import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import styles from "./styles/createCustomerStyles";
import PageContainer from "elements/PageContainer";
import { CustomInput } from "./CustomInput";
import { differenceInYears } from "date-fns";
import { dateWithoutTimezone } from "../../utils/dateWithoutTimezone";
import { getS3SignedUrl } from "../../utils/getS3SignedUrl";
import Modal from "elements/Modal";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import EmailIcon from "@mui/icons-material/Email";
import IntakeForm from "./IntakeForm";
import { mapDispatchToProps, mapStateToProps } from "./SagaMaps";
import { getInitialState, stringToBoolean, validateEmail } from "./Helpers";

class ClientOnboarding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...getInitialState(),
    };
  }

  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Client Onboarding",
    });

    this.props.setInsurancePlansRowsPerPage(1000);
    this.props.getInsuranceProvidersList();
    this.props.getInsurancePlans();
    this.props.getReferringPhysicians();
    if (this.props.match.params.id) {
      this.props.getSavedCustomerDetails(this.props.match.params.id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.savedCustomerLoading && !this.props.savedCustomerLoading) {
      if (!this.props.savedCustomerError) {
        this.mapSavedCustomerDetailsToState();
      }
    }
    if (
      (!prevProps.createUserSuccess && this.props.createUserSuccess) ||
      (!prevProps.savingForLaterSuccess && this.props.savingForLaterSuccess) ||
      (!prevProps.saveLeadSuccess && this.props.saveLeadSuccess)
    ) {
      this.setState({ successDialogOpen: true });
    }
    if (prevState.clientState != "Out" && this.state.clientState == "Out") {
      this.setState({ clientCountry: "" });
    }
    if (prevState.clientState == "Out" && this.state.clientState != "Out") {
      this.setState({ clientCountry: "United States" });
    }
    if (prevProps.insuranceListProviderId !== this.props.insuranceListProviderId) {
      this.props.getInsurancePlans();
    }
    if (!prevProps.insurancePlans && this.props.insurancePlans) {
      this.mapInsuranceCompanyToPlans();
    }
  }

  componentWillUnmount() {
    this.props.clearSaveForLaterInfo();
    this.props.clearCreateCustomer();
    this.props.setInsurancePlansRowsPerPage(25);
  }

  mapSavedCustomerDetailsToState = () => {
    const { savedCustomerDetails } = this.props;
    let clientAge = savedCustomerDetails.age
      ? savedCustomerDetails.age
      : savedCustomerDetails.date_of_birth
        ? differenceInYears(new Date(), new Date(savedCustomerDetails.date_of_birth))
        : null;
    let primaryInsurancePolicy =
      savedCustomerDetails?.insurance?.find((policy) => policy.is_primary == true) || {};
    let secondaryInsurancePolicy =
      savedCustomerDetails?.insruance?.find((policy) => policy.is_primary == false) || {};

    let familyMembers =
      savedCustomerDetails.family_members?.length > 0
        ? savedCustomerDetails.family_members.map((family) => {
            let numberValue = family?.phone?.replace(/[^0-9]/g, "") ?? "";
            let phoneNumber = numberValue.replace(
              /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/g,
              "($1)-$2-$3"
            );
            const { first_name, last_name, email, contact_type, relationship, primary_account } =
              family;
            return {
              first_name,
              last_name,
              email,
              phone: phoneNumber,
              contact_type: contact_type || "adult",
              relationship_to_client: relationship || "is_parent",
              is_primary: !!primary_account,
            };
          })
        : [
            {
              first_name: savedCustomerDetails.guardian_first_name,
              last_name: savedCustomerDetails.guardian_last_name,
              email: savedCustomerDetails.email,
              phone: savedCustomerDetails.phone
                ?.replace(/[^0-9]/g, "")
                .replace(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/g, "($1)-$2-$3"),
              relationship_to_client: savedCustomerDetails.relationship_to_client || "is_parent",
              contact_type: "adult",
              is_primary: true,
            },
          ];
    this.setState(
      {
        clientFirstName: savedCustomerDetails.name || "",
        clientLastName: savedCustomerDetails.child_last_name || "",
        clientDOB: savedCustomerDetails.date_of_birth
          ? dateWithoutTimezone(savedCustomerDetails.date_of_birth)
          : "",
        clientGender: savedCustomerDetails.gender || "",
        clientAge: clientAge,
        guardianFirstName: savedCustomerDetails.first_name || "",
        guardianLastName: savedCustomerDetails.last_name || "",
        clientAddress1: savedCustomerDetails.street_1 || "",
        clientAddress2: savedCustomerDetails.street_2 || "",
        clientCity: savedCustomerDetails.city || "",
        clientZip: savedCustomerDetails.zip_code || "",
        clientState: savedCustomerDetails.state || "",
        timezone: savedCustomerDetails.timezone || "",
        email: savedCustomerDetails.email || "",
        phone: savedCustomerDetails.phone || "",
        secondaryPhone: savedCustomerDetails.secondary_phone || "",
        contactTime: savedCustomerDetails.contact_time || "",
        contactMethod: savedCustomerDetails.contact_method || "",
        privatePay:
          savedCustomerDetails.private_pay === null ? null : !!savedCustomerDetails.private_pay,
        referralSource: savedCustomerDetails.referral_source || "",
        referralContactPhone: savedCustomerDetails.referral_contact_phone || "",
        internetAccess: savedCustomerDetails.internet_access,
        savedUserId: this.props.match.params.id,
        clientHasASDDiagnosis: savedCustomerDetails.has_diagnosis ? true : false,
        clientDiagnosis: savedCustomerDetails.diagnosis || "",
        relationshipToClient: savedCustomerDetails.relationship_to_client || "",
        contactType: savedCustomerDetails.contact_type || "Adult",
        familyMembers,
        insuranceId: primaryInsurancePolicy.insurance_id || "",
        insurancePlanId: primaryInsurancePolicy.insurance_plan_id || "",
        insuranceHolderName: primaryInsurancePolicy.insurance_holder_name || "",
        insuranceHolderRelationship: primaryInsurancePolicy.insurance_holder_relationship || "",
        insuranceHolderDOB: primaryInsurancePolicy.insurance_holder_dob ?? "",
        insuranceMemberId: primaryInsurancePolicy.member_id || "",
        insuranceExpirationDate: primaryInsurancePolicy.expiration_date || "",
        insuranceEffectiveDate: primaryInsurancePolicy.effective_date || "",
        insuranceGroupNumber: primaryInsurancePolicy.group_number || "",
        insuranceIsMedicaid: primaryInsurancePolicy.type_string
          ? primaryInsurancePolicy.type_string == "Medicaid"
            ? true
            : false
          : savedCustomerDetails.has_medicaid,
        secondaryInsuranceId: secondaryInsurancePolicy.insurance_id || "",
        secondaryInsurancePlanId: secondaryInsurancePolicy.insurance_plan_id || "",
        secondaryInsuranceHolderRelationship:
          secondaryInsurancePolicy.insurance_holder_relationship || "",
        secondaryInsuranceHolderDOB: secondaryInsurancePolicy.insurance_holder_dob ?? "",
        secondaryInsuranceHolderName: secondaryInsurancePolicy.insurance_holder_name || "",
        secondaryInsuranceMemberId: secondaryInsurancePolicy.member_id || "",
        secondaryInsuranceExpirationDate: secondaryInsurancePolicy.expiration_date || "",
        secondaryInsuranceEffectiveDate: secondaryInsurancePolicy.effective_date || "",
        secondaryInsuranceGroupNumber: secondaryInsurancePolicy.group_number || "",
        physicians: savedCustomerDetails.referring_physicians,
        additionalInfo: savedCustomerDetails.additional_info || "",
        clientPronouns: savedCustomerDetails.pronouns || "",
        isVerbal: savedCustomerDetails.is_verbal,
        hasComputer: savedCustomerDetails.has_computer,
        hasInternet: savedCustomerDetails.has_internet,
        preAuthRequired: savedCustomerDetails.pre_auth_required,
      },
      () => {
        this.mapInsuranceCompanyToPlans();
      }
    );
  };

  mapInsuranceCompanyToPlans = () => {
    const { insurancePlans } = this.props;
    const { insuranceId, secondaryInsuranceId } = this.state;
    let primaryInsurancePlans = [];
    let secondaryInsurancePlans = [];
    if (insuranceId) {
      primaryInsurancePlans = insurancePlans.data.filter(
        (plan) => plan.insurance_id == insuranceId
      );
    }
    if (secondaryInsuranceId) {
      secondaryInsurancePlans = insurancePlans.data.filter(
        (plan) => plan.insurance_id == secondaryInsuranceId
      );
    }
    this.setState({ primaryInsurancePlans, secondaryInsurancePlans });
  };

  getSignedUrl = async (filename) => {
    return await getS3SignedUrl(filename, this.props.savedCustomerDetails?.cognito_id);
  };

  handleDateChange = (val, name) => {
    if (name == "clientDOB") {
      let age = differenceInYears(new Date(), new Date(val));
      this.setState({ clientDOB: val, clientAge: age });
    } else {
      this.setState({ [name]: val });
    }
  };

  onChange = (name) => (e) => {
    const { checked, type, value } = e.target;
    const val = type === "checkbox" ? checked : value;
    this.setState({ [name]: val });
  };

  onRadioButtonChange = (name) => (e) => {
    const { value } = e.target;
    this.setState({
      [name]: stringToBoolean(value),
      [`${name}Error`]: false,
    });
  };

  onToggleSectionOpen = (section, open) => {
    this.setState({ [section]: open });
  };

  onNumberChange = (name) => (e) => {
    const { value } = e.target;
    this.setState({ [name]: value.replace(/[^0-9]/g, "") });
  };

  onClickASDDiagnosis = (value) => {
    this.setState({ clientHasASDDiagnosis: value });
  };

  handleEmailChange = (name) => (e) => {
    const { value } = e.target;
    this.setState({ email: value.replace(" ", "") });
  };

  handleZipChange = (name) => (e) => {
    const { value } = e.target;
    this.setState({ [name]: value.replace(/[^0-9]/g, "") });
  };

  checkFamilyMembersError = () => {
    const { familyMembers } = this.state;
    if (familyMembers.length === 0) {
      return true;
    } else {
      for (let i = 0; i < familyMembers.length; i++) {
        if (
          !familyMembers[i].first_name ||
          !familyMembers[i].last_name ||
          !familyMembers[i].phone ||
          !familyMembers[i].phone?.trim()?.match(/\d/g)?.length === 10 ||
          !familyMembers[i].email ||
          !validateEmail(familyMembers[i].email)
        ) {
          return true;
        }
      }
      return false;
    }
  };

  onSubmit = () => {
    const {
      clientDOB,
      clientFirstName,
      clientLastName,
      timezone,
      clientGender,
      clientHasASDDiagnosis,
      clientAddress1,
      clientAddress2,
      clientCity,
      clientState,
      clientZip,
      familyMembers,
      privatePay,
      preAuthRequired,
      insuranceId,
      insurancePlanId,
      insuranceMemberId,
      insuranceHolderDOB,
      insuranceHolderName,
      insuranceHolderRelationship,
      secondaryInsuranceId,
      secondaryInsurancePlanId,
      secondaryInsuranceMemberId,
      secondaryInsuranceHolderDOB,
      secondaryInsuranceHolderName,
      secondaryInsuranceHolderRelationship,
      hasSecondaryInsurance,
    } = this.state;

    this.setState(
      {
        clientFirstNameError: !clientFirstName,
        clientLastNameError: !clientLastName,
        clientDOBError: !clientDOB,
        timezoneError: !timezone,
        clientGenderError: !clientGender,
        clientHasASDDiagnosisError: clientHasASDDiagnosis === null,
        clientAddress1Error: !clientAddress1,
        clientCityError: !clientCity,
        clientStateError: !clientState,
        clientZipError: !clientZip,
        isPrivatePayError: privatePay === null,
        preAuthRequiredError: !privatePay && preAuthRequired === null,
        insuranceIdError: !privatePay && !insuranceId,
        insurancePlanIdError: !privatePay && !insurancePlanId,
        insuranceMemberIdError: !privatePay && !insuranceMemberId,
        insuranceHolderDOBError: !privatePay && !insuranceHolderDOB,
        insuranceHolderNameError: !privatePay && !insuranceHolderName,
        insuranceHolderRelationshipError: !privatePay && !insuranceHolderRelationship,
        secondaryInsuranceIdError: hasSecondaryInsurance && !secondaryInsuranceId,
        secondaryInsurancePlanIdError: hasSecondaryInsurance && !secondaryInsurancePlanId,
        secondaryInsuranceMemberIdError: hasSecondaryInsurance && !secondaryInsuranceMemberId,
        secondaryInsuranceHolderDOBError: hasSecondaryInsurance && !secondaryInsuranceHolderDOB,
        secondaryInsuranceHolderNameError: hasSecondaryInsurance && !secondaryInsuranceHolderName,
        secondaryInsuranceHolderRelationshipError:
          hasSecondaryInsurance && !secondaryInsuranceHolderRelationship,
        familyMembersError: this.checkFamilyMembersError(),
        physiciansError: false,
      },

      () => {
        if (
          this.state.clientFirstNameError ||
          this.state.clientLastNameError ||
          this.state.clientDOBError ||
          this.state.timezoneError ||
          this.state.clientGenderError ||
          this.state.clientHasASDDiagnosisError ||
          this.state.clientAddress1Error ||
          this.state.clientCityError ||
          this.state.clientStateError ||
          this.state.clientZipError ||
          this.state.isPrivatePayError ||
          this.state.preAuthRequiredError ||
          this.state.insuranceIdError ||
          this.state.insurancePlanIdError ||
          this.state.insuranceMemberIdError ||
          this.state.insuranceHolderDOBError ||
          this.state.insuranceHolderNameError ||
          this.state.insuranceHolderRelationshipError ||
          this.state.secondaryInsuranceIdError ||
          this.state.secondaryInsurancePlanIdError ||
          this.state.secondaryInsuranceMemberIdError ||
          this.state.secondaryInsuranceHolderDOBError ||
          this.state.secondaryInsuranceHolderNameError ||
          this.state.secondaryInsuranceHolderRelationshipError
        ) {
          this.scrollToTopErrorSection();
          return;
        } else {
          const family = familyMembers.map((fam) => {
            let phone = fam.phone?.replace(/[^0-9]/g, "");
            let email = fam.email?.toLowerCase().trim();
            fam.phone = phone;
            fam.email = email;
            return fam;
          });

          let createParams = {
            ...this.state,
            clientAddress1: clientAddress1.trim(),
            clientAddress2: clientAddress2.trim(),
            clientCity: clientCity.trim(),
            email: familyMembers[0].email,
            clientDOB: clientDOB || null,
            insuranceHolderDOB: insuranceHolderDOB || null,
            secondaryInsuranceHolderDOB: secondaryInsuranceHolderDOB || null,
            familyMembers: family,
          };

          this.props.createInsuranceCustomer(createParams);
        }
      }
    );
  };

  onSaveCustomerForLater = () => {
    const {
      email,
      clientDOB,
      clientAddress1,
      clientAddress2,
      clientCity,
      familyMembers,
      insuranceHolderDOB,
      secondaryInsuranceHolderDOB,
    } = this.state;

    const family = familyMembers.map((fam) => {
      let phone = fam.phone?.replace(/[^0-9]/g, "");
      let email = fam.email?.toLowerCase().trim();
      fam.phone = phone;
      fam.email = email;
      return fam;
    });
    this.setState(
      {
        familyMembersError: !email && !familyMembers.some((fam) => !!fam.email),
      },
      () => {
        if (this.state.familyMembersError) {
          this.scrollToTopErrorSection();
          return;
        } else {
          let createParams = {
            ...this.state,
            clientAddress1: clientAddress1.trim(),
            clientAddress2: clientAddress2.trim(),
            clientCity: clientCity.trim(),
            email:
              familyMembers && familyMembers[0].email
                ? familyMembers[0].email.toLowerCase()
                : email,
            eligibilityIncomplete: true,
            clientDOB: clientDOB || null,
            familyMembers: family,
            clientDOB: clientDOB || null,
            insuranceHolderDOB: insuranceHolderDOB || null,
            secondaryInsuranceHolderDOB: secondaryInsuranceHolderDOB || null,
          };
          this.props.saveCustomerForLater(createParams);
        }
      }
    );
  };

  scrollToTopErrorSection = () => {
    const {
      clientFirstNameError,
      clientLastNameError,
      clientDOBError,
      clientGenderError,
      clientHasASDDiagnosisError,
      timezoneError,
      clientAddress1Error,
      clientCityError,
      clientStateError,
      clientZipError,
      isPrivatePayError,
      preAuthRequiredError,
      insuranceIdError,
      insurancePlanIdError,
      insuranceMemberIdError,
      insuranceHolderDOBError,
      insuranceHolderNameError,
      insuranceHolderRelationshipError,
      hasSecondaryInsurance,
      secondaryInsuranceIdError,
      secondaryInsurancePlanIdError,
      secondaryInsuranceMemberIdError,
      secondaryInsuranceHolderDOBError,
      secondaryInsuranceHolderNameError,
      secondaryInsuranceHolderRelationshipError,
      familyMembersError,
    } = this.state;

    this.onToggleSectionOpen(
      "personalInfoOpen",
      clientFirstNameError ||
        clientLastNameError ||
        clientDOBError ||
        clientGenderError ||
        clientHasASDDiagnosisError ||
        timezoneError
    );
    this.onToggleSectionOpen(
      "clientAddressOpen",
      clientAddress1Error || clientCityError || clientStateError || clientZipError
    );
    this.onToggleSectionOpen("familyMembersOpen", familyMembersError);
    this.onToggleSectionOpen(
      "insuranceOpen",
      isPrivatePayError ||
        preAuthRequiredError ||
        insuranceIdError ||
        insurancePlanIdError ||
        insuranceMemberIdError ||
        insuranceHolderDOBError ||
        insuranceHolderNameError ||
        insuranceHolderRelationshipError ||
        (hasSecondaryInsurance &&
          (secondaryInsuranceIdError ||
            secondaryInsurancePlanIdError ||
            secondaryInsuranceMemberIdError ||
            secondaryInsuranceHolderDOBError ||
            secondaryInsuranceHolderNameError ||
            secondaryInsuranceHolderRelationshipError))
    );

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  setEmailErrorFalse = () => {
    this.setState({ emailError: false });
  };

  setEmailErrorTrue = () => {
    this.setState({ emailError: true });
  };

  setError = (name, error) => {
    this.setState({ [name]: error });
  };

  disqualifiedClient = () => {
    const { internetAccess, clientHasASDDiagnosis } = this.state;
    if (!internetAccess || !clientHasASDDiagnosis) {
      return true;
    } else {
      return false;
    }
  };

  onClickCloseSuccessDialog = () => {
    this.props.history.push("/dashboard");
  };

  onTimezoneChange = (e) => {
    this.setState({ timezone: e.target.value });
  };

  onToggleFamilyDrawer = () => {
    let addFamilyMember = {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      relationship_to_client: "",
      contact_type: "",
      create_account: false,
      is_primary: false,
      index: this.state.familyMembers?.length,
    };
    this.setState(
      {
        familyDrawerOpen: !this.state.familyDrawerOpen,
        addFamilyMember,
      },
      () => {
        if (!this.state.familyDrawerOpen) {
          this.setState({
            familyFirstNameError: false,
            familyLastNameError: false,
            familyPhoneError: false,
            familyEmailError: false,
          });
        }
      }
    );
  };

  onToggleEditFamilyDrawer = (i) => {
    const familyMember = this.state.familyMembers[i];
    this.setState(
      {
        editFamilyDrawerOpen: !this.state.editFamilyDrawerOpen,
        updateFamilyMember: this.state.editFamilyDrawerOpen ? {} : { ...familyMember, index: i },
      },
      () => {
        if (!this.state.editFamilyDrawerOpen) {
          this.setState({
            familyFirstNameError: false,
            familyLastNameError: false,
            familyPhoneError: false,
            familyEmailError: false,
          });
        }
      }
    );
  };

  onChangeFamilyMember = (name) => (e) => {
    let updateFamily = this.state.editFamilyDrawerOpen;
    const { checked, type, value } = e.target;
    const val = type === "checkbox" ? checked : value;
    let familyMember = updateFamily ? this.state.updateFamilyMember : this.state.addFamilyMember;
    if (name === "phone") {
      let numberValue = value.replace(/[^0-9]/g, "");
      familyMember.phone = numberValue.replace(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/g,
        "($1)-$2-$3"
      );
    } else if (name === "email") {
      familyMember[name] = val;
      this.setState({ email: val });
    } else if (name === "first_name") {
      familyMember[name] = val;
      this.setState({ guardianFirstName: val });
    } else if (name === "last_name") {
      familyMember[name] = val;
      this.setState({ guardianLastName: val });
    } else if (name === "create_account" || name === "is_primary") {
      familyMember[name] = val === "false" ? false : true;
    } else if (name === "relationship_to_client") {
      familyMember[name] = val;
      this.setState({ relationshipToClient: val });
    } else {
      familyMember[name] = val;
    }

    this.setState({
      [updateFamily ? "updateFamilyMember" : "addFamilyMember"]: familyMember,
    });
  };

  onSaveFamilyMember = (i, editFamily) => {
    let familyMembers = this.state.familyMembers;
    familyMembers[i] = editFamily ? this.state.updateFamilyMember : this.state.addFamilyMember;
    this.setState(
      {
        familyFirstNameError: !familyMembers[i].first_name,
        familyLastNameError: !familyMembers[i].last_name,
        familyPhoneError: !familyMembers[i].phone,
        familyEmailError: !familyMembers[i].email,
      },
      () => {
        if (
          this.state.familyFirstNameError ||
          this.state.familyLastNameError ||
          this.state.familyPhoneError ||
          this.state.familyEmailError
        ) {
          return;
        } else {
          let familyMembers = this.state.familyMembers;
          familyMembers[i] = editFamily
            ? this.state.updateFamilyMember
            : this.state.addFamilyMember;
          this.setState({
            familyMembers,
            updateFamilyMember: {},
            addFamilyMember: {},
            editFamilyDrawerOpen: false,
            familyDrawerOpen: false,
            familyFirstNameError: false,
            familyLastNameError: false,
            familyPhoneError: false,
            familyEmailError: false,
          });
        }
      }
    );
  };

  handlePhoneNumberChange = (name) => (e) => {
    const { value } = e.target;
    let numberValue = value.replace(/[^0-9]/g, "");
    let phone = numberValue.replace(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/g,
      "($1)-$2-$3"
    );
    this.setState({ [name]: phone });
  };

  onToggleHasSecondaryInsurance = () => {
    this.setState({ hasSecondaryInsurance: !this.state.hasSecondaryInsurance }, () => {
      if (!this.state.hasSecondaryInsurance) {
        this.setState({
          secondaryInsuranceHolderRelationship: "",
          secondaryInsuranceHolderDOB: "",
          secondaryInsuranceHolderName: "",
          secondaryInsuranceMemberId: "",
          secondaryInsuranceGroupNumber: "",
          secondaryInsurancePlanId: "",
          secondaryInsuranceId: "",
          secondaryInsuranceIdError: false,
          secondaryInsurancePlanIdError: false,
          secondaryInsuranceMemberIdError: false,
          secondaryInsuranceHolderDOBError: false,
          secondaryInsuranceHolderNameError: false,
          secondaryInsuranceHolderRelationshipError: false,
        });
      }
    });
  };

  onTogglePhysicianDrawer = () => {
    this.setState({ physicianDrawerOpen: !this.state.physicianDrawerOpen });
  };

  onSelectPhysician = (physician, editPhysicianId) => {
    let physicians = this.state?.physicians ?? [];
    physicians = physicians.filter((doc) =>
      editPhysicianId ? doc.id != editPhysicianId : doc.id != physician.id
    );
    physicians.push(physician);
    this.setState({ physicians, physicianDrawerOpen: false });
  };

  onRemovePhysician = (physician) => {
    let physicians = this.state?.physicians ?? [];
    physicians = physicians.filter((doc) => doc.id != physician.id);
    this.setState({ physicians });
  };

  onChangeInsuranceProvider = (name) => (e) => {
    const isPrimary = name === "insuranceId";
    const planIdVar = isPrimary ? "insurancePlanId" : "secondaryInsurancePlanId";
    this.setState({ [name]: e.target.value, [planIdVar]: "" }, () => {
      this.mapInsuranceCompanyToPlans();
    });
  };

  render() {
    const {
      createCustomerSaving,
      savingForLater,
      savingLead,
      savingForLaterSuccess,
      saveLeadSuccess,
      saving,
      createUserSuccess,
    } = this.props;
    return (
      <PageContainer
        loading={saving || createCustomerSaving || savingForLater || savingLead}
        horizontalPadding={false}
      >
        <IntakeForm
          {...this.props}
          {...this.state}
          onChange={this.onChange}
          handleDateChange={this.handleDateChange}
          handleEmailChange={this.handleEmailChange}
          validateEmail={validateEmail}
          setError={this.setError}
          onRadioButtonChange={this.onRadioButtonChange}
          onClickASDDiagnosis={this.onClickASDDiagnosis}
          onSubmit={this.onSubmit}
          onSaveCustomerForLater={this.onSaveCustomerForLater}
          setEmailErrorFalse={this.setEmailErrorFalse}
          setEmailErrorTrue={this.setEmailErrorTrue}
          CustomInput={CustomInput}
          onNumberChange={this.onNumberChange}
          onClickParentIsLegalGuardian={this.onClickParentIsLegalGuardian}
          disqualifiedClient={this.disqualifiedClient()}
          getSignedUrl={this.getSignedUrl}
          onSaveLead={this.onSaveLead}
          onChangeDoctorIsDiagnosing={this.onChangeDoctorIsDiagnosing}
          onToggleFamilyDrawer={this.onToggleFamilyDrawer}
          onToggleEditFamilyDrawer={this.onToggleEditFamilyDrawer}
          onChangeFamilyMember={this.onChangeFamilyMember}
          onSaveFamilyMember={this.onSaveFamilyMember}
          handlePhoneNumberChange={this.handlePhoneNumberChange}
          onToggleHasSecondaryInsurance={this.onToggleHasSecondaryInsurance}
          onTogglePhysicianDrawer={this.onTogglePhysicianDrawer}
          onSelectPhysician={this.onSelectPhysician}
          onRemovePhysician={this.onRemovePhysician}
          onTimezoneChange={this.onTimezoneChange}
          onChangeInsuranceProvider={this.onChangeInsuranceProvider}
          onToggleSectionOpen={this.onToggleSectionOpen}
        />

        <Modal
          open={this.state.successDialogOpen}
          title="Success!"
          description={
            createUserSuccess && !savingForLaterSuccess
              ? "This customer has been emailed an invitation to login."
              : savingForLaterSuccess
                ? "You have successfully saved the customer info for later."
                : "This customer has been saved to the referral table."
          }
          icon={saveLeadSuccess || savingForLaterSuccess ? <CloudDoneIcon /> : <EmailIcon />}
          primaryActionText="Ok"
          primaryActionOnClick={() => this.onClickCloseSuccessDialog()}
        />
      </PageContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ClientOnboarding));
