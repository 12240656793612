export const InsuranceCompanyConfig = [
  {
    id: "name",
    label: "Company Name",
    dataKey: "name",
    cols: 12,
    required: true,
  },
  {
    id: "contract_type",
    label: "Contact Type",
    dataKey: "contract_type",
    cols: 12,
    required: true,
  },
];
