import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Modal from "elements/Modal";
import TextField from "@mui/material/TextField";

const UpdateClinicianEmailDialog = (props) => {
  const {
    updateEmailOpen,
    onToggleDialog,
    onSubmitUpdateEmail,
    onChange,
    onClickCheckbox,
    newEmail,
    confirmNewEmail,
    updateUserEmailRisks,
    newEmailError,
    confirmEmailError,
    validateEmail,
    setError,
    updateClinicianEmailLoading,
    updateClinicianEmailSuccess,
    updateClinicianEmailError,
    updateClinicianEmailErrorMessage,
  } = props;
  return (
    <Modal
      open={updateEmailOpen}
      onClose={onToggleDialog}
      title="Manually Update Clinician Email"
      titleCentered
      content={
        <div style={{ width: 600 }}>
          <Typography component="p" color="error" style={{ textAlign: "left", fontWeight: 500 }}>
            WARNING: Manually updating a clinician's email could cause potential problems with the
            user experience. <br />
            <br />
          </Typography>
          <TextField
            id="type"
            onChange={onChange("newEmail")}
            value={newEmail}
            fullWidth
            style={{ margin: "20px 0" }}
            label="New Email Address"
            onFocus={() => {
              setError("newEmailError", false);
              setError("confirmEmailError", false);
            }}
            onBlur={() => {
              validateEmail(newEmail) || !newEmail
                ? setError("newEmailError", false)
                : setError("newEmailError", true);
              confirmNewEmail && newEmail.toLowerCase() !== confirmNewEmail.toLowerCase()
                ? setError("confirmEmailError", true)
                : setError("confirmEmailError", false);
            }}
            helperText={newEmailError ? "Invalid Email" : null}
          />
          <TextField
            id="type"
            onChange={onChange("confirmNewEmail")}
            value={confirmNewEmail}
            fullWidth
            style={{ margin: "0 0 20px" }}
            label="Confirm New Email Address"
            disabled={!newEmail}
            onFocus={() => setError("confirmEmailError", false)}
            onBlur={() =>
              newEmail.toLowerCase() === confirmNewEmail.toLowerCase() || !confirmNewEmail
                ? setError("confirmEmailError", false)
                : setError("confirmEmailError", true)
            }
            helperText={confirmEmailError ? "Emails do not match" : null}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={updateUserEmailRisks}
                onClick={() => onClickCheckbox("updateUserEmailRisks")}
                size="small"
              />
            }
            label={
              <span>
                I understand that there are risks associated with manually updating the current
                email address of a user.
              </span>
            }
          />
        </div>
      }
      primaryActionText="Submit"
      primaryActionOnClick={onSubmitUpdateEmail}
      primaryActionDisabled={
        !updateUserEmailRisks ||
        !newEmail ||
        !confirmNewEmail ||
        !validateEmail(newEmail) ||
        newEmail.toLowerCase() !== confirmNewEmail.toLowerCase()
      }
      secondaryActionText={updateClinicianEmailSuccess ? "Close" : "Cancel"}
      secondaryActionOnClick={onToggleDialog}
      loading={updateClinicianEmailLoading}
      success={updateClinicianEmailSuccess}
      successMessage={"Email updated successfully"}
      error={updateClinicianEmailError}
      errorMessage={updateClinicianEmailErrorMessage}
    />
  );
};

export default UpdateClinicianEmailDialog;
