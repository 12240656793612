import React, { useEffect, useState } from "react";

import { DataGrid } from "@mui/x-data-grid";
import { Button, Link, Stack } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Calendar, Files, UploadSimple, UserCircle } from "@phosphor-icons/react";
import UploadDocumentDialog from "../Dialogs/UploadDocumentDialog";
import { format } from "date-fns";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { updateDocument } from "api/customers";
import NoDocumentsWithPermissionOverlay from "elements/NoDocumentsWithPermissionOverlay";
import ANNoDataOverlay from "elements/ANNoDataOverlay";
import ANNoResultsOverlay from "elements/ANNoResultsOverlay";
import * as selectors from "selectors";
import ANTableFilters from "elements/ANTableFilters";
import noDocumentSvg from "assets/no_document.svg";
import { v4 as uuidv4 } from "uuid";
import { useGetClinicianDocuments } from "hooks/useGetClinicianDocuments";
import { useGetClinicianDocumentTypes } from "hooks/useGetClinicianDocumentTypes";
import { formatFilename } from "utils/snakeCasetoTitleCase";
import { getAWSCredentialsForCurrentUserSession } from "utils/aws";

const Documents = (props) => {
  const { saveDocument, replaceDocument, basicInfo, currentUser, userPermissions, clinicianId } =
    props;

  const theme = useTheme();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });
  const [uploadDocumentOpen, setUploadDocumentOpen] = useState(false);
  const [isNewDocument, setIsNewDocument] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [orderBy, setOrderBy] = useState("uploaded-date-desc");
  const [typeFilter, setTypeFilter] = useState([]);
  const [uploadedByFilter, setUploadedByFilter] = useState([]);
  const [uploadedDateFilter, setUploadedDateFilter] = useState([null, null]);
  const [dropzoneFiles, setDropzoneFiles] = useState([]);
  const [alreadyUploadedFiles, setAlreadyUploadedFiles] = useState([]);
  const [uploadDocType, setUploadDocType] = useState("");

  const notAvailableForUploadOnDocuments = [
    "BCBA_Certification",
    "LIABILITY_INSURANCE",
    "State_License",
  ];

  const uploadDocumentLoading = useSelector(selectors.getuploadDocumentLoading);
  const userId = useSelector(selectors.getUserId);

  const { mutate: updateClientDocument, isLoading: isLoadingDocumentUpdate } = useMutation(
    (documentData) => updateDocument(clientId, documentData),
    {
      onSuccess: () => updateTable(),
      onError: (error) => console.error("Error updating document:", error),
    }
  );

  useEffect(() => {
    if (!uploadDocumentLoading) updateTable();
  }, [uploadDocumentLoading]);

  const viewMode = userPermissions["view_client_dashboard"];
  const orderByMap = {
    "uploaded-date-asc": { sort: "created", direction: "asc" },
    "uploaded-date-desc": { sort: "created", direction: "desc" },
    type: { sort: "type", direction: "asc" },
    "uploaded-by": { sort: "uploaded_by", direction: "asc" },
  };
  // using isFetching instead of isLoading, since keepPreviousData (avoids showing `undefined` in chips)
  // is set to true in useGetClinicianDocuments and isLoading will be false when
  // the previous data is being used
  const {
    isFetching,
    data: response,
    refetch: updateTable,
  } = useGetClinicianDocuments(
    clinicianId,
    {
      sort: orderByMap[orderBy]?.sort,
      direction: orderByMap[orderBy]?.direction,
      page: paginationModel.page,
      size: paginationModel.pageSize,
      typeList: !typeFilter.length ? null : typeFilter.join(","),
      uploadedByList: uploadedByFilter.join(","),
      startDate: uploadedDateFilter?.[0],
      endDate: uploadedDateFilter?.[1],
    },
    props.documents ? false : true
  );
  const { data: documentTypes } = useGetClinicianDocumentTypes();

  const documents = response?.data.documents;
  const rowCount = response?.data.count;
  const typeCount = response?.data.type_count;
  const uploadedByCount = response?.data.uploaded_by_count;

  const handleFileUpload = (file) => {
    setDropzoneFiles([
      { errors: !!file.fileRejections?.[0], file: file.acceptedFiles[0] || file.fileRejections[0] },
    ]);
    if (!uploadDocumentOpen) setUploadDocumentOpen(true);
  };

  const handleFileDelete = (file) => {
    setDropzoneFiles(dropzoneFiles.filter((dropzoneFile) => dropzoneFile !== file));
  };

  const handleLoadedFileDelete = (file) => {
    setAlreadyUploadedFiles(alreadyUploadedFiles.filter((uploadedFile) => uploadedFile !== file));
  };

  const submitDocumentUpload = async (uploadDocType) => {
    const uploadDoc = dropzoneFiles[0]?.file ?? null;
    const { cognito_id } = basicInfo;
    let documentName = uploadDoc?.name.split(".")[0] ?? selectedRow.name;
    let fileExtension = uploadDoc?.name.split(".")[1] ?? "";
    const fileUUID = uuidv4();
    // if there is a new file or updated file to upload
    if (uploadDoc) {
      const credentials = await getAWSCredentialsForCurrentUserSession();
      const data = {
        credentials,
        region: "us-east-1",
        signatureVersion: "v2",
        apiVersion: "2006-03-01",
        params: { Bucket: process.env.AWS_USER_DOC_BUCKET },
      };

      const s3 = new AWS.S3(data);
      const params = {
        ACL: "authenticated-read",
        Body: uploadDoc,
        ContentEncoding: "base64",
        ContentType: uploadDoc.type,
        Key: `${cognito_id}/${documentName}-${fileUUID}.${fileExtension}`,
      };
      try {
        const s3url = await s3.upload(params).promise();
      } catch (error) {
        console.log("Error:", error);

        throw error;
      }
    }

    if (isNewDocument) {
      const document = {
        documentType: uploadDocType,
        documentName: documentName, //${fileName}-${fileUUID}.${fileExtension}
        clinicianId: clinicianId,
        file_size: uploadDoc.size,
        filename: `${documentName}-${fileUUID}.${fileExtension}`,
        uploaded_by: currentUser.id,
      };
      saveDocument(document);
    } else {
      const documentData = {
        documentId: selectedRow.id,
        documentType: uploadDocType,
        documentName: documentName,
        clinicianId: clinicianId,
        filename: `${documentName}-${fileUUID}.${fileExtension}`,
        file_size: uploadDoc ? uploadDoc.size : selectedRow.file_size,
      };

      replaceDocument(documentData);
    }
  };

  const generateRows = () => {
    if (!documents) return [];

    return documents.map((document) => ({
      id: document.id,
      name: document.display_filename,
      type: document.type,
      "uploaded-by": document.uploaded_by,
      "uploaded-date": document.created,
      edit: "Edit",
      file_size: document.file_size,
      filename: document.filename,
      source: document.source,
    }));
  };
  const rows = generateRows();

  const getColumnDefinitions = (handleEdit, handleOpenFile) => {
    const DateValueGetter = ({ value }) => (value ? format(new Date(value), "P") : null);
    const defaultColAttrs = {
      sortable: false,
      flex: 1,
    };
    const columns = [
      {
        field: "name",
        headerName: "Name",
        ...defaultColAttrs,
        renderCell: (params) => {
          return (
            <Link
              color="primary"
              variant="body2"
              underline="hover"
              onClick={() => handleOpenFile(params)}
              sx={{ "&:hover": { cursor: "pointer" } }}
            >
              {params.value}
            </Link>
          );
        },
      },
      {
        field: "type",
        headerName: "Type",
        ...defaultColAttrs,
        renderCell: (params) => {
          return <span>{formatFilename(params.value)}</span>;
        },
      },
      { field: "uploaded-by", headerName: "Uploaded By", ...defaultColAttrs },
      {
        field: "uploaded-date",
        headerName: "Uploaded Date",
        align: "right",
        headerAlign: "right",
        valueGetter: DateValueGetter,
        ...defaultColAttrs,
      },
    ];
    if (!viewMode) {
      columns.push({
        field: "edit",
        headerName: "Edit",
        align: "center",
        headerAlign: "center",
        ...defaultColAttrs,
        renderCell: (params) => {
          //render edit button if params.row.type is not in notAvailableForUploadOnDocuments
          return notAvailableForUploadOnDocuments.includes(params.row.type) ? null : (
            <Link
              color="primary"
              variant="body2"
              underline="hover"
              onClick={() => {
                handleEdit(params);
              }}
              sx={{ "&:hover": { cursor: "pointer" } }}
            >
              Edit
            </Link>
          );
        },
      });
    }
    return columns;
  };

  const orderingMenuItems = [
    {
      label: "Type",
      value: "type",
      icon: <Files weight="duotone" />,
    },
    { label: "Uploaded By", value: "uploaded-by", icon: <UserCircle weight="duotone" /> },
    {
      label: "Uploaded Date",
      value: "uploaded-date-asc",
      icon: <Calendar weight="duotone" />,
      caption: "Ascending",
    },
    {
      label: "Uploaded Date",
      value: "uploaded-date-desc",
      icon: <Calendar weight="duotone" />,
      caption: "Descending",
    },
  ];
  const filtersMenuItems = [
    {
      label: "Type",
      value: "type",
      icon: <Files weight="duotone" />,
      countObject: typeCount,
      state: typeFilter,
      setState: setTypeFilter,
    },
    {
      label: "Uploaded Date",
      value: "uploaded-date",
      icon: <Calendar weight="duotone" />,
      customComponent: (
        <StaticDateRangePicker
          value={uploadedDateFilter}
          onChange={(date) => setUploadedDateFilter(date)}
          slotProps={{ actionBar: { actions: [] }, toolbar: { hidden: true } }}
          calendars={2}
        />
      ),
      filterChipProps: {
        label: `Uploaded Date is : ${format(new Date(uploadedDateFilter[0]), "MM/dd/yy")} 
          ${
            uploadedDateFilter[1] ? ` - ${format(new Date(uploadedDateFilter[1]), "MM/dd/yy")}` : ""
          }`,
        onDelete: () => setUploadedDateFilter([null, null]),
      },
      showChipConditionCustomComponent: !!uploadedDateFilter[0],
    },
    {
      label: "Uploaded By",
      value: "uploaded-by",
      icon: <UserCircle weight="duotone" />,
      countObject: uploadedByCount,
      state: uploadedByFilter,
      setState: setUploadedByFilter,
    },
  ];

  const handleClearFilters = () => {
    setTypeFilter([]);
    setUploadedByFilter([]);
    setUploadedDateFilter([null, null]);
  };

  const customNoRowsOverlay = {
    NoDocuments: {
      component: ANNoDataOverlay,
      height: "296px",
      props: {
        imgSrc: noDocumentSvg,
        title: "No documents yet",
        description: "Client documents will display here once uploaded to the account",
      },
    },
    NoDocumentsWithPermission: {
      component: NoDocumentsWithPermissionOverlay,
      height: "296px",
      props: { handleFileUpload },
    },
    NoResults: {
      component: ANNoResultsOverlay,
      height: "236px",
      props: { handleClearFilters },
    },
  };
  const overlayName =
    !typeFilter.length &&
    !uploadedByFilter.length &&
    !uploadedDateFilter[0] &&
    !uploadedDateFilter[1]
      ? viewMode
        ? "NoDocuments"
        : "NoDocumentsWithPermission"
      : "NoResults";

  return (
    <>
      <Stack
        direction="row"
        padding={theme.spacing(5)}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <ANTableFilters
          orderingMenuItems={orderingMenuItems}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          filtersMenuItems={filtersMenuItems}
          filtersButtonProps={{ disabled: documents?.length === 0 }}
        />
        {!viewMode && (
          <Button
            color="secondary"
            startIcon={<UploadSimple />}
            size="small"
            onClick={() => setUploadDocumentOpen(true)}
            sx={{ minWidth: "fit-content" }}
          >
            Upload Document
          </Button>
        )}
      </Stack>
      <DataGrid
        rows={rows}
        loading={(isFetching && !documents) || isLoadingDocumentUpdate || uploadDocumentLoading}
        pageSizeOptions={[5, 10, 20]}
        columns={getColumnDefinitions(
          ({ row }) => {
            setUploadDocumentOpen(true);
            setIsNewDocument(false);
            setAlreadyUploadedFiles([{ name: row.name, key: row.name, size: row.file_size ?? 0 }]);
            setUploadDocType(documentTypes?.find((docType) => docType.name === row.type).name);
            setSelectedRow(row);
          },
          ({ row }) => {
            const url = `/document-viewer/${userId}/${row.id}?role=clinician&documentType=${row.source}`;
            window.open(url, "_blank", "noopener,noreferrer");
          }
        )}
        disableRowSelectionOnClick
        autoHeight
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        slots={{
          noRowsOverlay: customNoRowsOverlay[overlayName].component,
        }}
        slotProps={{ noRowsOverlay: { ...customNoRowsOverlay[overlayName].props } }}
        sx={{ "--DataGrid-overlayHeight": customNoRowsOverlay[overlayName].height }}
        hideFooter={!rowCount}
        paginationMode="server"
        rowCount={rowCount}
      />
      <UploadDocumentDialog
        open={uploadDocumentOpen}
        handleClose={() => {
          setUploadDocumentOpen(false);
          setSelectedRow(null);
          setTimeout(() => {
            setIsNewDocument(true);
            setAlreadyUploadedFiles([]);
            setDropzoneFiles([]);
            setUploadDocType("");
          }, 250); // Timeout avoids flickering
        }}
        filterOutDocumentTypes={notAvailableForUploadOnDocuments}
        handleFileUpload={handleFileUpload}
        dropzoneFiles={dropzoneFiles}
        handleFileDelete={handleFileDelete}
        handleLoadedFileDelete={handleLoadedFileDelete}
        submitDocumentUpload={submitDocumentUpload}
        isNewDocument={isNewDocument}
        alreadyUploadedFiles={alreadyUploadedFiles}
        uploadDocType={uploadDocType}
        setUploadDocType={setUploadDocType}
      />
    </>
  );
};

export default Documents;
