import React, { useEffect, useRef, useState } from "react";
import Insurance from "../Widgets/Insurance";
import InsuranceAuthorization from "../Widgets/InsuranceAuthorization";
import Providers from "../Widgets/Providers";
import { LoadingScreen } from "../../EventScheduling/EventSchedulingUtils";
import VerificationOfBenefits from "../Widgets/VerificationOfBenefits";
import { Box, Divider, Grid, Stack } from "@mui/material";
import {
  useCreateInsuranceVOB,
  useGetInsuranceVOB,
  useUpdateInsuranceVOB,
} from "hooks/insuranceVOB";
import { useSelector } from "react-redux";
import * as selectors from "selectors";
import { useGlobalToast } from "../../GlobalToastProvider";
import { useQueryClient } from "react-query";
import { GET_INSURANCE_VOB } from "constants/reactQueryKeys";
import AnDetailsPanel from "elements/Forms/AnDetailsPanel";
import { useTheme } from "@mui/styles";

const ProvidersAndInsurance = (props) => {
  const {
    classes,
    clientId,
    editMode,
    customerDetails,
    userInsurance,
    insuranceProviders,
    userInsuranceLoading,
    userPermissions,
    formatPhoneNumber,
    isClinician,
    editDisabled,
    getInsurancePlans,
    getInsuranceProvidersList,
    insurancePlans,
    insuranceProvidersList,
    setInsuranceListProviderId,
    insuranceListProviderId,
    dataPanel,
    updateDataPanel,
    resetDataPanel,
    copyToDataPanel,
    configPanel,
    setConfigPanel,
    isPanelOpen,
    setIsPanelOpen,
    titlePanel,
    updateTitlePanel,
    inputsForm,
    updateInputsForm,
    isDirty,
    updateIsDirty,
  } = props;
  const [insurancesId, setInsurancesId] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [hasErrors, setHasErrors] = useState({});
  const userId = useSelector(selectors.getUserId);
  const { showToast } = useGlobalToast();
  const panelRef = useRef(null);
  const theme = useTheme();

  const updateHasChenages = (hasChanges) => {
    setHasChanges(hasChanges);
  };
  const queryClient = useQueryClient();

  const { isLoading: isLoadingVOB, data: dataVOB = [] } = useGetInsuranceVOB(clientId, {
    status: "active",
  });

  const { mutate: useCreateInsuranceVOBMutate, isLoading: isCreating } = useCreateInsuranceVOB({
    onSuccess: () => {
      resetDataPanel();
      setConfigPanel([]);
      setIsPanelOpen(false);
      showToast({ message: "VOB Created Successfully!" });
      queryClient.invalidateQueries([GET_INSURANCE_VOB, clientId, { status: "active" }]);
    },
    onError: () => {
      showToast({
        message: "Error Creating VOB.",
        errorState: true,
      });
    },
  });

  const { mutate: useUpdateInsuranceVOBMutate, isLoading: isUpdating } = useUpdateInsuranceVOB({
    onSuccess: () => {
      resetDataPanel();
      setConfigPanel([]);
      setIsPanelOpen(false);
      showToast({ message: "VOB Updated Successfully!" });
      queryClient.invalidateQueries([GET_INSURANCE_VOB, clientId, { status: "active" }]);
    },
    onError: () => {
      showToast({
        message: "Error Updating VOB.",
        errorState: true,
      });
    },
  });

  const onSave = async () => {
    if (!isDirty) {
      if (dataPanel?.id) {
        useUpdateInsuranceVOBMutate({ clientId, createdBy: userId, ...dataPanel });
      } else {
        useCreateInsuranceVOBMutate({ clientId, createdBy: userId, ...dataPanel });
      }
    }
  };

  const updateHasErrors = (key, value) => {
    setHasErrors((prevDataPanel) => ({
      ...prevDataPanel,
      [key]: value,
    }));
  };

  if (!userPermissions) {
    return <LoadingScreen />;
  }

  useEffect(() => {
    setIsPanelOpen(false);
    resetDataPanel();
  }, [editMode]);

  useEffect(() => {
    if (isPanelOpen) {
      moveToTop();
    }
  }, [isPanelOpen]);

  const moveToTop = () => {
    if (panelRef.current) {
      panelRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <Stack
      direction="row"
      sx={{
        height: "calc(100vh - 163px)",
        [theme.breakpoints.down("md")]: {
          height: "calc(100vh - 171px)",
        },
        width: "100%",
      }}
    >
      <Stack
        flex={isPanelOpen ? 2 : 1}
        sx={{
          transition: "all 0.3s ease",
          overflow: "auto",
          padding: "32px 50px",
          height: "100%",
        }}
      >
        {userPermissions?.view_client_insurance && (
          <>
            <Insurance
              editMode={editMode}
              clientId={clientId}
              classes={classes}
              loading={userInsuranceLoading}
              customerDetails={customerDetails.details}
              userPermissions={userPermissions}
              userInsurance={userInsurance}
              insuranceProviders={insuranceProviders}
              formatPhoneNumber={formatPhoneNumber}
              editDisabled={editDisabled}
              getInsurancePlans={getInsurancePlans}
              getInsuranceProvidersList={getInsuranceProvidersList}
              insurancePlans={insurancePlans}
              insuranceProvidersList={insuranceProvidersList}
              setInsuranceListProviderId={setInsuranceListProviderId}
              insuranceListProviderId={insuranceListProviderId}
              setInsurancesId={setInsurancesId}
              {...props}
            />
          </>
        )}
        <>
          <VerificationOfBenefits
            {...props}
            classes={classes}
            editMode={editMode}
            clientId={clientId}
            dataPanel={dataPanel}
            updateDataPanel={updateDataPanel}
            copyToDataPanel={copyToDataPanel}
            configPanel={configPanel}
            setConfigPanel={setConfigPanel}
            updateTitlePanel={updateTitlePanel}
            setIsPanelOpen={setIsPanelOpen}
            isPanelOpen={isPanelOpen}
            updateInputsForm={updateInputsForm}
            onSave={onSave}
            insurancesId={insurancesId}
            updateHasChenages={updateHasChenages}
            updateIsDirty={updateIsDirty}
            isDirty={isDirty}
            hasErrors={hasErrors}
            updateHasErrors={updateHasErrors}
            dataVOB={dataVOB}
            isLoadingVOB={isLoadingVOB}
            moveToTop={moveToTop}
          />
        </>
        {(isClinician || userPermissions?.download_phi_release_request) && (
          <>
            <Providers
              {...props}
              providers={customerDetails.physicians}
              customerInfo={customerDetails.details}
            />
          </>
        )}
        {userPermissions?.view_client_insurance_authorization && (
          <>
            <InsuranceAuthorization
              {...props}
              editMode={editMode}
              userId={customerDetails.details.userid}
              approvedUnits={customerDetails.approvedUnits}
            />
          </>
        )}
      </Stack>
      <Stack
        flex={isPanelOpen ? 2 : 0}
        sx={{
          borderLeft: `1px solid ${theme.palette.divider}`,
          transition: "all 0.3s ease",
          alignItems: "flex-end",
          overflow: "auto",
        }}
        maxWidth="400px"
      >
        <AnDetailsPanel
          ref={panelRef}
          classes={classes}
          open={isPanelOpen}
          setOpen={setIsPanelOpen}
          editMode={editMode}
          titlePanel={titlePanel}
          data={dataPanel}
          resetData={resetDataPanel}
          config={configPanel}
          inputsForm={inputsForm}
          isLoading={isCreating || isUpdating}
          isDirty={isDirty}
          onSave={onSave}
          onCancel={() => {
            resetDataPanel();
            updateIsDirty(false);
            setConfigPanel([]);
            setIsPanelOpen(false);
          }}
          saveTitle={dataPanel.id ? "Save Changes" : "Create VOB"}
        />
      </Stack>
    </Stack>
  );
};

export default ProvidersAndInsurance;
