import React, { Component, useEffect } from "react";
import { connect } from "react-redux";

import { Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { Prompt } from "react-router-dom";
import { getInitialState } from "./InitialState";
import { mapStateToProps } from "./mapStateToProps";
import { mapDispatchToProps } from "./mapDispatchToProps";
import SaveBeforeNavDialog from "../ClientDetails/Dialogs/SaveBeforeNavDialog";
import withStyles from "@mui/styles/withStyles";
import styles from "../ClientDetails/styles";
import AnLibrariesPageContents from "./AnLibrariesPageContents";
import ClientDetailsTabs from "../ClientDetails/ClientDetailsTabs";
import AnLibrariesTabs from "./AnLibrariesTabs";

class AnLibraries extends Component {
  state = {
    ...getInitialState(this.props),
  };
  assignBlockRef = null;
  insuranceAuthorizationRef = null;
  smsPolling = null;
  editableTabs = [];

  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Libraries",
      hideDivider: true,
    });
  }

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {}

  onChange = (name) => (e) => {
    const { checked, type, value } = e.target;
    let val;
    switch (value) {
      case "Yes":
        val = true;
        break;
      case "No":
        val = false;
        break;
      default:
        val = type === "checkbox" ? checked : value;
    }
    this.setState({
      [name]: val,
      buttonDisabled: false,
      unsavedChanges: true,
      editedPersonalInformation: true,
    });
  };

  onChangeDate = (name, date) => {
    this.setState({
      [name]: date,
      buttonDisabled: false,
      unsavedChanges: true,
      editedPersonalInformation: true,
    });
  };

  onChangeArrayToString = (name, value) => {
    this.setState({
      [name]: typeof value === "string" ? value : value.join(", "),
      buttonDisabled: false,
      unsavedChanges: true,
      editedPersonalInformation: true,
    });
  };

  onClickCheckbox = (name) => {
    this.setState({ [name]: !this.state[name] });
  };

  onChangeNumber = (name) => (e) => {
    const { value } = e.target;
    this.setState({
      [name]: value.replace(/[^0-9]/g, ""),
      buttonDisabled: false,
      unsavedChanges: true,
      editedPersonalInformation: true,
    });
  };

  navigate = (url) => {
    this.props.history.push(url);
  };

  handleTabChange = (event, newValue) => {
    this.setState({ currentTab: newValue });
  };

  onActiveTabChange = (tab) => {
    if (
      (this.state.editedPersonalInformation ||
        this.state.editedFamilyContacts ||
        this.state.editedAccountStatus) &&
      tab !== this.state.currentTab
    ) {
      this.setState({ unsavedChanges: true, tabToChange: tab });
    } else {
      if (tab !== this.state.currentTab) {
        if (!this.editableTabs.includes(tab)) {
          this.setState({ editMode: false });
        }
        this.resetTab(tab);
      }
    }
  };

  resetTab = (tab) => {
    this.clearData(
      {
        currentTab: tab,
        nextLocation: null,
      },
      () => {
        this.props.setPageDetails({
          onEditButton: this.canEditTab(tab),
          editButtonAction: this.onEditMode,
        });
        this.refreshDataTab(tab);
      }
    );
  };

  refreshDataTab = (tab) => {};

  clearData = (extra = {}, callback) => {
    this.setState(
      {
        ...{
          editMode: false,
          editedFamilyContacts: false,
          editedPersonalInformation: false,
          editedAccountStatus: false,
          unsavedChanges: false,
          updatedUserStatus: null,
          updateUserStatusRisks: false,
        },
        ...extra,
      },
      () => {
        if (callback && typeof callback === "function") {
          callback();
        }
      }
    );
  };

  /**
   * This is the global method to enable the edit mode on all tabs
   */
  onEditMode = () => {
    this.onEditProfile();
  };

  formatPhoneNumber = (val) => {
    let formatedPhone = val
      ? val.replace(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/g, "$1-$2-$3")
      : val;
    return formatedPhone;
  };

  validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  setError = (name, error) => {
    this.setState({ [name]: error });
  };

  onTimezoneChange = (e) => {
    this.setState({
      clientTimezone: e.target.value,
      timezoneSubmitDisabled: false,
    });
  };

  /**
   * This method enable the button based on the permissions of the client profile
   * @param tab actual tab
   * @returns {boolean}
   */
  canEditTab = (tab) => {
    let havePermissions = false;
    this.setState({ editButton: havePermissions });
    return havePermissions;
  };

  getTabs = () => {
    const { userPermissions } = this.props;

    return [
      { label: "Insurance", link: "insurance", hide: !userPermissions?.view_insurance_utility },
      { label: "Providers", link: "providers", hide: !userPermissions?.view_physician_utility },
      {
        label: "Assessments",
        link: "assessments",
        hide: !userPermissions?.view_assessment_library,
      },
    ];
  };

  onContinueWithoutSaving = () => {
    this.clearData({ openWarning: false }, () => {
      if (this.state.nextLocation) {
        this.navigate(this.state.nextLocation);
      } else {
        this.resetTab(this.state.currentTab);
      }
    });
  };

  onCloseEditMode = (isCancelClicked = false) => {
    if (this.state.unsavedChanges) {
      this.setState({ openWarning: true, isCancelClicked });
    } else {
      this.resetTab(this.state.currentTab);
    }
  };

  setUnsavedChanges = (value) => {
    this.setState({ unsavedChanges: value });
  };

  render() {
    const { classes, loaded, customerDetails, userPermissions } = this.props;
    const { snackBarOpen, snackBarMessage, currentTab, unsavedChanges, editButton } = this.state;

    return (
      userPermissions && (
        <div>
          <Prompt
            when={(unsavedChanges || editButton) && this.editableTabs.includes(currentTab)}
            message={(nextLocation, action) => {
              if (unsavedChanges) {
                if (this.currentPath !== nextLocation.pathname && action === "POP") {
                  window.history.forward();
                }

                this.setState({
                  openWarning: true,
                  isCancelClicked: false,
                  nextLocation: nextLocation.pathname,
                });

                return false;
              }

              if (editButton) {
                this.props.setPageDetails({
                  onEditButton: false,
                  editButtonAction: null,
                });
                this.setState({ editButton: false });
              }
            }}
          />
          <SaveBeforeNavDialog
            saveBeforeNavOpen={this.state.openWarning}
            onToggleSaveBeforeNav={() => {
              this.setState({ openWarning: false });
            }}
            onContinueWithoutSaving={this.onContinueWithoutSaving}
            severetyAlert={"warning"}
            primaryActionColorButton={"error"}
            secondaryText={"Stay on Page"}
            isCancelClicked={this.state.isCancelClicked}
          />
          <AnLibrariesTabs
            currentTab={currentTab}
            handleTabChange={this.handleTabChange}
            userPermissions={userPermissions}
            classes={classes}
            tabs={this.getTabs()}
          />
          <div className={classes.root}>
            <AnLibrariesPageContents
              {...this.props}
              {...this.state}
              visibleTabs={this.getTabs().filter(({ hide }) => !hide)}
              userPermissions={userPermissions}
              setEditMode={(newState) => this.setState({ editMode: newState })}
              onChange={this.onChange}
              onChangeNumber={this.onChangeNumber}
              navigate={this.navigate}
              onClickCheckbox={this.onClickCheckbox}
              onToggleScheduleClientVideo={this.onOpenScheduleClientVideo}
              onChangeDate={this.onChangeDate}
              viewVideoCallDetails={this.viewVideoCallDetails}
              assignBlockRef={(ref) => (this.assignBlockRef = ref)}
              formatPhoneNumber={this.formatPhoneNumber}
              validateEmail={this.validateEmail}
              setError={this.setError}
              onTimezoneChange={this.onTimezoneChange}
              onCloseEditMode={this.onCloseEditMode}
              resetTab={this.resetTab}
              onActiveTabChange={this.onActiveTabChange}
              currentTab={this.state.currentTab}
              onChangeArrayToString={this.onChangeArrayToString}
              unsavedChanges={this.unsavedChanges}
              setUnsavedChanges={this.setUnsavedChanges}
            />
          </div>
          <Snackbar
            autoHideDuration={10000}
            onClose={() =>
              this.setState({
                snackBarOpen: false,
                snackBarError: false,
                snackBarMessage: "",
              })
            }
            open={snackBarOpen}
          >
            <Typography>{snackBarMessage}</Typography>
          </Snackbar>
        </div>
      )
    );
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AnLibraries));
