import React from "react";
import { Avatar, Box, Divider, FormControl, InputLabel, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

const ANEditItem = ({ title, input, xs, sm = 12, sx, panelMode, divider, classes }) => {
  return (
    <>
      <Grid item xs={xs} sm={sm} sx={sx} style={{ paddingLeft: 0 }}>
        {title && (
          <FormControl className="select" sx={{ width: "100%" }}>
            <InputLabel>{title}</InputLabel>
            {input && input}
          </FormControl>
        )}
        {!title && input}
      </Grid>
      {divider && (
        <Grid item xs={12} sm={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Divider
            style={{
              width: "98%",
              borderBottom: "1px solid #CDD3DA",
              marginBottom: "24px",
            }}
          />
        </Grid>
      )}
    </>
  );
};

export default ANEditItem;
