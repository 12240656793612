import React, { useEffect, useMemo, useRef, useState } from "react";
import actions from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import PageContainer from "elements/PageContainer";
import { Snackbar, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Formik } from "formik";
import * as yup from "yup";
import { DateCalendar } from "@mui/x-date-pickers";
import * as selectors from "selectors";
import { Calendar as CalendarIcon } from "@phosphor-icons/react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import momentTZ from "moment-timezone";
import { mergeClientAvailability } from "./mergeClientAvailability";
import {
  createCalendarCallEvents,
  createPrivateEventList,
} from "components/WeeklyCalendarSessions/CalendarUtils";
import { getAllClinicians } from "api/clinicians";
import { useSearchClients } from "hooks/useSearchClients";
import { useCalendarSessionsByDates } from "hooks/useCalendarSessionsByDates";
import { useLocation } from "react-router-dom";
import { LoadingScreen, durationOptions, useTimeOptions } from "./EventSchedulingUtils";
import UpdateEventDialog from "./UpdateEventDialog";
import CancelSessionDialog from "../WeeklyCalendarSessions/CancelSessionDialog";
import { getCancellationReasonLabel } from "../../constants/cancelReasons";
import { stringToBoolean } from "../ClientOnboarding/Helpers";
import { useGetHasInsuranceAuthorization } from "hooks/useGetHasInsuranceAuthorization";
import { useGetAppointmentAvailability } from "hooks/useGetAppointmentAvailability";
import useGetNonVideoServiceTypes from "../../hooks/useGetNonVideoServiceTypes";
import useEventFormSubmit from "./useEventFormSubmit";
import { addMinutesToTime, timeWithTimezoneToString } from "../../utils/dateTimeUtils";
import { useGetFeatureFlag } from "hooks/useGetFeatureFlag";
import { isToday } from "date-fns";
import VideoCallSelections from "./FormRendering/VideoCallSelections";
import AvailabilityViewer from "./FormRendering/AvailabilityViewer";
import LoadingChangesDialog from "./LoadingChangesDialog";
import { useClientAvailability } from "components/ClientDetails/Sections/ClientAvailability/hooks";
import { values } from "lodash";
import titleCaseToCamelCase from "utils/titleCaseToCamelCase";

const videoServiceTypeOptions = [
  { label: "Select", value: "" },
  { label: "Assessment", value: "ASSESSMENT", icon: <CalendarIcon /> },
  {
    label: "Caregiver Training",
    value: "CAREGIVER_TRAINING",
    icon: <CalendarIcon />,
  },
  { label: "Direct Therapy", value: "DIRECT_THERAPY", icon: <CalendarIcon /> },
  { label: "Orientation", value: "ORIENTATION", icon: <CalendarIcon /> },
];

const eventOptionsObject = {
  therapySession: { value: "therapy-session", label: "AnswersNow Video Call" },
  doxySession: { value: "doxy-session", label: "Doxy session" },
  otherServices: { value: "other-services", label: "Other Services" },
};

const eventOptions = [
  { label: "Select", value: "" },
  eventOptionsObject.therapySession,
  eventOptionsObject.doxySession,
  eventOptionsObject.otherServices,
];

export default function EventScheduling() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();
  const { id: userId } = useSelector(selectors.getUser);
  const userPermissions = useSelector(selectors.getUserPermissionsList);
  const scheduleCallLoading = useSelector(selectors.scheduleCallLoading);
  const scheduleCallSuccess = useSelector(selectors.scheduleCallSuccess);
  const [toggleButtonGroupValue, setToggleButtonGroupValue] = useState("schedule");
  const [clientInputValue, setClientInputValue] = useState("");
  const timeOptions = useTimeOptions();
  const [selectedClinicians, setSelectedClinicians] = useState([]);
  const [initialVideoClinicianLoaded, setInitialVideoClinicianLoaded] = useState(false);

  const [mergedTimeSlots, setMergedTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [updateEventDialogOpen, setUpdateEventDialogOpen] = useState(false);
  const [rescheduleAllInstances, setRescheduleAllInstances] = useState("false");
  const { data: cliniciansResponse } = useQuery("allClinicians", getAllClinicians, {
    refetchOnWindowFocus: false, // Disable refetching on window focus
  });
  const clinicians = cliniciansResponse?.data ?? [];
  const [hasAccessToOnSched, setHasAccessToOnSched] = useState(true);
  const { search } = useLocation();
  const canSelectBCBA = userPermissions?.select_scheduled_clinician;

  const query = new URLSearchParams(search);
  const videoId = query.get("id");
  const isNonVideoEvent = query.get("isNonVideoEvent") === "true";
  const clientIdParam = query.get("client");
  const clientNameParam = query.get("name");
  const isEditing = !!videoId;

  const selectedVideoChatInfo = useSelector(selectors.videoChatInfo);
  const videoChatInfo = isEditing ? selectedVideoChatInfo : null;
  const videoChatInfoLoading = useSelector(selectors.videoChatInfoLoading);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [goBackOnSnackbarClose, setGoBackOnSnackbarClose] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [cancelSessionOpen, setCancelSessionOpen] = useState(false);
  const [cancelReasonText, setCancelReasonText] = useState(false);
  const [cancelAllInstances, setCancelAllInstances] = useState(false);
  const [responsibleForCancellation, setResponsibleForCancellation] = useState("");
  const [canceled, setCanceled] = useState(false);
  const featureFlagQuery = useGetFeatureFlag(userId, "scheduling_page");

  const { onSubmit, onSubmitLoading, onSubmitSuccess, onCancelSubmit } = useEventFormSubmit({
    rescheduleAllInstances,
    selectedClinician:
      selectedClinicians?.find((cl) => cl.isPrimary) ||
      selectedClinicians?.find((cl) => !cl.isPrimary),
    secondaryClinicianUserId:
      selectedClinicians?.length > 1
        ? selectedClinicians?.find((cl) => !cl.isPrimary)?.user_id
        : null,
  });

  const onChangeCancelSelection = (e) => {
    const { value } = e.target;
    setCancelAllInstances(stringToBoolean(value));
  };
  const onChangeCancelReason = (e) => {
    setCancelReasonText(e.target.value);
  };
  const onChangeResponsibleForCancellation = (e) => {
    let cancelReasonText = getCancellationReasonLabel(e.target.value);
    setResponsibleForCancellation(e.target.value);
    if (cancelReasonText === e.target.value) {
      setCancelReasonText(null);
    } else {
      setCancelReasonText(cancelReasonText);
    }
  };
  const submitCancelSession = () => {
    onCancelSubmit({
      isNonVideoEvent,
      eventInfo: videoChatInfo,
      cancelAllInstances,
      message: cancelReasonText,
      responsibleForCancellation,
    });
    setCanceled(true);
    onToggleCancelSession();
  };

  const onToggleCancelSession = () => {
    setCancelSessionOpen(!cancelSessionOpen);
    if (cancelSessionOpen) {
      setCancelReasonText("");
    }
  };

  useEffect(() => {
    dispatch(actions.setPageDetails({ pageName: "Schedule" }));

    if (isEditing) {
      dispatch(actions.getVideoChatInfo(videoId, isNonVideoEvent));
      dispatch(actions.getAssignableClinicians());
    }
  }, []);

  useEffect(() => {
    if (onSubmitSuccess) {
      history.goBack();
    }
  }, [onSubmitSuccess]);
  // If user has no permission to access the scheduling page, redirect to previous page
  useEffect(() => {
    const { data: schedulePageFeatureFlag, isLoading } = featureFlagQuery;
    if (userId && !isLoading && !schedulePageFeatureFlag) history.goBack();
  }, [userId, featureFlagQuery]);

  // Auto select clinician if user doesn't have permission to select
  useEffect(() => {
    if (!selectedClinicians?.length > 0 && userPermissions && !canSelectBCBA) {
      const clinician = clinicians.find((clinician) => clinician.clinician_user_id === userId);
      if (clinician) {
        setSelectedClinicians([
          {
            ...clinician,
            isPrimary: true,
          },
        ]);
      }
    }
  }, [userId, clinicians, userPermissions, selectedClinicians]);

  useEffect(() => {
    canSelectBCBA && dispatch(actions.getAssignableClinicians());
  }, [userPermissions]);

  // Auto select client
  useEffect(() => {
    if (!isEditing && clientNameParam) setClientInputValue(clientNameParam);
  }, [clientNameParam]);

  // Effects to set initial states values when editing
  useEffect(() => {
    if (videoChatInfo && isEditing) {
      setClientInputValue(`${videoChatInfo.name} ${videoChatInfo.child_last_name}`);
      if (!userId) return;
      const ownEvent = videoChatInfo.clinician_user_id === userId;
      const isExpired = momentTZ(videoChatInfo.scheduled_date)
        .add(videoChatInfo.call_duration, "minutes")
        .isBefore(momentTZ());
      if (
        videoChatInfo.canceled ||
        isExpired ||
        (!canSelectBCBA && !ownEvent) ||
        videoChatInfo.no_show ||
        videoChatInfo.start_date ||
        videoChatInfo.not_started
      ) {
        history.goBack(); // Don't allow editing, just go back
      }
    }
  }, [videoChatInfo, userId]);

  useEffect(() => {
    if (videoChatInfo && !selectedClinicians && clinicians.length > 0) {
      // current user
      setSelectedClinicians([
        {
          ...clinicians.find((clinician) => clinician.clinician_user_id === userId),
          isPrimary: true,
        },
      ]);
    }
  }, [videoChatInfo, clinicians, values.client?.user_id]);

  const nonVideoEventServiceTypes = useGetNonVideoServiceTypes();
  const nonVideoEventServiceTypeOptions = nonVideoEventServiceTypes.map(({ id, description }) => ({
    label: description,
    value: id,
  }));

  const serviceTypeLookup = useMemo(
    () =>
      [...videoServiceTypeOptions, ...nonVideoEventServiceTypeOptions].reduce((acc, item) => {
        acc[item.value] = item.label;
        return acc;
      }, {}),
    [nonVideoEventServiceTypeOptions]
  );

  if (videoChatInfoLoading || (!videoChatInfo && isEditing)) return <LoadingScreen />;
  let initialValues = {
    event: "",
    client: null,
    serviceType: "",
    bcba: "",
    eventDate: new Date(),
    startTime: "",
    duration: 60,
    timeUnit: "",
    isRepeating: false,
    timeInterval: 1,
    repeatEndType: "",
    repeatUntilDate: null,
    repeatDays: [],
  };

  if (isEditing) {
    initialValues = {
      ...initialValues,
      event: isNonVideoEvent
        ? eventOptionsObject.otherServices.value
        : videoChatInfo.off_platform
          ? "doxy-session"
          : "therapy-session",
      serviceType: isNonVideoEvent
        ? parseFloat(videoChatInfo.billing_type)
        : videoChatInfo.billing_type,
      bcba: videoChatInfo.clinician_user_id,
      eventDate: new Date(videoChatInfo.scheduled_date),
      startTime: momentTZ(videoChatInfo.scheduled_date).format("h:mm A"),
      duration: videoChatInfo.call_duration,
      timeUnit: videoChatInfo.time_unit,
      isRepeating: videoChatInfo.is_recurring,
      timeInterval: videoChatInfo.time_interval,
      repeatEndType: videoChatInfo.repeat_type,
      repeatUntilDate: videoChatInfo.repeat_until_date
        ? new Date(videoChatInfo.repeat_until_date)
        : null,
      repeatDays:
        videoChatInfo.repeat_days && videoChatInfo.repeat_days !== ""
          ? videoChatInfo.repeat_days.split(",").map(Number)
          : [],
    };
  }

  const notPastTime = (value, context) => {
    const eventDate = context.parent.eventDate;
    const time = momentTZ(value, "h:mm A");
    if (isToday(eventDate) && time.isBefore(momentTZ())) return false;
    return true;
  };

  const validationSchema = yup.object({
    event: yup.string().required("Please select an event type"),
    client: yup.object().when([], (_, schema) => {
      return !isEditing
        ? schema.required("Please select a client to include for the Event")
        : schema.nullable();
    }),
    serviceType: yup.string().required("Please select a Service for the Event"),
    bcba: yup
      .string()
      .test("bcba-required", "Please select a Provider for the Event", (value) =>
        canSelectBCBA ? !!value : true
      ),
    eventDate: yup.date().required("Please select a date for the Event"),
    startTime: yup
      .string()
      .required("Please select the start time of the Event")
      .test("not-past-time", "Event time can't be in the past", notPastTime),
    duration: yup
      .number()
      .required("Please select the duration of the Event")
      .test("healthy-duration", "Event length can't exceed two hours", (value) =>
        !hasAccessToOnSched ? value <= 120 : true
      )
      .test("onSched-duration", "Event length can't exceed three hours", (value) =>
        hasAccessToOnSched ? value <= 180 : true
      ),
    isRepeating: yup.boolean(),
    timeUnit: yup
      .string()
      .nullable()
      .when("isRepeating", {
        is: true,
        then: () => yup.string().required("Please select the repeat pattern"),
      }),
    timeInterval: yup
      .number()
      .nullable()
      .when("isRepeating", {
        is: true,
        then: () =>
          yup
            .number()
            .positive("Must be a positive number")
            .integer("Must be an integer")
            .required("Please select the repeat pattern"),
      }),
    repeatEndType: yup.string().when("isRepeating", {
      is: true,
      then: () => yup.string().required("Please select when to end the event"),
    }),
    repeatUntilDate: yup
      .date()
      .nullable()
      .when("repeatEndType", {
        is: "until_date",
        then: (schema) =>
          schema
            .min(yup.ref("eventDate"), "Repeat until date must be after the event date")
            .required("Please select when to end the event"),
      }),
    repeatDays: yup.array().of(yup.number()),
  });

  return (
    <PageContainer horizontalPadding={false} fixHeight>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => onSubmit(values, videoChatInfo)}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleSubmit,
          touched,
          errors,
          handleBlur,
          validateForm,
          setTouched,
        }) => {
          const isOtherServicesEvent = values.event === eventOptionsObject.otherServices.value;

          // Determine service type options based on event type
          const serviceTypeOptions = useMemo(
            () =>
              isOtherServicesEvent ? nonVideoEventServiceTypeOptions : videoServiceTypeOptions,
            [values.event, nonVideoEventServiceTypeOptions]
          );
          // Fetch client availability data
          const dayOfWeek = momentTZ(values?.eventDate)
            ?.startOf("day")
            ?.format("dddd")
            .toLowerCase();
          const [clientAvailabilityData, setClientAvailabilityData] = useState([]);
          const { data, isLoading, refetch } = useClientAvailability({
            clientId: values?.client?.id,
            serviceType:
              typeof values?.serviceType === "string"
                ? titleCaseToCamelCase(values?.serviceType)
                : values?.serviceType ?? "directTherapy", // Send integer or default value
            dayOfWeek,
            enabled: !!values?.client?.user_id && !!values?.eventDate,
            onSuccess: ({ data }) =>
              setClientAvailabilityData(data?.services[0]?.availability[dayOfWeek]),
          });

          // Refetch client availability data when client ID, eventDate, or serviceType changes
          useEffect(() => {
            if (values?.client?.user_id && values?.eventDate && values?.serviceType) {
              refetch();
            }
          }, [values?.client?.user_id, values?.eventDate, values?.serviceType]);

          // Load clinicians based on video chat info
          useEffect(() => {
            if (
              !initialVideoClinicianLoaded &&
              videoChatInfo &&
              clinicians &&
              !selectedClinicians?.length
            ) {
              // Filter and map clinicians to include isPrimary property
              const loadedInClinicians = clinicians.filter((clinician) =>
                [
                  videoChatInfo.clinician_user_id,
                  videoChatInfo.secondary_clinician_user_id,
                ].includes(clinician.clinician_user_id)
              );
              // Sort the loadedInClinicians to put primary clinician first
              loadedInClinicians.sort((a, b) => b.isPrimary - a.isPrimary);

              if (loadedInClinicians.length > 0) {
                setInitialVideoClinicianLoaded(true);
                setSelectedClinicians(loadedInClinicians);

                // Collect clinician user IDs into a comma-separated string
                const clinicianUserIds = loadedInClinicians
                  .map((clinician) => clinician.user_id)
                  .join(",");

                handleChange({
                  target: {
                    name: "bcba",
                    value: clinicianUserIds,
                  },
                });
              }
            }
          }, [videoChatInfo, clinicians, selectedClinicians, initialVideoClinicianLoaded]);

          // Manually validates the form and returns any errors
          const manuallyValidateForm = async () => {
            const errors = await validateForm();
            if (Object.keys(errors).length > 0) {
              setTouched(
                Object.keys(values).reduce((acc, curr) => {
                  acc[curr] = true;
                  return acc;
                }, {})
              );
              return errors;
            }
          };

          const serviceTypeError = touched.serviceType && Boolean(errors.serviceType);
          const serviceTypeHelperText = serviceTypeError
            ? errors.serviceType
            : "This can be changed prior to submitting for billing";

          // Parse the date based on the selected clinician's timezone
          const parsedDate =
            selectedClinicians?.length > 0
              ? momentTZ(values?.eventDate)
                  ?.tz(selectedClinicians[0]?.timezone)
                  ?.format("YYYY-MM-DD")
              : null;

          // Fetch onSched availability
          const { data: onSchedAvailability, isLoading: loadingOnSchedAvailability } =
            useGetAppointmentAvailability(
              selectedClinicians?.length > 0
                ? selectedClinicians.map((clinician) => clinician.clinician_id)
                : [],
              "DIRECT_THERAPY",
              values.duration,
              parsedDate,
              parsedDate
            );

          // Merge availability data
          useEffect(() => {
            if (
              !loadingOnSchedAvailability &&
              onSchedAvailability?.data &&
              selectedClinicians?.length > 0
            ) {
              let mergedSlots = {};
              Object.keys(onSchedAvailability.data).forEach((id) => {
                const timezone = selectedClinicians[0].timezone;
                mergedSlots[id] = onSchedAvailability.data[id].map((slot) => {
                  if (!slot.startDateTime || !slot.endDateTime) {
                    return;
                  }
                  const slotStart = momentTZ.tz(slot.startDateTime, timezone);
                  return momentTZ.tz(slotStart, timezone).toISOString();
                });
              });
              setMergedTimeSlots(mergedSlots);
            }
          }, [onSchedAvailability?.data, loadingOnSchedAvailability, selectedClinicians]);

          // Fetch calendar sessions by dates
          const { data: response } = useCalendarSessionsByDates({
            startDate: momentTZ(values.eventDate).startOf("day").format(),
            endDate: momentTZ(values.eventDate).endOf("day").format(),
            userIdList: [
              ...(selectedClinicians?.length > 0
                ? selectedClinicians.map((clinician) => clinician.clinician_user_id)
                : []),
              values.client?.user_id,
            ],
          });

          // Process calendar events and non-working hours
          const privateEventsList = createPrivateEventList(response?.data.googleCalendarEvents);
          const callList = createCalendarCallEvents(
            response?.data.calendarSessions,
            serviceTypeLookup
          );
          const allEvents = [...callList, ...privateEventsList];
          const nonWorkingHours = response?.data.nonWorkingHours;

          // Fetch client list
          const { data: clientsResponse } = useSearchClients(userId, clientInputValue, true, true);
          const clientList = clientsResponse?.data ?? [];
          const userScheduleColorMap = {
            ...(selectedClinicians?.length > 0
              ? selectedClinicians.reduce((map, clinician, index) => {
                  map[clinician.user_id] = index + 1;
                  return map;
                }, {})
              : {}),
            [values.client?.user_id]: 0,
          };

          // Auto-select client based on the client list and editing state
          useEffect(() => {
            if (clientList.length > 0 && !values.client) {
              const clientId = isEditing ? videoChatInfo.client_id : clientIdParam;
              setFieldValue(
                "client",
                clientList.find((client) => client.client_id === clientId)
              );
            }
          }, [clientList]);

          // Reset service type if event changes
          useEffect(() => {
            if (
              values.event === "doxy-session" ||
              !serviceTypeOptions.some(({ value }) => value === values.serviceType)
            ) {
              setFieldValue("serviceType", "");
            }
          }, [values.event]);

          // Handle scheduling success or cancellation
          useEffect(() => {
            if (scheduleCallSuccess || canceled) {
              setGoBackOnSnackbarClose(true);
              if (canceled && !cancelAllInstances) {
                setSnackbarMessage("Event successfully canceled");
              } else if (canceled && cancelAllInstances) {
                setSnackbarMessage(
                  "This event and future sessions have been successfully canceled"
                );
              } else if (isEditing) {
                setSnackbarMessage("Event changes were successfully updated");
              } else {
                setSnackbarMessage("Event scheduled successfully");
              }
              setOpenSnackbar(true);
              setUpdateEventDialogOpen(false);
            }
          }, [scheduleCallSuccess, canceled]);

          // Handle selecting days for repeating events
          const handleSelectDay = (day) => {
            const { repeatDays } = values;
            if (repeatDays.includes(day)) {
              setFieldValue(
                "repeatDays",
                repeatDays.filter((d) => d !== day)
              );
            } else {
              setFieldValue(
                "repeatDays",
                [...repeatDays, day].sort((a, b) => a - b)
              );
            }
          };

          // Get the color for the selected day
          const getSelectedDayColor = (day) => {
            return values.repeatDays.includes(day) ? "primary" : "secondary";
          };

          // Fetch insurance authorization status
          const {
            data: insuranceAuthorizationResponse,
            isLoading: isLoadingInsuranceAuthorization,
          } = useGetHasInsuranceAuthorization(values.client?.client_id);
          const hasInsuranceAuthorization =
            insuranceAuthorizationResponse?.data?.hasInsuranceAuthorization ?? false;

          // Update repeat end type based on insurance authorization status
          useEffect(() => {
            if (
              insuranceAuthorizationResponse &&
              !hasInsuranceAuthorization &&
              !isLoadingInsuranceAuthorization &&
              values.repeatEndType === "current_auth_period" &&
              !isEditing
            ) {
              setFieldValue("repeatEndType", "");
            }
          }, [insuranceAuthorizationResponse]);

          // Render the user's timezone information
          const renderUserTimezone = () => {
            if (!values.client || (!videoChatInfo && isEditing)) return;
            const timezone = isEditing ? videoChatInfo.client_timezone : values.client.timezone;
            const name = isEditing ? videoChatInfo.name : values.client.name;

            const startTime = timeWithTimezoneToString(values.startTime, timezone);
            const endTime = addMinutesToTime(values.startTime, values.duration, timezone);
            return (
              <Typography variant="caption">
                {`${startTime} - ${endTime} for ${name} (${momentTZ().tz(timezone).format("z")})`}
              </Typography>
            );
          };

          const [clinicianOptions, setClinicianOptions] = useState([]);

          return (
            <Stack direction="row" height="100%">
              <VideoCallSelections
                theme={theme}
                values={values}
                isEditing={isEditing}
                isOtherServicesEvent={isOtherServicesEvent}
                eventOptions={eventOptions}
                eventOptionsObject={eventOptionsObject}
                clientList={clientList}
                clientInputValue={clientInputValue}
                setClientInputValue={setClientInputValue}
                serviceTypeLookup={serviceTypeLookup}
                serviceTypeOptions={serviceTypeOptions}
                serviceTypeError={serviceTypeError}
                serviceTypeHelperText={serviceTypeHelperText}
                canSelectBCBA={canSelectBCBA}
                clinicians={clinicians}
                selectedClinicians={selectedClinicians}
                setSelectedClinicians={setSelectedClinicians}
                touched={touched}
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                setClinicianOptions={setClinicianOptions}
                clinicianOptions={clinicianOptions}
                timeOptions={timeOptions}
                isToday={isToday}
                durationOptions={durationOptions}
                renderUserTimezone={renderUserTimezone}
                scheduleCallLoading={scheduleCallLoading}
                onSubmitLoading={onSubmitLoading}
                openSnackbar={openSnackbar}
                history={history}
                manuallyValidateForm={manuallyValidateForm}
                setUpdateEventDialogOpen={setUpdateEventDialogOpen}
                handleSubmit={handleSubmit}
                setCancelSessionOpen={setCancelSessionOpen}
                hasInsuranceAuthorization={hasInsuranceAuthorization}
                handleSelectDay={handleSelectDay}
                getSelectedDayColor={getSelectedDayColor}
                videoChatInfo={videoChatInfo}
              />
              <Stack gap={theme.spacing(2)} padding="44px">
                <Typography paddingLeft={theme.spacing(7)} variant="subtitle1">
                  Select a date and time
                </Typography>
                <DateCalendar
                  name="eventDate"
                  value={values.eventDate}
                  disablePast
                  onChange={(value) => setFieldValue("eventDate", value, true)}
                />
              </Stack>
              <AvailabilityViewer
                values={values}
                selectedClinicians={selectedClinicians}
                setFieldValue={setFieldValue}
                allEvents={allEvents}
                nonWorkingHours={nonWorkingHours}
                userScheduleColorMap={userScheduleColorMap}
                mergedTimeSlots={mergedTimeSlots}
                loadingOnSchedAvailability={loadingOnSchedAvailability}
                clientAvailability={mergeClientAvailability(clientAvailabilityData?.ranges)}
              />
              {values.isRepeating && (
                <LoadingChangesDialog
                  open={scheduleCallLoading || onSubmitLoading}
                  title={isEditing ? "Updating your event" : "Creating your event"}
                  description="We're adding the final touches. This may take a few moments."
                />
              )}

              <UpdateEventDialog
                open={updateEventDialogOpen}
                onClose={() => setUpdateEventDialogOpen(false)}
                rescheduleAllInstances={rescheduleAllInstances}
                setRescheduleAllInstances={setRescheduleAllInstances}
                showRecurringOptions={values.isRepeating}
              />
              <CancelSessionDialog
                sessionDetails={videoChatInfo}
                open={cancelSessionOpen}
                cancelReasonText={cancelReasonText}
                responsibleForCancellation={responsibleForCancellation}
                closeDialog={onToggleCancelSession}
                onChangeCancelReason={onChangeCancelReason}
                submitCancelSession={submitCancelSession}
                onChangeResponsibleForCancellation={onChangeResponsibleForCancellation}
                cancelAllInstances={cancelAllInstances}
                onChangeCancelSelection={onChangeCancelSelection}
              />
            </Stack>
          );
        }}
      </Formik>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => {
          setOpenSnackbar(false);
          if (goBackOnSnackbarClose) {
            history.goBack();
            dispatch(actions.resetScheduleVideoCallState());
          }
        }}
      >
        <Typography align="center">{snackbarMessage}</Typography>
      </Snackbar>
    </PageContainer>
  );
}
