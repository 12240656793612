import React, { useRef, useState, useEffect } from "react";
import { Tooltip } from "@mui/material";

/**
 * Component for rendering a tooltip only when the content is overflowed. Also, the content will be ellipsis if overflowed.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The children to render.
 * @param {React.ElementType} props.component - The component to render. Default is "div".
 * @param {Object} props.tooltipProps - The props to pass to the tooltip.
 */
const ANTooltip = ({ children, component: Component = "div", tooltipProps, style, ...props }) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      if (textElementRef.current) {
        setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (textElementRef.current) resizeObserver.observe(textElementRef.current);
    handleResize(); // Initial check

    return () => {
      if (textElementRef.current) resizeObserver.unobserve(textElementRef.current);
    };
  }, []);

  return (
    <Component
      ref={textElementRef}
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        ...style,
      }}
      {...props}
    >
      {isOverflowed ? (
        <Tooltip title={children} {...tooltipProps}>
          <span>{children}</span>
        </Tooltip>
      ) : (
        children
      )}
    </Component>
  );
};

export default ANTooltip;
