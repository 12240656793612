import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useTheme } from "@mui/styles";

import {
  deleteClinicianCertificationAsync,
  saveClinicianCertificationAsync,
  updateClinicianCertificationAsync,
} from "api/clinician";
import { postUploadFiles } from "hooks/postUploadFiles";
import { openS3Document } from "utils/openS3Document";
import { CLINICIAN_CERTIFICATION } from "constants/reactQueryKeys";
import SectionBox from "elements/SectionBox";
import { useGlobalToast } from "components/GlobalToastProvider";
import SectionList from "./SectionList";
import CertificationDialog from "./Dialogs/AddEditCertificationDialog";
import DeleteAlertDialog from "./Dialogs/DeleteAlertDialog";
/** @typedef {import("api/typesDef").Certification} Certification */

export default function Cartifications({ basicInfo, certifications = [], editMode }) {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const { showToast } = useGlobalToast();
  // Retry State for mutations
  const [retry, setRetry] = useState(0);

  // Dialog States
  const [openAddEditDialog, setOpenAddEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isNewCertification, setIsNewCertification] = useState(true);

  /** @type {ReturnType<typeof useState<(Certification)>>} */
  const [selectedCertification, setSelectedCertification] = useState(null);

  // Menu Options
  const list = certifications.map((certification) => ({
    id: certification.pkey,
    title: certification.certification_number || "Missing certification number",
    expirationDate: certification.expiry_date,
  }));

  const onSelectElement = (id) => {
    const currentCertification = certifications.find(
      (certification) => certification.pkey === parseInt(id)
    );
    setSelectedCertification(currentCertification);
  };

  // Mutations
  const { mutate: saveCertification } = useMutation(
    isNewCertification ? saveClinicianCertificationAsync : updateClinicianCertificationAsync,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CLINICIAN_CERTIFICATION);
        showToast({
          message: isNewCertification ? "Certification added!" : "Certification updated!",
        });
        setRetry(0);
      },
      onError: (error) => {
        console.error(error);
        showToast({
          message: "Whoops! Something went wrong",
          errorState: true,
          retryHandler: () => setRetry(1),
        });
      },
      onSettled: () => {
        handleCloseAddEditDialog();
      },
      retry,
    }
  );

  const { mutate: deleteCertification, isLoading: isDeletingCertification } = useMutation(
    deleteClinicianCertificationAsync,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(CLINICIAN_CERTIFICATION);
        showToast({ message: "Certification removed!" });
        setRetry(0);
      },
      onError: (error) => {
        console.error(error);
        showToast({
          message: "Whoops! Something went wrong",
          errorState: true,
          retryHandler: () => setRetry(1),
        });
      },
      onSettled: () => {
        handleCloseDeleteDialog();
      },
      retry,
    }
  );

  // Dialog handler
  const handleOpenCertificationDialog = (isNew) => {
    setIsNewCertification(isNew);
    setOpenAddEditDialog(true);
  };

  const handleCloseAddEditDialog = () => {
    setOpenAddEditDialog(false);
    setSelectedCertification(null);
  };
  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  // Formik Submit Handler
  const handleSubmitCertification = (values) => {
    const certificationFile = values.certificationFile[0]?.alreadyUploaded
      ? []
      : values.certificationFile;

    return postUploadFiles(basicInfo.cognito_id, certificationFile).then(
      async ({ errors, success: filename }) => {
        const file = certificationFile[0]?.file;

        if (errors) {
          console.error(errors);
          return;
        }

        let certificationData = {
          clinicianId: basicInfo.id,
          certificationNumber: values.certificationNumber,
          expiryDate: values.expiryDate,
          issuedOn: values.issuedOn,
          displayFilename: filename ? file?.name : selectedCertification.display_filename,
          fileSize: filename ? file.size : selectedCertification.file_size,
        };

        if (isNewCertification) {
          certificationData = { ...certificationData, imageName: filename.split("/")[1] };
        } else {
          certificationData = {
            ...certificationData,
            id: selectedCertification.pkey,
            imageName: filename ? filename.split("/")[1] : selectedCertification?.image_name,
          };
          setSelectedCertification(null);
        }

        await saveCertification(certificationData);
      }
    );
  };

  // Delete Certification handler
  const handleDeleteCertification = () => {
    const certPayload = {
      clinicianId: selectedCertification.clinician_id,
      id: selectedCertification.pkey,
    };

    deleteCertification(certPayload);
  };

  // Download Certification handler
  const handleDownloadCertification = () => {
    openS3Document({ cognitoId: basicInfo.cognito_id, filename: selectedCertification.image_name });
  };

  return (
    <SectionBox
      title="Certifications"
      description={"Update and add certifications on file for the Clinician"}
      padding={theme.spacing(5, 0)}
    >
      <SectionList
        list={list}
        editMode={editMode}
        addHandler={() => handleOpenCertificationDialog(true)}
        editHandler={() => handleOpenCertificationDialog(false)}
        deleteHandler={() => setOpenDeleteDialog(true)}
        downloadHandler={handleDownloadCertification}
        onSelectElement={onSelectElement}
        noDataMessage="No Certifications on the account"
        menuActionText="Certification"
      />

      <CertificationDialog
        cognitoId={basicInfo.cognito_id}
        open={openAddEditDialog}
        handleClose={handleCloseAddEditDialog}
        isNew={isNewCertification}
        currentCertification={selectedCertification}
        onSubmit={handleSubmitCertification}
        certifications={certifications}
      />

      <DeleteAlertDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onDelete={handleDeleteCertification}
        isLoading={isDeletingCertification}
        title="Delete Certification Document?"
      />
    </SectionBox>
  );
}
