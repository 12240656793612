import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Radio from "@mui/material/Radio";
import { withRouter } from "react-router";
import actions from "../../../actions";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Lottie from "react-lottie";
import StandardLayout from "../../../assets/standard.png";
import ActivitySplitLayout from "../../../assets/activity_split.png";
import ActivityFocusLayout from "../../../assets/activity_focus.png";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import LoadingButton from "@mui/lab/LoadingButton";

import {
  getSignedinState,
  getLogin,
  isAlpha,
  isBeta,
  isSubscribed,
  isExpired,
  canMessage,
  userClinician,
  getUserPermissionsList,
  userLoaded,
  getUser,
  getCurrentChannelUrl,
} from "../../../selectors";
import TextField from "@mui/material/TextField";

const mapStateToProps = (state) => ({
  user: getUser(state),
  userLoaded: userLoaded(state),
  chatUrl: getCurrentChannelUrl(state),
  signedInState: getSignedinState(state),
  login: getLogin(state),
  alpha: isAlpha(state),
  beta: isBeta(state),
  subscribed: isSubscribed(state),
  expired: isExpired(state),
  canMessage: canMessage(state),
  clinician: userClinician(state),
  userPermissions: getUserPermissionsList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPageDetails: actions.setPageDetails,
      sendMessage: actions.sendMessage,
      setVideoRoomId: actions.setVideoRoomId,
    },
    dispatch
  );

function getInitialState() {
  return {
    showLayouts: false,
    videoActivityDialogOpen: false,
    videoType: null,
    activityId: null,
    videoLoading: false,
  };
}

class ActivityDrawer extends Component {
  constructor() {
    super();
    this.anchorRef = React.createRef();
    this.state = {
      ...getInitialState(),
    };
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isCaregiver) {
      if (!prevProps.controlsPassed && this.props.controlsPassed) {
        if (this.state.showLayouts) {
          this.setState({ showLayouts: false });
        }
      }
    }
  }

  onLayoutClick = () => {
    this.setState({ showLayouts: !this.state.showLayouts });
  };

  onSelectLayout = (layout) => {
    this.props.updateRoomLayout(layout);
  };

  onVideoActivityClick = (event, id) => {
    this.setState({
      videoActivityDialogOpen: true,
      videoType: "activity",
      activityId: id,
    });
  };

  onVideoReinforcementClick = (event) => {
    this.setState({
      videoActivityDialogOpen: true,
      videoType: "reinforcement",
    });
  };

  onCloseVideoActivityDialog = () => {
    this.setState({
      videoActivityDialogOpen: false,
      videoType: null,
    });
  };

  onStartVideo = () => {
    if (this.state.videoType === "reinforcement") {
      this.props.startVideoReinforcement();
    } else {
      this.props.startVideoActivity(this.state.activityId);
    }

    const delay = () => {
      return new Promise((resolve) => setTimeout(resolve, 1000));
    };

    this.setState({ videoLoading: true }, async () => {
      await delay();
      this.onCloseVideoActivityDialog();
      if (this.props.roomLayout === "standard") {
        this.onSelectLayout("activity_split");
      }
      this.props.setVideoPlaying(true);
      await delay();
      this.setState({ videoLoading: false });
    });
  };

  handleActivityClick = (e, activity) => {
    const { type } = activity;
    switch (type) {
      case "video":
        this.onVideoActivityClick(e, activity.id);
        return;
      case "prompt":
        this.props.onSelectPromptActivity(activity);
        return;
      default:
        this.props.startActivity(activity);
    }
  };

  render() {
    const {
      classes,
      chatOpen,
      roomLayout,
      animations,
      startAnimation,
      activities,
      animation,
      activity,
      endAnimation,
      endActivity,
      videoURL,
      setVideoURL,
      setVideoStartTime,
      videoStartTime,
      hasJoinedRoom,
      userPermissions,
      clinicianInControl,
      isCaregiver,
      activityDrawerOpen,
      toggleActivityDrawer,
    } = this.props;

    return (
      <>
        <Drawer
          className={classes.activityDrawer}
          anchor="right"
          open={activityDrawerOpen}
          variant="temporary"
          ModalProps={{ hideBackdrop: true }}
          onClose={toggleActivityDrawer}
          classes={{
            paper: classes.activityDrawerPaper,
          }}
        >
          <div className={classes.activityDrawerContainer}>
            <div className={classes.activityDrawerHeader}>
              Activities
              <IconButton onClick={toggleActivityDrawer} aria-label="Previous Page" size="large">
                <CloseIcon />
              </IconButton>
            </div>
            <div className={classes.activityDrawerContent}>
              <>
                <div className={!clinicianInControl ? classes.videoControlsDisabled : null}>
                  <div>
                    {(userPermissions?.control_activities ||
                      userPermissions?.control_reinforcements) && (
                      <div className={classes.activityDrawerTitle}>{"Interactive"}</div>
                    )}
                    {userPermissions?.control_activities && (
                      <div className={classes.animationsContainer}>
                        {activities &&
                          activities.map((activity) => {
                            return (
                              <div
                                key={activity.id}
                                onClick={(e) => this.handleActivityClick(e, activity)}
                                className={classes.animation}
                              >
                                <img src={activity.image_url} />
                                <div className="lottieTitle">{activity.type}</div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                    <div className={classes.activityDrawerTitle} style={{ marginBottom: 16 }}>
                      {"Reinforcement"}
                    </div>
                    {userPermissions?.control_reinforcements && (
                      <div className={classes.animationsContainer}>
                        {animations
                          ? animations.map((animation) => (
                              <div
                                key={animation.id}
                                onClick={() => startAnimation(animation)}
                                className={classes.animation}
                              >
                                <div className="lottieContainer">
                                  <Lottie
                                    options={{
                                      loop: true,
                                      autoplay: true,
                                      path: animation.thumbnail_url,
                                      rendererSettings: {
                                        preserveAspectRatio: "xMidYMid slice",
                                      },
                                    }}
                                    width={50}
                                  />
                                </div>
                                <div className="lottieTitle">{animation.title}</div>
                              </div>
                            ))
                          : null}
                        <div onClick={this.onVideoReinforcementClick} className={classes.animation}>
                          <img src={"https://aba-cards.s3.amazonaws.com/Video_Icon.png"} />
                          <div className="lottieTitle">Video</div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    {userPermissions?.change_video_view && (
                      <>
                        <div className={classes.activityDrawerTitle}>{"Layout"}</div>
                        <div className={classes.drawerCurrentLayout}>
                          {roomLayout === "standard" && (
                            <img className={classes.drawerLayoutImage} src={StandardLayout} />
                          )}
                          {roomLayout === "activity_split" && (
                            <img className={classes.drawerLayoutImage} src={ActivitySplitLayout} />
                          )}
                          {roomLayout === "activity_focus" && (
                            <img className={classes.drawerLayoutImage} src={ActivityFocusLayout} />
                          )}
                        </div>
                        <div className={classes.drawerLayouts}>
                          <div
                            onClick={() => this.onSelectLayout("standard")}
                            className={
                              roomLayout === "standard"
                                ? classes.drawerCurrentLayout
                                : classes.drawerLayout
                            }
                          >
                            <label style={{ paddingLeft: 0 }}>
                              <Radio
                                color="primary"
                                size="small"
                                readOnly
                                checked={roomLayout === "standard"}
                              />
                              Standard
                            </label>
                          </div>
                          <div
                            onClick={() => this.onSelectLayout("activity_split")}
                            className={
                              roomLayout === "activity_split"
                                ? classes.drawerCurrentLayout
                                : classes.drawerLayout
                            }
                          >
                            <label>
                              <Radio
                                color="primary"
                                size="small"
                                readOnly
                                checked={roomLayout === "activity_split"}
                              />
                              Split
                            </label>
                          </div>
                          <div
                            onClick={() => this.onSelectLayout("activity_focus")}
                            className={
                              roomLayout === "activity_focus"
                                ? classes.drawerCurrentLayout
                                : classes.drawerLayout
                            }
                          >
                            <label>
                              <Radio
                                color="primary"
                                size="small"
                                readOnly
                                checked={roomLayout === "activity_focus"}
                              />
                              Activity
                            </label>
                          </div>
                          <div className="tip">
                            Choose the layout that best supports the session needs.
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            </div>
          </div>
        </Drawer>
        <Dialog
          className={classes.videoActivityDialog}
          open={this.state.videoActivityDialogOpen}
          onClose={this.onCloseVideoActivityDialog}
          style={{ zIndex: 4000 }}
          PaperProps={{
            style: {
              backgroundColor: "#fff",
              width: 492,
            },
          }}
          maxWidth="xl"
        >
          <div style={{ width: 492 }}>
            <div className={classes.videoActivityDialogTitle}>Video Activity</div>
            <div className={classes.videoActivityDialogContent}>
              <div className="inputLabel">Video URL</div>
              <div className="input">
                <TextField
                  disabled={
                    (activity.active && activity.type === "video") ||
                    (animation.active && animation.type === "video")
                  }
                  onChange={setVideoURL}
                  value={videoURL ?? ""}
                  name="videoURL"
                  autoComplete="off"
                  fullWidth
                />
              </div>
              <div className="inputInfo">YouTube URLs only</div>
              <div className="inputLabel" style={{ marginTop: 24 }}>
                Start Time (Optional)
              </div>
              <div className="input">
                <TextField
                  disabled={
                    (activity.active && activity.type === "video") ||
                    (animation.active && animation.type === "video")
                  }
                  onChange={setVideoStartTime}
                  value={videoStartTime ?? ""}
                  name="videoStartTime"
                  autoComplete="off"
                  fullWidth
                />
              </div>
              <div className="inputInfo">In seconds</div>
            </div>
            {((activity.active && activity.type === "video") ||
              (animation.active && animation.type === "video")) &&
              !this.state.videoLoading && (
                <Typography
                  component="p"
                  style={{ width: "100%", textAlign: "center" }}
                  className={classes.popoverEndActivityMessage}
                >
                  Please end the current video activity
                </Typography>
              )}
            {activity.active &&
              !this.state.videoLoading &&
              activity.type !== "video" &&
              this.state.videoType === "activity" && (
                <Typography
                  component="p"
                  style={{ width: "100%", textAlign: "center" }}
                  className={classes.popoverEndActivityMessage}
                >
                  This will end your current activity
                </Typography>
              )}
            <div className="btnGroup">
              <div className="btn">
                <Button onClick={this.onCloseVideoActivityDialog} variant="text">
                  Cancel
                </Button>
              </div>
              <div className="btn">
                <LoadingButton
                  disabled={
                    (activity.active && activity.type === "video") ||
                    (animation.active && animation.type === "video") ||
                    !videoURL
                  }
                  onClick={this.onStartVideo}
                  loading={this.state.videoLoading}
                >
                  Start Video
                </LoadingButton>
              </div>
            </div>
          </div>
        </Dialog>
        {!chatOpen && hasJoinedRoom && !isCaregiver && (
          <div className={classes.engineControls}>
            {animation.active && clinicianInControl && (
              <Button onClick={endAnimation} className={classes.endAnimationButton}>
                {animation.type === "video" ? "End Video Activity" : "End Animation"}
              </Button>
            )}
            {activity.active && activity.type === "video" && clinicianInControl && (
              <Button onClick={endActivity} className={classes.endAnimationButton}>
                End Video Activity
              </Button>
            )}
          </div>
        )}
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivityDrawer));
