import { createReducer } from "redux-create-reducer";
import actions from "../actions";

const initialState = {
  providers: [],
  providersLoading: false,
  providersNotAccepted: [],
  cptCodes: [],
  authorizationCodes: [],
  userCodes: [],
  userCodesLoading: false,
  userInsurance: [],
  userInsuranceLoading: false,
  billableTimeLoading: false,
  billableTimeSubmitting: false,
  billableTime: [],
  offPlatformLoading: false,
  offPlatformSuccess: false,
  offPlatformWork: [],
  updateBillableTimeInsuranceCodeLoading: false,
  updateBillableTimeInsuranceCodeSuccess: false,
  updateBillableTimeInsuranceCodeError: false,
  availableCptCodes: [],
  superBillsOffset: 0,
  superBillsPage: 0,
  superBillsCount: 0,
  superBillsLoading: false,
  superBills: [],
  healthiePlansResults: [],
  healthiePlansLoading: false,
  referringPhysicians: [],
  createPhysicianLoading: false,
  createPhysicianId: null,
  plans: [],
  insurancePlan: null,
  providersList: [],
  insuranceProvider: null,
  insuranceLoading: false,
  insuranceSuccess: false,
  insuranceState: "",
  insuranceProviderId: "",
  plansCount: 0,
  plansPage: 0,
  plansRowsPerPage: 25,
  plansSort: "",
  plansSortDirection: "asc",
  createPlanLoading: false,
  createPlanSuccess: false,
  createProviderLoading: false,
  createProviderSuccess: false,
  createProviderError: false,
};

const reducers = {
  [actions.GET_INSURANCE_PROVIDERS](state, { payload }) {
    return {
      ...state,
      providers: [],
      providersLoading: true,
    };
  },
  [actions.GET_INSURANCE_PROVIDERS_SUCCESS](state, { payload }) {
    return {
      ...state,
      providers: payload,
      providersLoading: false,
    };
  },
  [actions.GET_INSURANCE_PROVIDERS_NOT_ACCEPTED_SUCCESS](state, { payload }) {
    return {
      ...state,
      providersNotAccepted: payload,
    };
  },
  [actions.GET_INSURANCE_CODES_SUCCESS](state, { payload }) {
    return {
      ...state,
      cptCodes: payload,
    };
  },
  [actions.GET_AUTHORIZATION_CODES](state) {
    return {
      ...state,
    };
  },
  [actions.GET_AUTHORIZATION_CODES_SUCCESS](state, { payload }) {
    return {
      ...state,
      authorizationCodes: payload,
    };
  },
  [actions.GET_INSURANCE_CODES_BY_USER](state) {
    return {
      ...state,
      userCodesLoading: true,
    };
  },
  [actions.GET_INSURANCE_CODES_BY_USER_SUCCESS](state, { payload }) {
    return {
      ...state,
      userCodes: payload,
      userCodesLoading: false,
    };
  },
  [actions.GET_INSURANCE_CODES_BY_USER_FAIL](state) {
    return {
      ...state,
      userCodesLoading: false,
    };
  },
  [actions.DISABLE_USER_INSURANCE](state, { payload }) {
    return {
      ...state,
      userInsurance: [],
    };
  },
  [actions.GET_USER_INSURANCE](state) {
    return {
      ...state,
      userInsuranceLoading: true,
    };
  },
  [actions.GET_USER_INSURANCE_SUCCESS](state, { payload }) {
    return {
      ...state,
      userInsurance: payload,
      userInsuranceLoading: false,
    };
  },
  [actions.GET_USER_INSURANCE_FAIL](state) {
    return {
      ...state,
      userInsuranceLoading: false,
    };
  },
  [actions.UPDATE_USER_INSURANCE](state) {
    return {
      ...state,
      userInsuranceLoading: true,
    };
  },
  [actions.GET_BILLABLE_TIME](state) {
    return {
      ...state,
      billableTime: [],
      billableTimeLoading: true,
    };
  },
  [actions.GET_BILLABLE_TIME_SUCCESS](state, { payload }) {
    return {
      ...state,
      billableTime: payload,
      billableTimeLoading: false,
    };
  },
  [actions.GET_BILLABLE_TIME_FAIL](state) {
    return {
      ...state,
      billableTimeLoading: false,
    };
  },
  [actions.CREATE_OFF_PLATFORM_WORK](state) {
    return {
      ...state,
      offPlatformLoading: true,
      offPlatformSuccess: false,
    };
  },
  [actions.CREATE_OFF_PLATFORM_WORK_SUCCESS](state) {
    return {
      ...state,
      offPlatformLoading: false,
      offPlatformSuccess: true,
    };
  },
  [actions.CREATE_OFF_PLATFORM_WORK_FAIL](state) {
    return {
      ...state,
      offPlatformLoading: false,
      offPlatformSuccess: false,
    };
  },
  [actions.GET_OFF_PLATFORM_WORK](state) {
    return {
      ...state,
      offPlatformLoading: true,
    };
  },
  [actions.GET_OFF_PLATFORM_WORK_SUCCESS](state, { payload }) {
    return {
      ...state,
      offPlatformWork: payload,
      offPlatformLoading: false,
    };
  },
  [actions.GET_OFF_PLATFORM_WORK_FAIL](state) {
    return {
      ...state,
      offPlatformLoading: false,
    };
  },
  [actions.APPROVE_OFF_PLATFORM_WORK](state) {
    return {
      ...state,
      offPlatformLoading: true,
    };
  },
  [actions.DENY_OFF_PLATFORM_WORK](state) {
    return {
      ...state,
      offPlatformLoading: true,
    };
  },
  [actions.UPDATE_BILLABLE_TIME_INSURANCE_CODE](state) {
    return {
      ...state,
      updateBillableTimeInsuranceCodeLoading: true,
    };
  },
  [actions.UPDATE_BILLABLE_TIME_INSURANCE_CODE_SUCCESS](state) {
    return {
      ...state,
      updateBillableTimeInsuranceCodeLoading: false,
      updateBillableTimeInsuranceCodeSuccess: true,
    };
  },
  [actions.UPDATE_BILLABLE_TIME_INSURANCE_CODE_FAIL](state) {
    return {
      ...state,
      updateBillableTimeInsuranceCodeLoading: false,
      updateBillableTimeInsuranceCodeError: true,
    };
  },
  [actions.CLEAR_UPDATE_BILLABLE_TIME_INSURANCE_CODE_INFO](state) {
    return {
      ...state,
      updateBillableTimeInsuranceCodeLoading: false,
      updateBillableTimeInsuranceCodeSuccess: false,
      updateBillableTimeInsuranceCodeError: false,
    };
  },
  [actions.GET_ALL_AVAILABLE_CODES_SUCCESS](state, { payload }) {
    return {
      ...state,
      availableCptCodes: payload,
    };
  },
  [actions.GET_SUPERBILLS_BY_CLIENT](state) {
    return {
      ...state,
      superBillsLoading: true,
    };
  },
  [actions.GET_SUPERBILLS_BY_CLIENT_SUCCESS](state, { payload }) {
    return {
      ...state,
      superBillsLoading: false,
      superBills: payload.superBills,
      superBillsCount: payload.count,
    };
  },
  [actions.GET_SUPERBILLS_BY_CLIENT_FAIL](state) {
    return {
      ...state,
      superBillsLoading: false,
    };
  },
  [actions.SET_SUPERBILLS_PAGE](state, { payload }) {
    return {
      ...state,
      superBillsPage: payload,
      superBillsOffset: payload * 10,
    };
  },
  [actions.GET_REFERRING_PHYSICIANS_SUCCESS](state, { payload }) {
    return {
      ...state,
      referringPhysicians: payload,
    };
  },
  [actions.CREATE_REFERRING_PHYSICIAN](state) {
    return {
      ...state,
      createPhysicianLoading: true,
      createPhysicianId: null,
    };
  },
  [actions.CREATE_REFERRING_PHYSICIAN_SUCCESS](state, { payload }) {
    return {
      ...state,
      createPhysicianLoading: false,
      createPhysicianId: payload,
    };
  },
  [actions.CREATE_REFERRING_PHYSICIAN_FAIL](state) {
    return {
      ...state,
      createPhysicianLoading: false,
    };
  },
  [actions.GET_INSURANCE_PROVIDERS_LIST_SUCCESS](state, { payload }) {
    return {
      ...state,
      providersList: payload,
    };
  },
  [actions.GET_INSURANCE_PLAN](state) {
    return {
      ...state,
      insurancePlan: null,
      insuranceLoading: true,
      insuranceSuccess: false,
    };
  },
  [actions.GET_INSURANCE_PLAN_SUCCESS](state, { payload }) {
    return {
      ...state,
      insurancePlan: payload,
      insuranceLoading: false,
      insuranceSuccess: true,
    };
  },
  [actions.GET_INSURANCE_PLAN_FAIL](state) {
    return {
      ...state,
      insuranceLoading: false,
      insuranceSuccess: false,
    };
  },
  [actions.GET_INSURANCE_PROVIDER](state) {
    return {
      ...state,
      insuranceProvider: null,
      insuranceLoading: true,
      insuranceSuccess: false,
    };
  },
  [actions.GET_INSURANCE_PROVIDER_SUCCESS](state, { payload }) {
    return {
      ...state,
      insuranceProvider: payload,
      insuranceLoading: false,
      insuranceSuccess: true,
    };
  },
  [actions.GET_INSURANCE_PROVIDER_FAIL](state) {
    return {
      ...state,
      insuranceLoading: false,
      insuranceSuccess: false,
    };
  },
  [actions.GET_INSURANCE_PLANS](state) {
    return {
      ...state,
      plans: [],
      insuranceLoading: true,
    };
  },
  [actions.GET_INSURANCE_PLANS_SUCCESS](state, { payload }) {
    return {
      ...state,
      plans: payload.plans,
      plansCount: payload.count,
      insuranceLoading: false,
    };
  },
  [actions.CREATE_INSURANCE_PLAN](state) {
    return {
      ...state,
      createPlanLoading: true,
      createPlanSuccess: false,
    };
  },
  [actions.CREATE_INSURANCE_PLAN_SUCCESS](state) {
    return {
      ...state,
      createPlanLoading: false,
      createPlanSuccess: true,
    };
  },
  [actions.CREATE_INSURANCE_PLAN_FAIL](state) {
    return {
      ...state,
      createPlanLoading: false,
      createPlanError: false,
    };
  },
  [actions.UPDATE_INSURANCE_PLAN](state) {
    return {
      ...state,
      updatePlanLoading: true,
      createPlanSuccess: false,
    };
  },
  [actions.UPDATE_INSURANCE_PLAN_SUCCESS](state) {
    return {
      ...state,
      updatePlanLoading: false,
      updatePlanSuccess: true,
    };
  },
  [actions.UPDATE_INSURANCE_PLAN_FAIL](state) {
    return {
      ...state,
      updatePlanLoading: false,
      updatePlanError: true,
    };
  },
  [actions.CREATE_INSURANCE_PROVIDER](state) {
    return {
      ...state,
      createProviderLoading: true,
      createProviderSuccess: false,
    };
  },
  [actions.CREATE_INSURANCE_PROVIDER_SUCCESS](state) {
    return {
      ...state,
      createProviderLoading: false,
      createProviderSuccess: true,
    };
  },
  [actions.CREATE_INSURANCE_PROVIDER_FAIL](state) {
    return {
      ...state,
      createProviderLoading: false,
    };
  },
  [actions.UPDATE_INSURANCE_PROVIDER](state) {
    return {
      ...state,
      createProviderLoading: true,
      createProviderSuccess: false,
    };
  },
  [actions.UPDATE_INSURANCE_PROVIDER_SUCCESS](state) {
    return {
      ...state,
      createProviderLoading: false,
      createProviderSuccess: true,
    };
  },
  [actions.UPDATE_INSURANCE_PROVIDER_FAIL](state) {
    return {
      ...state,
      createProviderLoading: false,
      createProviderError: true,
    };
  },
  [actions.SET_INSURANCE_LIST_STATE](state, { payload }) {
    return {
      ...state,
      insuranceState: payload,
    };
  },
  [actions.SET_INSURANCE_LIST_PROVIDER_ID](state, { payload }) {
    return {
      ...state,
      insuranceProviderId: payload,
    };
  },
  [actions.CLEAR_INSURANCE_LIST_FILTERS](state) {
    return {
      ...state,
      plans: [],
      insurancePlan: null,
      providersList: [],
      insuranceProvider: null,
      insuranceState: "",
      insuranceProviderId: "",
      plansCount: 0,
      plansPage: 0,
      plansRowsPerPage: 25,
      plansSort: "",
      plansSortDirection: "asc",
    };
  },
  [actions.SET_INSURANCE_PLANS_PAGE](state, { payload }) {
    return {
      ...state,
      plansPage: payload,
    };
  },
  [actions.SET_INSURANCE_PLANS_ROWS_PER_PAGE](state, { payload }) {
    return {
      ...state,
      plansRowsPerPage: payload,
    };
  },
  [actions.SET_INSURANCE_PLANS_SORT](state, { payload }) {
    let sortDirection = state.plansSortDirection;
    if (state.plansSort && state.plansSort !== payload.sort) {
      sortDirection = "asc";
    } else if (state.plansSort) {
      sortDirection = sortDirection === "asc" ? "desc" : "asc";
    }
    return {
      ...state,
      plansSort: payload.sort,
      plansSortDirection: sortDirection,
    };
  },
};

export default createReducer(initialState, reducers);
