import React from "react";
import withStyles from "@mui/styles/withStyles";
import DetailSection from "elements/DetailSection";
import Typography from "@mui/material/Typography";
import Avatar from "elements/Avatar";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import DeleteIcon from "@mui/icons-material/Delete";
import { format } from "date-fns";
import Divider from "@mui/material/Divider";
import { styles } from "./styles";
import { convertToISO } from "utils/convertToISO";

const AssignedClinicians = (props) => {
  const {
    classes,
    clinician,
    loading,
    onClickChatWithClinician,
    editDisabled,
    chatId,
    history,
    hasScheduledCall,
    userPermissions,
    assignClinicianOpen,
    onOpenAssignClinician,
    onCloseAssignClinician,
    onOpenAssignClinicianConfirm,
    onOpenRemoveClinicianConfirm,
    assignedRole,
    assignableClinicians,
    assignableCliniciansLoading,
    secondaryClinician,
    assignBlockRef,
  } = props;

  const mapTimeSlotsToDisplay = (timeSlots) => {
    return (
      <div className={classes.availabilitySlotContainer}>
        {timeSlots.map((slot, i) => {
          return (
            <Typography key={i} component="p" style={{ fontSize: 14 }}>
              {`${format(convertToISO(slot.range_start), "p")} - 
                            ${format(convertToISO(slot.range_end), "p")}`}
            </Typography>
          );
        })}
      </div>
    );
  };

  const filteredAssignableClinicians = assignableClinicians?.filter((c) => {
    if (!!clinician || !!secondaryClinician) {
      return (
        c.clinician_id !== clinician?.clinician_id &&
        c.clinician_id !== secondaryClinician?.clinician_id
      );
    } else {
      return c;
    }
  });
  return (
    <DetailSection
      noPadding
      columnDirection
      title="Assigned Clinicians"
      loading={loading || assignableCliniciansLoading}
    >
      {!assignClinicianOpen ? (
        <div className={classes.container}>
          <div className={classes.clinicianInfoContainer}>
            {!!clinician ? (
              <div className={classes.clinicianInfo}>
                <div className={classes.info}>
                  <Avatar imageUrl={clinician.image_url} />
                  <div className={classes.clinicianNameInfo}>
                    <Typography
                      component="p"
                      className={classes.clinicianName}
                    >{`${clinician.first_name} ${clinician.last_name}`}</Typography>
                    <Typography component="p" className={classes.role}>
                      {clinician?.is_director
                        ? "Clinical Director"
                        : secondaryClinician?.is_supervisor
                          ? "Clinical Supervisor"
                          : "Clinician"}
                    </Typography>
                  </div>
                </div>
                <div className={classes.info}>
                  {userPermissions?.create_support_chat_with_clinician && (
                    <Button
                      variant="text"
                      style={{ marginLeft: 10, fontWeight: 500 }}
                      onClick={onClickChatWithClinician}
                      disabled={editDisabled}
                    >
                      Chat
                    </Button>
                  )}
                  {userPermissions?.view_chat_log_with_clinician && (
                    <Button
                      variant="text"
                      style={{ marginLeft: 10, fontWeight: 500 }}
                      onClick={() => history.push(`/chats/${chatId}`)}
                    >
                      View Chat
                    </Button>
                  )}
                  {userPermissions?.assign_clinician && (
                    <Button
                      variant="text"
                      style={{ fontWeight: 500, color: "#404040" }}
                      onClick={() => onOpenAssignClinician("primary")}
                      // PATCH::  Always allows primary clinician change
                      // disabled={editDisabled || hasScheduledCall}
                      disabled={editDisabled}
                    >
                      Reassign
                    </Button>
                  )}
                </div>
              </div>
            ) : (
              <div className={classes.clinicianInfo}>
                <Typography component="p" color="secondary" className={classes.unmatchedText}>
                  NONE SELECTED
                </Typography>
                {userPermissions?.assign_clinician && (
                  <Button
                    color="secondary"
                    onClick={() => onOpenAssignClinician("primary")}
                    disabled={editDisabled}
                  >
                    Assign
                  </Button>
                )}
              </div>
            )}
          </div>
          <Divider />
          <div className={classes.clinicianInfoContainer}>
            <Typography className={classes.title}>Supervisory Clinician</Typography>
            {!!secondaryClinician ? (
              <div className={classes.clinicianInfo}>
                <div className={classes.info}>
                  <Avatar imageUrl={secondaryClinician.image_url} />
                  <div className={classes.clinicianNameInfo}>
                    <Typography
                      component="p"
                      className={classes.clinicianName}
                    >{`${secondaryClinician.first_name} ${secondaryClinician.last_name}`}</Typography>
                    <Typography component="p" className={classes.role}>
                      {secondaryClinician?.is_director
                        ? "Clinical Director"
                        : secondaryClinician?.is_supervisor
                          ? "Clinical Supervisor"
                          : "Clinician"}
                    </Typography>
                  </div>
                </div>
                <div className={classes.info}>
                  {userPermissions?.assign_secondary_clinician && (
                    <Button
                      aria-label="delete"
                      variant="text"
                      color="secondary"
                      style={{ marginLeft: 10 }}
                      disabled={editDisabled}
                      onClick={() => onOpenRemoveClinicianConfirm(secondaryClinician)}
                    >
                      <DeleteIcon style={{ fill: "#DC2626" }} />
                    </Button>
                  )}
                  {userPermissions?.assign_secondary_clinician && (
                    <Button
                      variant="text"
                      style={{ fontWeight: 500, color: "#404040" }}
                      onClick={() => onOpenAssignClinician("secondary")}
                      disabled={editDisabled}
                    >
                      Reassign
                    </Button>
                  )}
                </div>
              </div>
            ) : (
              <div className={classes.clinicianInfo}>
                <Typography component="p" color="secondary" className={classes.unmatchedText}>
                  NONE SELECTED
                </Typography>
                {userPermissions?.assign_clinician && (
                  <Button
                    color="secondary"
                    onClick={() => onOpenAssignClinician("secondary")}
                    disabled={editDisabled}
                  >
                    Assign
                  </Button>
                )}
              </div>
            )}
          </div>
          {/* PATCH::  Always allows primary clinician change */}
          {/* {!!clinician && hasScheduledCall && userPermissions?.assign_clinician && (
            <Alert severity="error" className={classes.alertContainer}>
              The client has scheduled call/s. Please, cancel all calls before assigning a new
              primary clinician.
            </Alert>
          )} */}
        </div>
      ) : (
        <div className={classes.assignContainer} ref={assignBlockRef}>
          {filteredAssignableClinicians.length == 0 ? (
            <div className={classes.clinicianInfo}>
              <Typography component="p" color="secondary" className={classes.unmatchedText}>
                NO ASSIGNABLE CLINICIANS
              </Typography>
              <Button color="secondary" variant="outlined" onClick={onCloseAssignClinician}>
                Go Back
              </Button>
            </div>
          ) : (
            <>
              <div className={classes.info}>
                <Typography component="p" className={classes.subtitle} style={{ marginBottom: 5 }}>
                  {assignedRole === "primary"
                    ? "Select Primary Clinician"
                    : "Select Supervisory Clinician"}
                </Typography>
                <Button
                  onClick={onCloseAssignClinician}
                  color="secondary"
                  variant="text"
                  style={{ fontWeight: 500 }}
                >
                  Go Back
                </Button>
              </div>
              {filteredAssignableClinicians.map((c) => (
                <div className={classes.clinicianAvailabilityInfoContainer} key={c.clinician_id}>
                  <div className={classes.clinicianAvailabilityInfo}>
                    <Avatar imageUrl={c.image_url} />
                    <div className={classes.clinicianAvailabilityBasicInfo}>
                      <Typography component="p" className={classes.clinicianName}>
                        {c.name}
                        <span className={classes.availabilityRole}>
                          {"  -  "}
                          {c.is_director
                            ? "Clinical Director"
                            : c?.is_supervisor
                              ? "Clinical Supervisor"
                              : "Clinician"}
                        </span>
                      </Typography>
                      <div className={classes.clinicianInfo}>
                        <Button
                          onClick={() => onOpenAssignClinicianConfirm(c)}
                          variant="text"
                          style={{ fontWeight: 500 }}
                        >
                          Select
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      )}
    </DetailSection>
  );
};

export default withStyles(styles)(AssignedClinicians);
