import { call, put, takeEvery } from "redux-saga/effects";
import actions from "../actions";
import {
  startVideoCall,
  endVideoCall,
  getUpcomingVideo,
  getRoomInfo,
  getVideoIsRunning,
  updateRoomLayout,
  getAnimations,
  getActivities,
  getCards,
  sendVideoSignatures,
  getVideoCallInfo,
  scheduleVideoCall,
  cancelVideoCall,
  getVideoCallsList,
  getVideoChatInfo,
  rescheduleVideoCall,
  getVideoCallBreakdown,
  getUserVideoCalls,
  markClientNoShow,
  getNextClinicianCall,
  getClinicianCallsWeek,
  getClinicianCalendarCalls,
  getClinicianPastCalls,
  getClinicianUpcomingCalls,
  secondaryClinicianJoinCall,
  secondaryClinicianLeaveCall,
  getVideoRoomState,
  getCustomerUpcomingCalls,
  sendParentVideoSignatures,
  checkVideoCallAccess,
  getVideoCallsFuture,
  getVideoCallsPast,
  getVideoCallBillingIssues,
  setDemoCall,
  getUserCalendarSessions,
  getCalendarSessions,
  getClinicianIncompleteOffPlatformCalls,
  completeOffPlatformCall,
  sendSessionNoteForVideo,
  fetchMeetingConfig,
  fetchMessagingConfig,
} from "../api/video";
import { getParentInfo } from "../api/parent";
import { refreshCognitoToken } from "../api/auth";

function* fetchMeetingConfigAsync({ payload = ["", 0] }) {
  try {
    const refresh = yield call(refreshCognitoToken);
    if (!refresh) {
      return yield put(actions.signOut());
    }
    const res = yield call(fetchMeetingConfig, ...payload);
    yield put(actions.fetchMeetingConfigSuccess(res.data));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* fetchMessagingConfigAsync({ payload = ["", 0] }) {
  try {
    const refresh = yield call(refreshCognitoToken);
    if (!refresh) {
      return yield put(actions.signOut());
    }
    const res = yield call(fetchMessagingConfig, ...payload);
    yield put(actions.fetchMessagingConfigSuccess(res.data));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* startVideoCallAsync(videoDetails) {
  try {
    const videoId = yield call(startVideoCall, videoDetails.payload);
    yield put(actions.startVideoCallSuccess(videoId.data));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* endVideoCallAsync(videoId) {
  try {
    yield call(endVideoCall, videoId.payload);
    yield put(actions.endVideoCallSuccess());
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getNextClinicianCallAsync({ payload }) {
  try {
    const nextCall = yield call(getNextClinicianCall, payload);
    yield put(actions.getNextClinicianCallSuccess(nextCall.data));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.getNextClinicianCallFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getClinicianCallsWeekAsync({ payload }) {
  try {
    const upcoming = yield call(getClinicianCallsWeek, payload);
    yield put(actions.getClinicianCallsWeekSuccess(upcoming.data));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.getClinicianCallsWeekFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getUpcomingVideoAsync({ payload }) {
  try {
    const upcomingVideo = yield call(getUpcomingVideo, payload);
    let upcomingVideoData = upcomingVideo.data;
    for (let i = 0; i < upcomingVideoData.length; i++) {
      const roomInfo = yield call(getRoomInfo, upcomingVideoData[i].id);
      upcomingVideoData[i].info = roomInfo.data;
    }
    yield put(actions.getUpcomingVideoSuccess(upcomingVideoData));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getVideoIsRunningAsync(roomId) {
  try {
    const isRunning = yield call(getVideoIsRunning, roomId.payload);
    yield put(actions.getVideoIsRunningSuccess(isRunning.data));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* updateVideoRoomLayoutAsync(updatedState) {
  try {
    yield call(updateRoomLayout, updatedState.payload);
    yield put(actions.updateVideoRoomLayoutSuccess());
  } catch (e) {
    yield put(actions.updateVideoRoomLayoutFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getAnimationsAsync() {
  try {
    const animations = yield call(getAnimations);
    yield put(actions.getAnimationsSuccess(animations.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getActivitiesAsync() {
  try {
    const activities = yield call(getActivities);
    yield put(actions.getActivitiesSuccess(activities.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getCardsAsync() {
  try {
    const cards = yield call(getCards);
    yield put(actions.getCardsActivitySuccess(cards.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* sendVideoSignaturesAsync(signatures) {
  try {
    yield call(sendVideoSignatures, signatures.payload);
    yield put(actions.getUser());
    yield put(actions.sendVideoSignaturesSuccess());
  } catch (e) {
    yield put(actions.sendVideoSignaturesFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getVideoCallInfoAsync(videoId) {
  try {
    const videoInfo = yield call(getVideoCallInfo, videoId.payload);
    yield put(actions.getVideoCallInfoSuccess(videoInfo.data));
  } catch (e) {
    yield put(actions.getVideoCallInfoFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* scheduleVideoCallAsync(videoDetails) {
  try {
    const videoInfo = yield call(scheduleVideoCall, videoDetails.payload);
    yield put(
      actions.scheduleVideoCallSuccess({
        ...videoInfo.data,
        ...videoDetails.payload,
      })
    );
  } catch (e) {
    yield put(actions.scheduleVideoCallFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
  }
}

function* rescheduleVideoCallAsync({ payload }) {
  try {
    const videoInfo = yield call(rescheduleVideoCall, payload);
    yield put(actions.rescheduleVideoCallSuccess(videoInfo.data));
  } catch (e) {
    yield put(actions.rescheduleVideoCallFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
  }
}

function* cancelVideoCallAsync({ payload }) {
  try {
    yield call(cancelVideoCall, payload);
    yield put(actions.cancelVideoCallSuccess());
    if (payload.isAdmin) {
      yield put(actions.getCalendarSessions({}));
    } else {
      if (payload.clinicianSessionsPageAction) {
        yield put(
          actions.getUserCalendarSessions({
            clinicianUserId: payload.clinicianUserId,
          })
        );
      } else if (payload.clientId) {
        yield put(actions.getCustomerUpcomingCalls(payload.clientId));
      } else {
        yield put(actions.getNextClinicianCall(payload.clinicianUserId));
        yield put(actions.getClinicianCallsWeek(payload.clinicianUserId));
        const upcomingCalls = yield call(getClinicianUpcomingCalls);
        yield put(actions.getClinicianUpcomingCallsSuccess(upcomingCalls.data));
      }
    }
  } catch (e) {
    yield put(actions.completeOffPlatformCallFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getVideoCallsListAsync() {
  try {
    const videoCalls = yield call(getVideoCallsList);
    yield put(
      actions.setVideoCallsList(
        videoCalls.data,
        videoCalls.data.length > 0 ? parseInt(videoCalls.data[0].count) : 0
      )
    );
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getVideoCallsFutureAsync() {
  try {
    const videoCalls = yield call(getVideoCallsFuture);
    yield put(
      actions.setVideoCallsList(
        videoCalls.data,
        videoCalls.data.length > 0 ? parseInt(videoCalls.data[0].count) : 0
      )
    );
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getVideoCallsPastAsync() {
  try {
    const videoCalls = yield call(getVideoCallsPast);
    yield put(
      actions.setVideoCallsList(
        videoCalls.data,
        videoCalls.data.length > 0 ? parseInt(videoCalls.data[0].count) : 0
      )
    );
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getUserVideoCallsAsync({ payload }) {
  try {
    const videoCalls = yield call(getUserVideoCalls, payload);
    yield put(
      actions.getUserVideoCallsSuccess(
        videoCalls.data,
        videoCalls.data.length > 0 ? parseInt(videoCalls.data[0].count) : 0
      )
    );
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getVideoChatInfoAsync({ payload }) {
  try {
    const videoInfo = yield call(getVideoChatInfo, payload);
    yield put(actions.getVideoChatInfoSuccess(videoInfo.data));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* setOneTimeVideoInfoAsync({ payload }) {
  try {
    let videoId = payload.videoInfo.videoId ? payload.videoInfo.videoId : payload.videoInfo.id;
    const roomInfo = yield call(getRoomInfo, videoId);
    yield put(actions.setOneTimeVideoInfoSuccess(roomInfo.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getVideoCallBreakdownAsync({ payload }) {
  try {
    const breakdown = yield call(getVideoCallBreakdown, payload);
    yield put(actions.getVideoCallBreakdownSuccess(breakdown.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* sendBillableTimeForVideoAsync({ payload }) {
  try {
    const idList = yield call(sendSessionNoteForVideo, payload);
    yield put(actions.sendBillableTimeForVideoSuccess(idList.data));
  } catch (e) {
    let message = "An error has occurred. Please try again.";
    if (e.response && e.response.data && e.response.data.error) {
      message = e.response.data.error;
    }
    yield put(actions.sendBillableTimeForVideoFail(message));
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* markClientNoShowAsync({ payload }) {
  try {
    yield call(markClientNoShow, payload.id);
    yield put(actions.getVideoChatInfo(payload));
    yield put(actions.completeOffPlatformCallSuccess());
  } catch (e) {
    yield put(actions.completeOffPlatformCallFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getClinicianCalendarCallsAsync({ payload }) {
  try {
    const calendarCalls = yield call(getClinicianCalendarCalls, payload);
    yield put(actions.getClinicianCalendarCallsSuccess(calendarCalls.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getClinicianPastCallsAsync() {
  try {
    const pastCalls = yield call(getClinicianPastCalls);
    yield put(actions.getClinicianPastCallsSuccess(pastCalls.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    console.error(e);
  }
}

function* getClinicianUpcomingCallsAsync() {
  try {
    const upcomingCalls = yield call(getClinicianUpcomingCalls);
    yield put(actions.getClinicianUpcomingCallsSuccess(upcomingCalls.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    console.error(e);
  }
}

function* secondaryClinicianJoinCallAsync(videoDetails) {
  try {
    const secondaryClinicianDetails = yield call(secondaryClinicianJoinCall, videoDetails.payload);
    yield put(actions.secondaryClinicianJoinCallSuccess(secondaryClinicianDetails.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    console.error(e);
  }
}

function* secondaryClinicianLeaveCallAsync(videoDetails) {
  try {
    const secondaryClinicianDetails = yield call(secondaryClinicianLeaveCall, videoDetails.payload);
    yield put(actions.secondaryClinicianLeaveCallSuccess(secondaryClinicianDetails.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    console.error(e);
  }
}

function* getVideoRoomStateAsync(videoRoomId) {
  try {
    const videoRoomState = yield call(getVideoRoomState, videoRoomId.payload);
    yield put(actions.getVideoRoomStateSuccess(videoRoomState.data));
  } catch (e) {
    yield put(actions.getVideoRoomStateFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getCustomerUpcomingCallsAsync({ payload }) {
  try {
    const videoInfo = yield call(getCustomerUpcomingCalls, payload);
    yield put(actions.getCustomerUpcomingCallsSuccess(videoInfo.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}
function* sendParentVideoSignaturesAsync(signatures) {
  try {
    yield call(sendParentVideoSignatures, signatures.payload);
    let result = yield call(getParentInfo);
    yield put(actions.setParentPendingSignatures(result.data.pendingSignature));
    yield put(actions.sendParentVideoSignaturesSuccess());
  } catch (e) {
    yield put(actions.sendParentVideoSignaturesFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* checkVideoCallAccessAsync({ payload }) {
  try {
    const access = yield call(checkVideoCallAccess, payload);
    yield put(actions.checkVideoCallAccessSuccess(access.data));
  } catch (e) {
    yield put(actions.checkVideoCallAccessFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getVideoCallBillingIssuesAsync({ payload }) {
  try {
    const videoCalls = yield call(getVideoCallBillingIssues, payload);
    yield put(actions.getVideoCallBillingIssuesSuccess(videoCalls.data));
  } catch (e) {
    yield put(actions.getVideoCallBillingIssuesFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* setDemoCallAsync(videoDetails) {
  try {
    const videoInfo = yield call(setDemoCall, videoDetails.payload);
    yield put(
      actions.setDemoCallSuccess({
        ...videoInfo.data,
        ...videoDetails.payload,
      })
    );
  } catch (e) {
    yield put(actions.setDemoCallFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
  }
}

function* getUserCalendarSessionsAsync({ payload }) {
  try {
    const calendarSessions = yield call(getUserCalendarSessions, payload);
    yield put(actions.getUserCalendarSessionsSuccess(calendarSessions.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getCalendarSessionsAsync({ payload }) {
  try {
    const videoCalls = yield call(getCalendarSessions, payload);
    yield put(actions.setCalendarSessionsList(videoCalls.data, 0));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getClinicianIncompleteOffPlatformCallsAsync({ payload }) {
  try {
    const result = yield call(getClinicianIncompleteOffPlatformCalls, payload);
    yield put(actions.getClinicianIncompleteOffPlatformCallsSuccess(result.data));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* completeOffPlatformCallAsync({ payload }) {
  try {
    const result = yield call(completeOffPlatformCall, payload);
    yield put(actions.completeOffPlatformCallSuccess(result.data));
  } catch (e) {
    yield put(actions.completeOffPlatformCallFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

export default function* authSaga() {
  yield takeEvery(actions.FETCH_MEETING_CONFIG, fetchMeetingConfigAsync);
  yield takeEvery(actions.FETCH_MESSAGING_CONFIG, fetchMessagingConfigAsync);
  yield takeEvery(actions.START_VIDEO_CALL, startVideoCallAsync);
  yield takeEvery(actions.END_VIDEO_CALL, endVideoCallAsync);
  yield takeEvery(actions.GET_NEXT_CLINICIAN_CALL, getNextClinicianCallAsync);
  yield takeEvery(actions.GET_CLINICIAN_CALLS_WEEK, getClinicianCallsWeekAsync);
  yield takeEvery(actions.GET_UPCOMING_VIDEO, getUpcomingVideoAsync);
  yield takeEvery(actions.GET_VIDEO_IS_RUNNING, getVideoIsRunningAsync);
  yield takeEvery(actions.UPDATE_ROOM_LAYOUT, updateVideoRoomLayoutAsync);
  yield takeEvery(actions.GET_ANIMATIONS, getAnimationsAsync);
  yield takeEvery(actions.GET_ACTIVITIES, getActivitiesAsync);
  yield takeEvery(actions.GET_CARDS_ACTIVITY, getCardsAsync);
  yield takeEvery(actions.SEND_VIDEO_SIGNATURES, sendVideoSignaturesAsync);
  yield takeEvery(actions.GET_VIDEO_CALL_INFO, getVideoCallInfoAsync);
  yield takeEvery(actions.SCHEDULE_VIDEO_CALL, scheduleVideoCallAsync);
  yield takeEvery(actions.RESCHEDULE_VIDEO_CALL, rescheduleVideoCallAsync);
  yield takeEvery(actions.CANCEL_VIDEO_CALL, cancelVideoCallAsync);
  yield takeEvery(actions.GET_CALENDAR_SESSIONS, getCalendarSessionsAsync);
  yield takeEvery(actions.GET_VIDEO_CALLS_LIST, getVideoCallsListAsync);
  yield takeEvery(actions.GET_VIDEO_CALLS_FUTURE, getVideoCallsFutureAsync);
  yield takeEvery(actions.GET_VIDEO_CALLS_PAST, getVideoCallsPastAsync);
  yield takeEvery(actions.GET_USER_VIDEO_CALLS, getUserVideoCallsAsync);
  yield takeEvery(actions.GET_VIDEO_CHAT_INFO, getVideoChatInfoAsync);
  yield takeEvery(actions.SET_ONE_TIME_VIDEO_INFO, setOneTimeVideoInfoAsync);
  yield takeEvery(actions.GET_VIDEO_CALL_BREAKDOWN, getVideoCallBreakdownAsync);
  yield takeEvery(actions.SEND_BILLABLE_TIME_FOR_VIDEO, sendBillableTimeForVideoAsync);
  yield takeEvery(actions.MARK_CLIENT_NO_SHOW, markClientNoShowAsync);
  yield takeEvery(actions.GET_CLINICIAN_CALENDAR_CALLS, getClinicianCalendarCallsAsync);
  yield takeEvery(actions.GET_CLINICIAN_PAST_CALLS, getClinicianPastCallsAsync);
  yield takeEvery(actions.GET_CLINICIAN_UPCOMING_CALLS, getClinicianUpcomingCallsAsync);
  yield takeEvery(actions.SECONDARY_CLINICIAN_JOIN_CALL, secondaryClinicianJoinCallAsync);
  yield takeEvery(actions.SECONDARY_CLINICIAN_LEAVE_CALL, secondaryClinicianLeaveCallAsync);
  yield takeEvery(actions.SET_DEMO_CALL, setDemoCallAsync);
  yield takeEvery(actions.GET_CLINICIAN_WEEKLY_CALENDAR_SESSIONS, getUserCalendarSessionsAsync);
  //parent
  yield takeEvery(actions.GET_VIDEO_ROOM_STATE, getVideoRoomStateAsync);
  yield takeEvery(actions.GET_CUSTOMER_UPCOMING_CALLS, getCustomerUpcomingCallsAsync);
  yield takeEvery(actions.SEND_PARENT_VIDEO_SIGNATURES, sendParentVideoSignaturesAsync);
  yield takeEvery(actions.CHECK_VIDEO_CALL_ACCESS, checkVideoCallAccessAsync);
  yield takeEvery(actions.GET_VIDEO_CALL_BILLING_ISSUES, getVideoCallBillingIssuesAsync);
  yield takeEvery(
    actions.GET_CLINICIAN_INCOMPLETE_OFF_PLATFORM_CALLS,
    getClinicianIncompleteOffPlatformCallsAsync
  );
  yield takeEvery(actions.COMPLETE_OFF_PLATFORM_CALL, completeOffPlatformCallAsync);
}
