import React from "react";
import {
  HouseLine,
  User,
  Users,
  UsersThree,
  FirstAidKit,
  IdentificationBadge,
  Calendar,
  ClipboardText,
  ChatText,
  BookOpenText,
  Wrench,
  ClockClockwise,
} from "@phosphor-icons/react";

export const NAVIGATION_PERMISSIONS = {
  adminHome: {
    permission: "view_admin_dashboard",
    url: "/dashboard",
    title: "Home",
    icon: <HouseLine size={20} color={"#D2B3EA"} />,
    iconSelected: <HouseLine size={20} color={"#FFFFFF"} />,
  },
  clinicianHome: {
    permission: "view_clinician_dashboard",
    url: "/dashboard",
    title: "Home",
    icon: <HouseLine size={20} color={"#D2B3EA"} />,
    iconSelected: <HouseLine size={20} color={"#FFFFFF"} />,
  },
  clientHome: {
    permission: "view_client_dashboard",
    url: "/dashboard",
    title: "Home",
    icon: <HouseLine size={20} color={"#D2B3EA"} />,
    iconSelected: <HouseLine size={20} color={"#FFFFFF"} />,
  },
  myClients: {
    permission: "view_my_clients",
    url: "/my-clients",
    title: "My Clients",
    icon: <User size={20} color={"#D2B3EA"} />,
    iconSelected: <User size={20} color={"#FFFFFF"} />,
  },
  clients: {
    permission: "view_client_list_by_workflow_state",
    url: "/clients",
    title: "Clients",
    icon: <Users size={20} color={"#D2B3EA"} />,
    iconSelected: <Users size={20} color={"#FFFFFF"} />,
  },
  clinicians: {
    permission: "view_clinician_list",
    url: "/clinicians",
    title: "Clinicians",
    icon: <IdentificationBadge size={20} color={"#D2B3EA"} />,
    iconSelected: <IdentificationBadge size={20} color={"#FFFFFF"} />,
  },
  sessions: {
    permission: "view_client_dashboard",
    url: "/sessions",
    title: "Sessions",
    icon: <Calendar size={20} color={"#D2B3EA"} />,
    iconSelected: <Calendar size={20} color={"#FFFFFF"} />,
  },
  schedule: {
    permission: "view_schedule_page",
    url: "/clinician-schedule",
    title: "Schedule",
    icon: <Calendar size={20} color={"#D2B3EA"} />,
    iconSelected: <Calendar size={20} color={"#FFFFFF"} />,
  },
  // videoCalls: {
  //   permission: "view_entities",
  //   url: "/upcoming-video-calls",
  //   title: "Upcoming Video Calls",
  //   icon: <ClockClockwise size={20} color={"#D2B3EA"} />,
  //   iconSelected: <ClockClockwise size={20} color={"#FFFFFF"} />,
  // },
  billing: {
    permission: "view_billing_items",
    url: "/billing",
    title: "Billing",
    icon: <ClipboardText size={20} color={"#D2B3EA"} />,
    iconSelected: <ClipboardText size={20} color={"#FFFFFF"} />,
  },
  chat: {
    permission: "chat",
    url: "/chat",
    title: "Chat",
    icon: <ChatText size={20} color={"#D2B3EA"} />,
    iconSelected: <ChatText size={20} color={"#FFFFFF"} />,
  },
  libraries: {
    permission: "view_libraries",
    url: "/libraries",
    title: "Libraries",
    icon: <BookOpenText size={20} color={"#D2B3EA"} />,
    iconSelected: <BookOpenText size={20} color={"#FFFFFF"} />,
  },
  admin: {
    permission: "invite_admin_users",
    title: "Admin",
    icon: <Wrench size={20} color={"#D2B3EA"} />,
    iconSelected: <Wrench size={20} color={"#FFFFFF"} />,
    pages: [
      {
        permission: "invite_admin_users",
        url: "/users",
        title: "User Management",
        icon: <UsersThree size={20} color={"#D2B3EA"} />,
      },
    ],
  },
};
