import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStyles from "@mui/styles/withStyles";
import styles from "./styles";
import actions from "../../actions";
import { addMinutes } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import NextSessionBlock from "elements/NextSessionBlock";
import WeeklySchedule from "./WeeklySchedule";
import ManageCallDialog from "./ManageCallDialog";
import PageContainer from "elements/PageContainer";
import {
  getNextClinicianCall,
  getNextClinicianCallLoading,
  getUserId,
  getUserClinicianId,
  getUser,
  getClinicianCallsForWeek,
  getClinicianCallsForWeekLoading,
  getClinicianCallsForWeekSuccess,
  getClinicianWorkflowStatus,
  getClinicianDashboard,
  getClinicianDashboardLoading,
  getPendingSignatures,
  getUserPermissionsList,
  getVideoBillingIssues,
  getVideoBreakdownLoading,
  getVideoBreakdown,
  getNotesSaved,
  rescheduleVideoDetails,
  setDemoCallLoading,
  setDemoCallSuccess,
  getClinicianClientStatusInfo,
  getClinicianClientStatusInfoLoading,
  scheduleCallLoading,
  scheduleCallSuccess,
  getInsuranceCodesByUser,
  getCustomerDetails,
  getCustomerTreatmentPlansLoading,
  getTreatmentPlanLoading,
  getTreatmentPlanSuccess,
  getUpdateUserInsuranceStatusLoading,
  getUpdateUserInsuranceStatusSuccess,
  getUpdateUserInsuranceStatusError,
  getClinicianIncompleteOffPlatform,
  getCompletingOffPlatform,
  getCompleteOffPlatformSuccess,
  getClinicianNotifications,
  getClinicianNotificationsSuccess,
  getClinicianNotificationsLoading,
  getClinicianCallsNeverStarted,
  getClinicianCalendarSynced,
  getClinicianCalendarSyncLoading,
  getClinicianCalendarSyncSuccess,
} from "selectors";
import Heading from "elements/Heading";
import VideoBreakdownDialog from "../SessionNotes";
import StartDemoCallConfirmDialog from "../ClinicianSessions/StartDemoCallConfirmDialog";
import ClinicianQuickActions from "./ClinicianQuickActions";
import MarkAssessmentCompleteDialog from "../ClientDetails/Dialogs/MarkAssessmentCompleteDialog";
import { DropzoneDialogBase } from "mui-file-dropzone";
import CancelSessionDialog from "../WeeklyCalendarSessions/CancelSessionDialog";
import EditSelectionDialog from "../WeeklyCalendarSessions/EditSelectionDialog";
import IncompleteOffPlatformCalls from "./IncompleteOffPlatformCalls";
import CompleteOffPlatformDialog from "../CompleteOffPlatformDialog";
import Notifications from "./Notifications";
import CallNotStartedDialog from "./CallNotStartedDialog";
import CalendarSync from "components/CalendarSync";
import { IconButton, Snackbar, Button } from "@mui/material";
import { X } from "@phosphor-icons/react";
import { checkFeatureAccess } from "api/featureAccess";
import {
  getCancellationReasonLabel,
  getKeyCancellationReason,
} from "../../constants/cancelReasons";
import { roundDownToNearest15 } from "components/EventScheduling/EventSchedulingUtils";
import { getAWSCredentialsForCurrentUserSession } from "utils/aws";

const mapStateToProps = (state) => ({
  clinicianUserId: getUserId(state),
  clinicianId: getUserClinicianId(state),
  nextCall: getNextClinicianCall(state),
  nextCallLoading: getNextClinicianCallLoading(state),
  clinician: getUser(state),
  weeklySchedule: getClinicianCallsForWeek(state),
  weeklyScheduleLoading: getClinicianCallsForWeekLoading(state),
  weeklyScheduleSuccess: getClinicianCallsForWeekSuccess(state),
  clinicianWorkflowStatus: getClinicianWorkflowStatus(state),
  onboardingDashboard: getClinicianDashboard(state),
  dashboardLoading: getClinicianDashboardLoading(state),
  pendingSignatures: getPendingSignatures(state),
  userPermissions: getUserPermissionsList(state),
  videoBillingIssues: getVideoBillingIssues(state),
  videoBreakdown: getVideoBreakdown(state),
  videoBreakdownLoading: getVideoBreakdownLoading(state),
  sessionNotesSaved: getNotesSaved(state),
  rescheduleVideoDetails: rescheduleVideoDetails(state),
  setDemoCallLoading: setDemoCallLoading(state),
  setDemoCallSuccess: setDemoCallSuccess(state),
  clientStatusInfo: getClinicianClientStatusInfo(state),
  clientStatusInfoLoading: getClinicianClientStatusInfoLoading(state),
  scheduleCallLoading: scheduleCallLoading(state),
  scheduleCallSuccess: scheduleCallSuccess(state),
  insuranceCodes: getInsuranceCodesByUser(state),
  customerDetails: getCustomerDetails(state),
  treatmentPlansLoading: getCustomerTreatmentPlansLoading(state),
  treatmentPlanLoading: getTreatmentPlanLoading(state),
  treatmentPlanSuccess: getTreatmentPlanSuccess(state),
  updateUserInsuranceStatusLoading: getUpdateUserInsuranceStatusLoading(state),
  updateUserInsuranceStatusSuccess: getUpdateUserInsuranceStatusSuccess(state),
  updateUserInsuranceStatusError: getUpdateUserInsuranceStatusError(state),
  incompleteOffPlatform: getClinicianIncompleteOffPlatform(state),
  completingOffPlatform: getCompletingOffPlatform(state),
  completeOffPlatformSuccess: getCompleteOffPlatformSuccess(state),
  notifications: getClinicianNotifications(state),
  notificationsSuccess: getClinicianNotificationsSuccess(state),
  notificationsLoading: getClinicianNotificationsLoading(state),
  callsNeverStarted: getClinicianCallsNeverStarted(state),
  calendarSynced: getClinicianCalendarSynced(state),
  calendarSyncLoading: getClinicianCalendarSyncLoading(state),
  calendarSyncSuccess: getClinicianCalendarSyncSuccess(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPageDetails: actions.setPageDetails,
      getNextClinicianCall: actions.getNextClinicianCall,
      setOneTimeVideoInfo: actions.setOneTimeVideoInfo,
      getClinicianCallsForWeek: actions.getClinicianCallsWeek,
      cancelVideoCall: actions.cancelVideoCall,
      getClinicianDashboard: actions.getClinicianDashboard,
      getVideoCallBillingIssues: actions.getVideoCallBillingIssues,
      getVideoCallBreakdown: actions.getVideoCallBreakdown,
      setVideoCallsType: actions.setVideoCallsType,
      setDemoCall: actions.setDemoCall,
      clearVideoInfo: actions.clearVideoInfo,
      getClinicianClientStatus: actions.getClinicianClientStatus,
      updateUserInsuranceStatus: actions.updateUserInsuranceStatus,
      clearUpdateUserInsuranceStatus: actions.clearUpdateUserInsuranceStatus,
      uploadTreatmentPlan: actions.uploadTreatmentPlan,
      updateTreatmentPlan: actions.updateTreatmentPlan,
      getInsuranceCodesByUser: actions.getInsuranceCodesByUser,
      getCustomerInfo: actions.getCustomerInfo,
      clearCustomerInfo: actions.clearCustomerInfo,
      getCustomerTreatmentPlans: actions.getCustomerTreatmentPlans,
      getIncompleteOffPlatform: actions.getClinicianIncompleteOffPlatformCalls,
      dismissClinicianNotification: actions.dismissClinicianNotification,
      getClinicianNotifications: actions.getClinicianNotifications,
      getClinicianCallsNeverStarted: actions.getClinicianCallsNeverStarted,
      markClientNoShow: actions.markClientNoShow,
      setClinicianCalendarSynced: actions.setSuccessfulCalendarSync,
    },
    dispatch
  );

class ClinicianDashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      manageCallOpen: false,
      manageCall: null,
      rescheduleCallOpen: false,
      editSelectionOpen: false,
      editAllInstances: false,
      cancelSessionOpen: false,
      cancelSelectionOpen: false,
      cancelAllInstances: false,
      cancelReasonText: "",
      responsibleForCancellation: "",
      cptCodeDialogOpen: false,
      cptCodeVideoCall: null,
      currentBreakdown: [],
      enterInsuranceCode: false,
      sessionNotesDialogOpen: false,
      startDemoOpen: false,
      scheduleCallOpen: false,
      selectedClient: {},
      selectedClientUserId: null,
      selectedClientId: null,
      assessmentCompleteOpen: false,
      treatmentPlanDialogOpen: false,
      treatmentPlan: [],
      treatmentPlanTimeLog: [],
      treatmentDropzoneOpen: false,
      updateTreatmentPlan: null,
      updateTreatmentDropzoneOpen: false,
      treatmentPlanError: false,
      treatmentPlanUploading: false,
      sessionNotesOpen: false,
      fileObjects: [],
      showAssessmentCompleteNotification: false,
      startCallOpen: false,
      startCallDetails: {},
      completeOffPlatformOpen: false,
      offPlatformDetails: null,
      callNotStartedDialogOpen: false,
      callNotStartedDetails: {},
      callNotStartedReason: "",
      callNotEndedDialogOpen: false,
      calendarSyncOpen: false,
      calendarSyncToastOpen: false,
      calendarSyncToastMessage: "",
      onSchedFeatureFlag: false,
    };
  }
  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Home",
      currentPage: "dashboard",
      menu: "clinicianHome",
    });
    if (this.props.clinicianUserId) {
      this.props.setVideoCallsType("past");
      this.props.getClinicianCallsForWeek(this.props.clinicianUserId);
      // this.props.getVideoCallBillingIssues(this.props.clinicianUserId);
      this.props.getIncompleteOffPlatform(this.props.clinicianUserId);
      checkFeatureAccess(this.props.clinicianUserId, "onsched_setup").then((includeFeature) =>
        this.setState({ onSchedFeatureFlag: includeFeature })
      );
    }
    if (this.props.clinicianId) {
      this.props.getClinicianClientStatus(this.props.clinicianId);
      this.props.getClinicianNotifications(this.props.clinicianId);
      this.props.getClinicianCallsNeverStarted(this.props.clinicianId);
      const params = new URLSearchParams(this.props.location.search);
      if (params.get("calendarSyncRedirect")) {
        this.props.setClinicianCalendarSynced(this.props.clinicianId);
      } else if (this.props.clinicianWorkflowStatus && !this.props.calendarSynced) {
        this.openCalendarSyncDialog();
      }
    }
    window.scrollTo(0, 0);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevProps.clinicianUserId || prevProps.pendingSignatures.length > 0) &&
      this.props.clinicianUserId &&
      this.props.pendingSignatures.length === 0
    ) {
      this.props.setVideoCallsType("past");
      this.props.getClinicianCallsForWeek(this.props.clinicianUserId);
      // this.props.getVideoCallBillingIssues(this.props.clinicianUserId);
      this.props.getIncompleteOffPlatform(this.props.clinicianUserId);
    }
    if (!prevProps.clinicianUserId && this.props.clinicianUserId) {
      checkFeatureAccess(this.props.clinicianUserId, "onsched_setup").then((includeFeature) =>
        this.setState({ onSchedFeatureFlag: includeFeature })
      );
    }
    if (prevProps.completingOffPlatform && !this.props.completingOffPlatform) {
      this.props.getIncompleteOffPlatform(this.props.clinicianUserId);
    }
    if (!prevProps.clinicianId && this.props.clinicianId) {
      this.props.getClinicianClientStatus(this.props.clinicianId);
      this.props.getClinicianNotifications(this.props.clinicianId);
      this.props.getClinicianCallsNeverStarted(this.props.clinicianId);
      const params = new URLSearchParams(this.props.location.search);
      if (params.get("calendarSyncRedirect")) {
        this.props.setClinicianCalendarSynced(this.props.clinicianId);
      }
    }
    // if (!prevProps.sessionNotesSaved && this.props.sessionNotesSaved) {
    //     this.props.getVideoCallBillingIssues(this.props.clinicianUserId);
    // }

    if (
      prevProps.setDemoCallLoading &&
      !this.props.setDemoCallLoading &&
      this.props.setDemoCallSuccess
    ) {
      let roomId = uuidv4();
      const details = {
        videoInfo: this.props.rescheduleVideoDetails,
        roomId,
        videoKey: this.props.rescheduleVideoDetails.videoKey,
      };
      this.props.setOneTimeVideoInfo(details);
      this.toggleStartDemoCallDialog();
      this.props.history.push(`/video/${details.videoKey}`);
    }

    if (
      prevState.scheduleCallOpen &&
      !this.state.scheduleCallOpen &&
      this.props.scheduleCallSuccess
    ) {
      if (this.state.selectedClient.workflow_status == "INSURANCE_APPROVED") {
        this.handleInsuranceApprovedScheduleCall();
      } else {
        this.props.setVideoCallsType("past");
        this.props.getClinicianClientStatus(this.props.clinicianId);
      }
    }

    if (
      prevProps.updateUserInsuranceStatusLoading &&
      !this.props.updateUserInsuranceStatusLoading &&
      this.props.updateUserInsuranceStatusSuccess
    ) {
      this.props.setVideoCallsType("past");
      this.props.getClinicianClientStatus(this.props.clinicianId);
    }

    if (prevState.treatmentPlanDialogOpen && !this.state.treatmentPlanDialogOpen) {
      this.props.getClinicianClientStatus(this.props.clinicianId);
      if (this.state.updateTreatmentPlan) {
        this.setState({ updateTreatmentPlan: null });
      }
      this.setState({ treatmentPlanTimeLog: [] });
    }

    if (prevProps.treatmentPlanLoading && !this.props.treatmentPlanLoading) {
      if (this.props.treatmentPlanSuccess) {
        this.props.getClinicianClientStatus(this.props.clinicianId);
      } else {
        this.setState({ treatmentPlanError: true });
      }
      this.setState({ treatmentPlanUploading: false });
    }
    if (
      prevProps.treatmentPlansLoading &&
      !this.props.treatmentPlansLoading &&
      this.props.customerDetails.treatmentPlans
    ) {
      this.setState({
        updateTreatmentPlan: this.props.customerDetails.treatmentPlans.find(
          (plan) => !plan.approved
        ),
      });
    }

    if (prevProps.clientStatusInfoLoading && !this.props.clientStatusInfoLoading) {
      if (
        this.props.updateUserInsuranceStatusSuccess &&
        !this.props.updateUserInsuranceStatusLoading
      ) {
        if (
          this.state.selectedClient.workflow_status == "ASSESSMENT_SCHEDULED" ||
          this.state.selectedClient.workflow_status == "ASSESSMENT_SCHEDULED_NOW"
        ) {
          this.setState({ showAssessmentCompleteNotification: true });
        }
      }
    }

    if (
      prevProps.callsNeverStarted.length !== this.props.callsNeverStarted.length &&
      this.props.callsNeverStarted
    ) {
      this.setState({
        callNotStartedDialogOpen: true,
        callNotStartedDetails: this.props.callsNeverStarted[0],
        callNotStartedReason: "",
      });
    }

    if (
      (prevState.callNotStartedDialogOpen || prevState.cancelSessionOpen) &&
      !this.state.callNotStartedDialogOpen &&
      !this.state.cancelSessionOpen
    ) {
      this.props.getClinicianCallsNeverStarted(this.props.clinicianId);
    }

    if (
      prevProps.completingOffPlatform &&
      !this.props.completingOffPlatform &&
      this.state.callNotStartedDialogOpen
    ) {
      this.setState({
        callNotStartedDialogOpen: false,
        callNotStartedDetails: {},
        callNotStartedReason: "",
      });
    }
    if (
      prevProps.notifications.length !== this.props.notifications.length &&
      this.props.notifications.length > 0 &&
      this.props.notifications.some((n) => n.type === "CALL_NOT_ENDED")
    ) {
      this.onOpenCallNotEndedDialog();
    }

    if (
      !prevProps.clinicianWorkflowStatus &&
      this.props.clinicianWorkflowStatus &&
      !this.props.calendarSynced
    ) {
      this.openCalendarSyncDialog();
    }

    if (prevProps.calendarSyncLoading && !this.props.calendarSyncLoading) {
      let toastMessage = "Calendar Sync Successful";
      if (!this.props.calendarSyncSuccess) {
        toastMessage = "Error Syncing Calendar";
      }
      this.setState({
        calendarSyncToastOpen: true,
        calendarSyncToastMessage: toastMessage,
      });
    }
  }

  componentWillUnmount() {
    this.props.clearUpdateUserInsuranceStatus();
  }

  onOpenManageCall = (call) => {
    this.setState({ manageCall: call, manageCallOpen: true });
  };

  onCloseManageCall = () => {
    this.setState({ manageCall: null, manageCallOpen: false });
  };

  onClickRescheduleSession = (recurringEvent) => {
    if (recurringEvent) {
      this.setState({ editSelectionOpen: true, manageCallOpen: false });
    } else {
      this.setState({ rescheduleCallOpen: true, manageCallOpen: false });
    }
  };

  onOpenStartCallDialog = (call) => {
    this.setState({ startCallOpen: true, startCallDetails: call });
  };

  onCloseStartCallDialog = () => {
    this.setState({ startCallOpen: false, startCallDetails: {} });
  };

  startVideoCall = () => {
    const { startCallDetails } = this.state;
    let roomId = uuidv4();
    if (startCallDetails.room_id) {
      roomId = startCallDetails.room_id;
    }
    const details = {
      videoInfo: startCallDetails,
      roomId,
      videoKey: startCallDetails.video_key,
    };
    this.props.setOneTimeVideoInfo(details);
    this.props.history.push(`/video/${details.videoKey}`);
  };

  onCloseRescheduleCall = () => {
    this.setState({
      rescheduleCallOpen: false,
      manageCall: null,
      editAllInstances: false,
    });
    this.props.getClinicianCallsForWeek(this.props.clinicianUserId);
    this.props.getNextClinicianCall(this.props.clinicianUserId);
    if (this.props.customerDetails?.clinician?.first_name) {
      this.props.clearCustomerInfo();
    }
  };

  onToggleEditSelection = () => {
    this.setState(
      {
        editSelectionOpen: !this.state.editSelectionOpen,
      },
      () => {
        if (!this.state.editSelectionOpen) {
          this.setState({
            editAllInstances: false,
          });
        }
      }
    );
  };

  onChangeEditSelection = (e) => {
    const { value } = e.target;
    this.setState({ editAllInstances: this.stringToBoolean(value) });
  };

  onContinueEditSelection = () => {
    this.setState({ editSelectionOpen: false, rescheduleCallOpen: true });
  };

  stringToBoolean = (value) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true;
      if (value.toLowerCase() === "false") return false;
    }
    return value;
  };

  onClickCancelSession = (recurringEvent) => {
    this.setState({ cancelSessionOpen: true, manageCallOpen: false });
  };

  onToggleCancelSession = () => {
    this.setState(
      {
        cancelSessionOpen: !this.state.cancelSessionOpen,
      },
      () => {
        if (!this.state.cancelSessionOpen) {
          this.setState({
            cancelReasonText: "",
            manageCall: {},
            cancelAllInstances: false,
            callNotStartedDetails: {},
          });
          this.props.getClinicianClientStatus(this.props.clinicianId);
        }
      }
    );
  };

  onChangeCancelSelection = (e) => {
    const { value } = e.target;
    this.setState({ cancelAllInstances: this.stringToBoolean(value) });
  };

  onChangeCancelReason = (e) => {
    this.setState({ cancelReasonText: e.target.value });
  };

  onChangeResponsibleForCancellation = (e) => {
    let cancelReasonText = getCancellationReasonLabel(e.target.value);
    this.setState({
      responsibleForCancellation: e.target.value,
      cancelReasonText,
    });
  };

  submitCancelSession = () => {
    const { manageCall, cancelReasonText, cancelAllInstances, responsibleForCancellation } =
      this.state;
    this.props.cancelVideoCall({
      message: cancelReasonText,
      responsibleForCancellation: getKeyCancellationReason(responsibleForCancellation),
      id: manageCall.id,
      clinicianUserId: this.props.clinicianUserId,
      cancelAllInstances,
    });
    this.onToggleCancelSession();
  };

  onChangeCancelReasonText = (e) => {
    this.setState({ cancelReasonText: e.target.value });
  };

  openCptCodeDialog = async (videoCallInfo, enterCode) => {
    this.setState(
      {
        cptCodeVideoCall: videoCallInfo,
        currentBreakdown: [
          {
            ...videoCallInfo,
            client_name: `${videoCallInfo.child_first_name} ${videoCallInfo.child_last_name}`,
            insurance_code: videoCallInfo.item_type,
            billable_time_id: videoCallInfo.id,
          },
        ],
      },
      () => {
        this.setState({ cptCodeDialogOpen: true, enterInsuranceCode: true });
      }
    );
  };

  closeCPTCodeDialog = () => {
    this.setState({
      cptCodeDialogOpen: false,
      cptCodeVideoCall: null,
    });
  };

  openSessionNotesDialog = (billableTimeId) => {
    const { videoBreakdown } = this.props;
    let currentBreakdown = videoBreakdown?.filter((code) => code.id == billableTimeId);
    this.setState({ sessionNotesDialogOpen: true, currentBreakdown });
  };

  closeSessionNotesDialog = () => {
    this.setState({
      sessionNotesDialogOpen: false,
      currentBreakdown: [],
      enterInsuranceCode: false,
      cptCodeVideoCall: null,
    });
  };

  toggleStartDemoCallDialog = () => {
    this.setState({ startDemoOpen: !this.state.startDemoOpen });
  };

  startDemoCall = async () => {
    const { clinicianUserId } = this.props;
    // We want to have a time that is available in the new schedule page
    const startDate = roundDownToNearest15(new Date());
    let videoDetails = {
      startDate,
      endDate: addMinutes(startDate, 30),
      clinicianUserId,
    };
    this.props.clearVideoInfo();
    this.props.setDemoCall(videoDetails);
  };

  goToClientDetails = (clientClientId) => {
    this.props.history.push(`/clients/${clientClientId}/dashboard`);
  };

  openScheduleCallDialog = (client) => {
    if (this.state.selectedClientUserId) {
      this.setState(
        {
          selectedClient: {},
          selectedClientUserId: null,
          selectedClientId: null,
        },
        () => {
          this.setState({
            scheduleCallOpen: true,
            selectedClientUserId: client.user_id,
            selectedClientId: client.client_id,
            selectedClient: client,
          });
        }
      );
    } else {
      this.setState({
        scheduleCallOpen: true,
        selectedClientUserId: client.user_id,
        selectedClientId: client.client_id,
        selectedClient: client,
      });
    }
  };

  closeScheduleCallDialog = () => {
    this.setState({
      scheduleCallOpen: false,
    });
    this.props.getClinicianClientStatus(this.props.clinicianId);
    this.props.getClinicianCallsForWeek(this.props.clinicianUserId);
    this.props.getNextClinicianCall(this.props.clinicianUserId);
    if (this.props.customerDetails?.clinician?.first_name) {
      this.props.clearCustomerInfo();
    }
  };

  navigate = (url) => {
    this.props.history.push(url);
  };

  onToggleAssessmentComplete = (client) => {
    if (this.state.assessmentCompleteOpen) {
      this.setState({ assessmentCompleteOpen: false });
    } else {
      if (this.state.selectedClientUserId) {
        this.setState(
          {
            selectedClient: {},
            selectedClientUserId: null,
            selectedClientId: null,
          },
          () => {
            this.setState({
              assessmentCompleteOpen: true,
              selectedClientUserId: client.user_id,
              selectedClientId: client.client_id,
            });
          }
        );
      } else {
        this.setState({
          assessmentCompleteOpen: true,
          selectedClientUserId: client.user_id,
          selectedClientId: client.client_id,
        });
      }

      this.setState({
        assessmentCompleteOpen: true,
        selectedClientUserId: client.user_id,
        selectedClientId: client.client_id,
      });
    }
  };

  onSubmitMarkAssessmentComplete = () => {
    this.props.updateUserInsuranceStatus({
      status: "ASSESSMENT_COMPLETED",
      clientId: this.state.selectedClientId,
    });
    this.onToggleAssessmentComplete();
  };

  persistTreatmentPlan = (update) => {
    const { fileObjects } = this.state;
    this.setState(
      {
        treatmentPlan: fileObjects,
        fileObjects: [],
        treatmentDropzoneOpen: false,
        updateTreatmentDropzoneOpen: false,
      },
      () => {
        if (update) {
          this.handleUpdateTreatmentPlan();
        } else {
          this.handleSaveTreatmentPlan();
        }
      }
    );
  };

  onOpenTreatmentPlanDialog = (clientId) => {
    if (this.state.selectedClientUserId) {
      this.setState({
        selectedClient: {},
        selectedClientUserId: null,
        selectedClientId: null,
      });
    }
    this.props.clearCustomerInfo();
    this.props.getCustomerInfo(clientId);
    this.setState({ treatmentDropzoneOpen: true });
  };

  openTreatmentDropzone = () => {
    if (this.state.updateTreatmentPlan) {
      this.setState({ updateTreatmentDropzoneOpen: true });
    } else {
      this.setState({ treatmentDropzoneOpen: true });
    }
  };

  onCloseUpdateTreatmentPlan = () => {
    this.setState({ updateTreatmentDropzoneOpen: false });
  };

  onOpenUpdateTreatmentPlan = (clientId) => {
    this.props.clearCustomerInfo();
    this.props.getCustomerInfo(clientId);
    this.props.getCustomerTreatmentPlans(clientId);
    this.setState({ updateTreatmentDropzoneOpen: true });
  };

  handleCloseDropzone = () => {
    this.setState({ treatmentDropzoneOpen: false });
  };

  addFileObjects = (fileObjects) => {
    this.setState({ fileObjects });
  };

  persistFileObjects = (name, dialog) => {
    const { fileObjects } = this.state;
    this.setState({
      [name]: fileObjects,
      fileObjects: [],
      [dialog]: false,
    });
  };

  handleSaveTreatmentPlan = async () => {
    this.setState({ treatmentPlanUploading: true });
    const { customerDetails } = this.props;
    const { details } = customerDetails;
    const { treatmentPlan } = this.state;
    const file = treatmentPlan[0].file;
    const credentials = await getAWSCredentialsForCurrentUserSession();
    const s3 = new AWS.S3({
      credentials,
      apiVersion: "2006-03-01",
      params: { Bucket: process.env.AWS_USER_DOC_BUCKET },
    });
    const fileUUID = uuidv4();
    const params = {
      ACL: "authenticated-read",
      Body: file,
      ContentType: file.type,
      Key: `${details.cognito_id}/treatment_plan-${fileUUID}.pdf`,
    };
    const upload = await s3.upload(params).promise();
    const treatmentPlanData = {
      filename: upload.Key.split("/")[1],
      display_filename: file.name,
      clientId: details.client_id,
      type: "TREATMENT_PLAN",
      internal: false,
      billing_related: false,
      clinicianUserId: this.props.clinicianUserId,
      uploaded_by: this.props.clinicianUserId,
      file_size: file.size,
    };
    this.props.uploadTreatmentPlan(treatmentPlanData);
  };

  handleUpdateTreatmentPlan = async () => {
    this.setState({ treatmentPlanUploading: true });
    const { customerDetails } = this.props;
    const { details } = customerDetails;
    const { updateTreatmentPlan, treatmentPlan } = this.state;
    const file = treatmentPlan[0].file;
    const credentials = await getAWSCredentialsForCurrentUserSession();
    const s3 = new AWS.S3({
      credentials,
      apiVersion: "2006-03-01",
      params: { Bucket: process.env.AWS_USER_DOC_BUCKET },
    });
    const params = {
      ACL: "authenticated-read",
      Body: file,
      ContentType: file.type,
      Key: `${updateTreatmentPlan.cognito_id}/${updateTreatmentPlan.filename}`,
    };
    const upload = await s3.upload(params).promise();
    const treatmentPlanData = {
      id: updateTreatmentPlan.id,
      clientId: details.client_id,
      clinicianUserId: this.props.clinicianUserId,
    };
    this.props.updateTreatmentPlan(treatmentPlanData);
  };

  openSessionNotesDialogForTreatmentPlan = () => {
    this.setState({
      sessionNotesOpen: true,
      updateTreatmentPlan: null,
      treatmentPlan: [],
    });
  };

  closeSessionNotesDialogForTreatmentPlan = () => {
    this.setState({ sessionNotesOpen: false });
  };

  handleInsuranceApprovedScheduleCall = () => {
    this.props.updateUserInsuranceStatus({
      status: "FULL_CUSTOMER",
      clientId: this.state.selectedClientId,
    });
  };

  onToggleCompleteOffPlatformDialog = (offPlatformDetails) => {
    this.setState({
      completeOffPlatformOpen: !this.state.completeOffPlatformOpen,
      offPlatformDetails,
    });
  };

  onClickViewSMSThread = (clientId, date) => {
    this.props.history.push(`/clients/${clientId}/communication?sms-open=true&date=${date}`);
  };

  onToggleCallNotStartedDialog = () => {
    this.setState({
      callNotStartedDialogOpen: !this.state.callNotStartedDialogOpen,
    });
  };

  onChangeCallNotStartedReason = (e) => {
    this.setState({ callNotStartedReason: e.target.value });
  };

  onSubmitCallNotStartedReason = () => {
    const { callNotStartedReason, callNotStartedDetails } = this.state;
    if (callNotStartedReason == "cancel") {
      this.setState({
        manageCall: callNotStartedDetails,
        cancelSessionOpen: true,
        callNotStartedDialogOpen: false,
        callNotStartedReason: "",
      });
    } else {
      this.props.markClientNoShow(callNotStartedDetails.id);
    }
  };

  onNavToBillingFromCallNotEnded = (notification) => {
    this.props.dismissClinicianNotification({
      notificationId: notification.id,
      clinicianId: notification.clinician_id,
    });
    this.props.history.push("/billing");
  };

  onOpenCallNotEndedDialog = () => {
    this.setState({ callNotEndedDialogOpen: true });
  };

  onCloseCallNotEndedDialog = (notification) => {
    this.props.dismissClinicianNotification({
      notificationId: notification.id,
      clinicianId: notification.clinician_id,
    });
    this.setState({ callNotEndedDialogOpen: false });
  };

  onCloseCalendarSyncToast = () => {
    this.setState({
      calendarSyncToastOpen: false,
      calendarSyncToastMessage: "",
    });
  };

  openCalendarSyncDialog = () => {
    this.setState({
      calendarSyncOpen: true,
      calendarSyncToastOpen: false,
      calendarSyncToastMessage: "",
    });
  };

  onCloseCalendarSyncDialog = () => {
    this.setState({
      calendarSyncOpen: false,
    });
  };

  render() {
    const {
      classes,
      clinician,
      history,
      clinicianWorkflowStatus,
      dashboardLoading,
      onboardingDashboard,
      pendingSignatures,
      userPermissions,
      videoBillingIssues,
      setDemoCallLoading,
      clientStatusInfoLoading,
      clientStatusInfo,
      updateUserInsuranceStatusLoading,
      callsNeverStarted,
      clinicianId,
      calendarSyncSuccess,
    } = this.props;

    const {
      cptCodeVideoCall,
      cptCodeDialogOpen,
      startDemoOpen,
      fileObjects,
      updateTreatmentPlan,
      sessionNotesDialogOpen,
      callNotStartedDialogOpen,
      calendarSyncToastOpen,
      calendarSyncToastMessage,
      calendarSyncOpen,
      onSchedFeatureFlag,
    } = this.state;
    const loading =
      !clinicianWorkflowStatus || clientStatusInfoLoading || updateUserInsuranceStatusLoading;
    return (
      <PageContainer loading={loading}>
        <div className={classes.mainContainer}>
          <Heading style={{ textAlign: "left" }}>
            {clinician?.first_name && `Welcome, ${clinician.first_name}`}
          </Heading>
          {clinicianId && onSchedFeatureFlag && (
            <CalendarSync open={calendarSyncOpen} onClose={this.onCloseCalendarSyncDialog} />
          )}
          <Notifications
            onClickViewSMSThread={this.onClickViewSMSThread}
            onNavToBillingFromCallNotEnded={this.onNavToBillingFromCallNotEnded}
            onOpenCallNotEndedDialog={this.onOpenCallNotEndedDialog}
            onCloseCallNotEndedDialog={this.onCloseCallNotEndedDialog}
            callNotEndedDialogOpen={this.state.callNotEndedDialogOpen}
            {...this.props}
          />

          <IncompleteOffPlatformCalls
            {...this.state}
            {...this.props}
            onToggleCompleteOffPlatformDialog={this.onToggleCompleteOffPlatformDialog}
            navigate={this.navigate}
          />

          <ClinicianQuickActions
            {...this.state}
            {...this.props}
            toggleStartDemoCallDialog={this.toggleStartDemoCallDialog}
            navigate={this.navigate}
            openScheduleCallDialog={this.openScheduleCallDialog}
            onToggleAssessmentComplete={this.onToggleAssessmentComplete}
            onOpenTreatmentPlanDialog={this.onOpenTreatmentPlanDialog}
            onOpenUpdateTreatmentPlan={this.onOpenUpdateTreatmentPlan}
          />

          <NextSessionBlock
            showClientProgress={true}
            history={history}
            userPermissions={userPermissions}
          />
          <WeeklySchedule
            onOpenManageCallDialog={this.onOpenManageCall}
            onOpenStartCallDialog={this.onOpenStartCallDialog}
            onCloseStartCallDialog={this.onCloseStartCallDialog}
            startVideoCall={this.startVideoCall}
            {...this.props}
            {...this.state}
          />
          {this.state.manageCall && (
            <>
              <ManageCallDialog
                onClickRescheduleSession={this.onClickRescheduleSession}
                onCloseManageCallDialog={this.onCloseManageCall}
                videoCall={this.state.manageCall}
                onClickCancelSession={this.onClickCancelSession}
                {...this.state}
                {...this.props}
              />
              <CancelSessionDialog
                sessionDetails={this.state.manageCall}
                open={this.state.cancelSessionOpen}
                cancelReasonText={this.state.cancelReasonText}
                responsibleForCancellation={this.state.responsibleForCancellation}
                closeDialog={this.onToggleCancelSession}
                onChangeCancelReason={this.onChangeCancelReason}
                submitCancelSession={this.submitCancelSession}
                onChangeResponsibleForCancellation={this.onChangeResponsibleForCancellation}
                disableClose={this.state.callNotStartedDetails?.id == this.state.manageCall?.id}
                cancelAllInstances={this.state.cancelAllInstances}
                onChangeCancelSelection={this.onChangeCancelSelection}
              />
              <EditSelectionDialog
                sessionDetails={this.state.manageCall}
                open={this.state.editSelectionOpen}
                editAllInstances={this.state.editAllInstances}
                closeDialog={this.onToggleEditSelection}
                onChangeEditSelection={this.onChangeEditSelection}
                onContinueEditSelection={this.onContinueEditSelection}
              />
            </>
          )}
          {cptCodeDialogOpen && (
            <VideoBreakdownDialog
              open={cptCodeDialogOpen}
              startDate={cptCodeVideoCall?.start_date}
              endDate={cptCodeVideoCall?.end_date}
              videoCallId={cptCodeVideoCall?.id}
              clientUserId={cptCodeVideoCall?.user_id}
              clientData={{
                child_name: cptCodeVideoCall?.child_first_name,
                child_last_name: cptCodeVideoCall?.child_last_name,
              }}
              clinicianUserId={cptCodeVideoCall?.clinician_user_id}
              onCloseCPTCodeDialog={this.closeCPTCodeDialog}
              inVideoCall={false}
              isDemo={false}
              enterInsuranceCode={this.state.enterInsuranceCode}
              videoBreakdown={this.state.currentBreakdown}
              showExtendedInfo={true}
              billingType={cptCodeVideoCall?.billing_type}
              clientId={cptCodeVideoCall?.client_id}
              sessionNoteId={cptCodeVideoCall?.sessionNoteId}
              billableTimeId={cptCodeVideoCall?.billableTimeId}
              isSecondary={false}
            />
          )}
          <StartDemoCallConfirmDialog
            startDemoOpen={startDemoOpen}
            toggleStartDemoCallDialog={this.toggleStartDemoCallDialog}
            startDemoCall={this.startDemoCall}
            setDemoCallLoading={setDemoCallLoading}
          />
          <MarkAssessmentCompleteDialog
            isClinician={true}
            onToggleAssessmentComplete={this.onToggleAssessmentComplete}
            onSubmitMarkAssessmentComplete={this.onSubmitMarkAssessmentComplete}
            open={this.state.assessmentCompleteOpen}
          />
          {callNotStartedDialogOpen &&
            this.state.callNotStartedDetails &&
            !this.state.cancelSessionOpen && (
              <CallNotStartedDialog
                sessionDetails={this.state.callNotStartedDetails}
                open={callNotStartedDialogOpen}
                closeDialog={this.onToggleCallNotStartedDialog}
                callNotStartedReason={this.state.callNotStartedReason}
                onChangeCallNotStartedReason={this.onChangeCallNotStartedReason}
                onSubmitCallNotStartedReason={this.onSubmitCallNotStartedReason}
                loading={this.props.completingOffPlatform}
              />
            )}

          <DropzoneDialogBase
            open={this.state.treatmentDropzoneOpen}
            filesLimit={1}
            acceptedFiles={[".pdf"]}
            fileObjects={fileObjects}
            showPreviews={false}
            showPreviewsInDropzone={true}
            maxFileSize={15728640}
            onClose={this.handleCloseDropzone}
            dropzoneText={"Drag and drop or click here"}
            dialogProps={{
              style: { zIndex: 40003 },
            }}
            onDelete={(deleteFileObj) => {
              this.addFileObjects([]);
            }}
            onSave={() => {
              this.persistTreatmentPlan(false);
            }}
            onAdd={(newFileObjs) => {
              this.addFileObjects(newFileObjs);
            }}
            submitButtonText="Save"
          />

          <DropzoneDialogBase
            open={this.state.updateTreatmentDropzoneOpen}
            filesLimit={1}
            acceptedFiles={[".pdf"]}
            showPreviews={false}
            showPreviewsInDropzone={true}
            maxFileSize={15728640}
            fileObjects={fileObjects}
            onClose={this.onCloseUpdateTreatmentPlan}
            dropzoneText={"Drag and drop or click here"}
            dialogProps={{
              style: { zIndex: 40003 },
            }}
            onDelete={(deleteFileObj) => {
              this.addFileObjects([]);
            }}
            onSave={() => this.persistTreatmentPlan(true)}
            onAdd={(newFileObjs) => {
              this.addFileObjects(newFileObjs);
            }}
            submitButtonText="Save"
          />
          {this.state.completeOffPlatformOpen && (
            <CompleteOffPlatformDialog
              callDetails={this.state.offPlatformDetails}
              onToggleCompleteOffPlatformDialog={this.onToggleCompleteOffPlatformDialog}
              openCptCodeDialog={this.openCptCodeDialog}
            />
          )}
        </div>

        <Snackbar
          sx={{ zIndex: 3007 }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={calendarSyncToastOpen}
          onClose={this.onCloseCalendarSyncToast}
          message={calendarSyncToastMessage}
          classes={{ anchorOriginBottomCenter: classes.snackbar }}
          action={
            calendarSyncSuccess ? (
              <IconButton color="inherit" sx={{ p: 0.5 }} onClick={this.onCloseCalendarSyncToast}>
                <X size={20} color="#fff" />
              </IconButton>
            ) : (
              <Button
                color="primary"
                variant="text"
                onClick={this.openCalendarSyncDialog}
                styles={{
                  fontFamily: "Basier Circle",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "22px",
                  letterSpacing: "0.46px",
                  textTransform: "capitalize",
                }}
              >
                Try again
              </Button>
            )
          }
        />
      </PageContainer>
    );
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ClinicianDashboardContainer)
);
