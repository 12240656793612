import { TextField, Typography } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import React from "react";

export const InsuranceCompanyForm = (data, errors, onChange) => {
  return [
    {
      id: "name",
      input: (
        <TextField
          id="name"
          label="Company name"
          onChange={onChange("name", null, data)}
          value={data.name ?? ""}
          fullWidth
        />
      ),
      cols: 12,
    },
    {
      id: "contract_type",
      title: "Contact Type",
      input: (
        <>
          <RadioGroup
            id="contract_type"
            aria-label="contract_type"
            name="contract_type"
            value={data.contract_type ?? ""}
            onChange={onChange("contract_type", null, data)}
          >
            <FormControlLabel
              value={"group"}
              control={<Radio color="primary" size="small" />}
              label={
                <Typography
                  component="p"
                  style={{
                    fontSize: 14,
                    color: "#2A2D39",
                  }}
                >
                  Group Contract
                </Typography>
              }
            />
            <FormControlLabel
              value={"single_case"}
              control={<Radio color="primary" size="small" />}
              label={
                <Typography
                  component="p"
                  style={{
                    fontSize: 14,
                    color: "#2A2D39",
                  }}
                >
                  Single case Agreement
                </Typography>
              }
            />
          </RadioGroup>
        </>
      ),
      cols: 12,
    },
  ];
};
