import { createReducer } from "redux-create-reducer";
import actions from "../actions";

const initialState = {
  workflowStatus: "",
  rejectionNote: "",
  clinicianId: "",
  basicInfo: {},
  basicInfoLoading: false,
  basicInfoSaving: false,
  basicInfoSuccess: false,
  driversLicenseLoading: false,
  driversLicenseSaving: false,
  driversLicenseSuccess: false,
  driversLicenseError: false,
  credentials: [],
  credentialsLoading: false,
  credentialsSaving: false,
  credentialsSuccess: false,
  certificationLoading: false,
  certificationSaving: false,
  certificationSuccess: false,
  licenses: [],
  license: null,
  licensesLoading: false,
  licensesSaving: false,
  licensesSuccess: false,
  availability: [],
  availabilityLoading: false,
  availabilitySaving: false,
  availabilitySuccess: false,
  documents: [],
  documentsLoading: false,
  documentsSaving: false,
  documentsSuccess: false,
  applicationLoading: false,
  applicationSuccess: false,
  applicationError: null,
  applicationErrorMessage: "",
  applicantCognitoId: null,
  applicantClinicianId: null,
  insurances: [],
  insuranceLoading: false,
  insuranceSaving: false,
  insuranceSuccess: false,
  bioSaving: false,
  bioSuccess: false,
  bioFail: false,
  bioPictureSaving: false,
  bioPictureSuccess: false,
  bioPictureFail: false,
  dashboard: {},
  dashboardLoading: false,
  dashboardSuccess: false,
  clients: [],
  clientsLoading: false,
  sessionClients: [],
  page: 0,
  rowsPerPage: 1000,
  count: 0,
  sort: "",
  sortdirection: "asc",
  pastClients: [],
  pastClientsPage: 0,
  pastClientsCount: 0,
  pastClientsSort: "",
  pastClientsSortDirection: "asc",
  payStructure: null,
  payStructureLoading: false,
  payStructureSaving: false,
  payStructureSuccess: false,
  liabilityExpirationLoading: false,
  liabilityExpirationSuccess: false,
  liabilityExpirationError: false,
  updateClinicianEmailLoading: false,
  updateClinicianEmailSuccess: false,
  updateClinicianEmailError: false,
  updateClinicianEmailErrorMessage: null,
  clientStatusInfo: [],
  clientStatusInfoLoading: false,
  notes: [],
  noteSaving: false,
  noteSuccess: false,
  notifications: [],
  notificationsLoading: false,
  notificationsSuccess: false,
  callsNeverStarted: [],
  clinicianCredentialData: {},
  clinicianCredentialDataError: null,
  clinicianCredentialDataLoading: false,
  insuranceSaveFail: false,
  calendarSynced: false,
  calendarSyncedDate: null,
  calendarSyncedEmail: "",
  calendarSyncLoading: false,
  calendarSyncSuccess: false,
};

const reducers = {
  [actions.GET_CLINICIAN_STATUS](state) {
    return {
      ...state,
      workflowStatus: "",
      rejectionNote: "",
      applicationSuccess: false,
    };
  },
  [actions.GET_CLINICIAN_STATUS_SUCCESS](state, { payload }) {
    return {
      ...state,
      workflowStatus: payload.workflow_status,
      clinicianId: payload.clinician_id,
      rejectionNote: payload.rejection_note,
      calendarSynced: !!payload.calendar_synced,
      calendarSyncedDate: payload.calendar_synced_date,
      calendarSyncedEmail: payload.syncedEmail,
    };
  },
  [actions.CLEAR_CLINICIAN_DETAILS](state) {
    return {
      ...initialState,
    };
  },
  [actions.GET_CLINICIAN_BASIC_INFO](state) {
    return {
      ...state,
      basicInfoLoading: true,
      basicInfoSuccess: false,
    };
  },
  [actions.GET_CLINICIAN_BASIC_INFO_SUCCESS](state, { payload }) {
    return {
      ...state,
      basicInfoLoading: false,
      basicInfoSuccess: true,
      basicInfo: {
        ...payload,
        is_active: payload.is_active,
        deactivation: payload.deactivation ? payload.deactivation : null,
      },
    };
  },
  [actions.SAVE_CLINICIAN_BASIC_INFO](state) {
    return {
      ...state,
      basicInfoSaving: true,
      basicInfoSuccess: false,
    };
  },
  [actions.SAVE_CLINICIAN_BASIC_INFO_SUCCESS](state) {
    return {
      ...state,
      basicInfoSaving: false,
      basicInfoSuccess: true,
    };
  },
  [actions.SAVE_CLINICIAN_BASIC_INFO_FAIL](state) {
    return {
      ...state,
      basicInfoSaving: false,
      basicInfoSuccess: false,
    };
  },
  [actions.UPDATE_CLINICIAN_BIO](state) {
    return {
      ...state,
      bioSaving: true,
      bioSuccess: false,
    };
  },
  [actions.UPDATE_CLINICIAN_BIO_SUCCESS](state) {
    return {
      ...state,
      bioSaving: false,
      bioSuccess: true,
    };
  },
  [actions.UPDATE_CLINICIAN_BIO_FAIL](state) {
    return {
      ...state,
      bioSaving: false,
      bioSuccess: false,
      bioFail: true,
    };
  },
  [actions.UPDATE_CLINICIAN_PICTURE](state) {
    return {
      ...state,
      bioPictureSaving: true,
      bioPictureSuccess: false,
    };
  },
  [actions.UPDATE_CLINICIAN_PICTURE_SUCCESS](state) {
    return {
      ...state,
      bioPictureSaving: false,
      bioPictureSuccess: true,
    };
  },
  [actions.UPDATE_CLINICIAN_PICTURE_FAIL](state) {
    return {
      ...state,
      bioPictureSaving: false,
      bioPictureSuccess: false,
      bioPictureFail: true,
    };
  },
  [actions.SAVE_CLINICIAN_DRIVERS_LICENSE](state) {
    return {
      ...state,
      driversLicenseSaving: true,
      driversLicenseSuccess: false,
      driversLicenseError: false,
    };
  },
  [actions.SAVE_CLINICIAN_DRIVERS_LICENSE_SUCCESS](state) {
    return {
      ...state,
      driversLicenseSaving: false,
      driversLicenseSuccess: true,
    };
  },
  [actions.SAVE_CLINICIAN_DRIVERS_LICENSE_FAIL](state) {
    return {
      ...state,
      driversLicenseSaving: false,
      driversLicenseSuccess: false,
      driversLicenseError: true,
    };
  },
  [actions.CLEAR_CLINICIAN_DRIVERS_LICENSE_INFO](state) {
    return {
      ...state,
      driversLicenseSaving: false,
      driversLicenseSuccess: false,
      driversLicenseError: false,
    };
  },
  [actions.GET_CLINICIAN_CREDENTIALS](state) {
    return {
      ...state,
      credentialsLoading: true,
      credentialsSuccess: false,
    };
  },
  [actions.GET_CLINICIAN_CREDENTIALS_SUCCESS](state, { payload }) {
    return {
      ...state,
      credentialsLoading: false,
      credentialsSuccess: true,
      credentials: payload,
    };
  },
  [actions.SAVE_CLINICIAN_CREDENTIALS](state) {
    return {
      ...state,
      credentialsSaving: true,
      credentialsSuccess: false,
    };
  },
  [actions.SAVE_CLINICIAN_CREDENTIALS_SUCCESS](state) {
    return {
      ...state,
      credentialsSaving: false,
      credentialsSuccess: true,
    };
  },
  [actions.SAVE_CLINICIAN_CREDENTIALS_FAIL](state) {
    return {
      ...state,
      credentialsSaving: false,
      credentialsSuccess: false,
    };
  },
  [actions.GET_CLINICIAN_STATE_LICENSE](state) {
    return {
      ...state,
      licensesLoading: true,
      licensesSuccess: false,
    };
  },
  [actions.GET_CLINICIAN_STATE_LICENSE_SUCCESS](state, { payload }) {
    return {
      ...state,
      licensesLoading: false,
      licensesSuccess: true,
      licenses: payload,
    };
  },
  [actions.GET_CLINICIAN_STATE_LICENSE_BY_ID](state) {
    return {
      ...state,
      licensesLoading: true,
      licensesSuccess: false,
    };
  },
  [actions.GET_CLINICIAN_STATE_LICENSE_BY_ID_SUCCESS](state, { payload }) {
    return {
      ...state,
      licensesLoading: false,
      licensesSuccess: true,
      license: payload,
    };
  },
  [actions.SAVE_CLINICIAN_STATE_LICENSE](state) {
    return {
      ...state,
      licensesSaving: true,
      licensesSuccess: false,
    };
  },
  [actions.SAVE_CLINICIAN_STATE_LICENSE_SUCCESS](state) {
    return {
      ...state,
      licensesSaving: false,
      licensesSuccess: true,
    };
  },
  [actions.SAVE_CLINICIAN_STATE_LICENSE_FAIL](state) {
    return {
      ...state,
      licensesSaving: false,
      licensesSuccess: false,
    };
  },
  [actions.UPDATE_CLINICIAN_STATE_LICENSE](state) {
    return {
      ...state,
      licensesSaving: true,
      licensesSuccess: false,
    };
  },
  [actions.UPDATE_CLINICIAN_STATE_LICENSE_SUCCESS](state) {
    return {
      ...state,
      licensesSaving: false,
      licensesSuccess: true,
    };
  },
  [actions.UPDATE_CLINICIAN_STATE_LICENSE_FAIL](state) {
    return {
      ...state,
      licensesSaving: false,
      licensesSuccess: false,
    };
  },
  [actions.DELETE_CLINICIAN_STATE_LICENSE](state) {
    return {
      ...state,
      licensesSaving: true,
      licensesSuccess: false,
    };
  },
  [actions.DELETE_CLINICIAN_STATE_LICENSE_SUCCESS](state) {
    return {
      ...state,
      licensesSaving: false,
      licensesSuccess: true,
    };
  },
  [actions.DELETE_CLINICIAN_STATE_LICENSE_FAIL](state) {
    return {
      ...state,
      licensesSaving: false,
      licensesSuccess: false,
    };
  },
  [actions.SAVE_CLINICIAN_CERTIFICATION](state) {
    return {
      ...state,
      certificationSaving: true,
      certificationSuccess: false,
    };
  },
  [actions.SAVE_CLINICIAN_CERTIFICATION_SUCCESS](state) {
    return {
      ...state,
      certificationSaving: false,
      certificationSuccess: true,
    };
  },
  [actions.SAVE_CLINICIAN_CERTIFICATION_FAIL](state) {
    return {
      ...state,
      certificationSaving: false,
      certificationSuccess: false,
    };
  },
  [actions.GET_CLINICIAN_INSURANCES](state) {
    return {
      ...state,
      insurances: [],
      insuranceLoading: true,
      insuranceSuccess: false,
    };
  },
  [actions.GET_CLINICIAN_INSURANCES_SUCCESS](state, { payload }) {
    return {
      ...state,
      insurances: payload,
      insuranceLoading: false,
      insuranceSuccess: true,
    };
  },
  [actions.GET_CLINICIAN_INSURANCES_FAIL](state) {
    return {
      ...state,
      insuranceLoading: false,
      insuranceSuccess: false,
    };
  },
  [actions.UPDATE_CLINICIAN_INSURANCE](state) {
    return {
      ...state,
      insuranceSaving: true,
      insuranceSuccess: false,
      insuranceSaveFail: false,
    };
  },
  [actions.UPDATE_CLINICIAN_INSURANCE_SUCCESS](state) {
    return {
      ...state,
      insuranceSaving: false,
      insuranceSuccess: true,
      insuranceSaveFail: false,
    };
  },
  [actions.UPDATE_CLINICIAN_INSURANCE_FAIL](state) {
    return {
      ...state,
      insuranceSaving: false,
      insuranceSuccess: false,
      insuranceSaveFail: true,
    };
  },
  [actions.ADD_CLINICIAN_INSURANCE](state) {
    return {
      ...state,
      insuranceSaving: true,
      insuranceSuccess: false,
      insuranceSaveFail: false,
    };
  },
  [actions.ADD_CLINICIAN_INSURANCE_SUCCESS](state) {
    return {
      ...state,
      insuranceSaving: false,
      insuranceSuccess: true,
      insuranceSaveFail: false,
    };
  },
  [actions.ADD_CLINICIAN_INSURANCE_FAIL](state) {
    return {
      ...state,
      insuranceSaving: false,
      insuranceSuccess: false,
      insuranceSaveFail: true,
    };
  },
  [actions.GET_CLINICIAN_AVAILABILITY](state) {
    return {
      ...state,
      availabilityLoading: true,
      availabilitySuccess: false,
    };
  },
  [actions.GET_CLINICIAN_AVAILABILITY_SUCCESS](state, { payload }) {
    return {
      ...state,
      availabilityLoading: false,
      availabilitySuccess: true,
      availability: payload,
    };
  },
  [actions.SAVE_CLINICIAN_AVAILABILITY](state) {
    return {
      ...state,
      availabilitySaving: true,
      availabilitySuccess: false,
    };
  },
  [actions.SAVE_CLINICIAN_AVAILABILITY_SUCCESS](state) {
    return {
      ...state,
      availabilitySaving: false,
      availabilitySuccess: true,
    };
  },
  [actions.SAVE_CLINICIAN_AVAILABILITY_FAIL](state) {
    return {
      ...state,
      availabilitySaving: false,
      availabilitySuccess: false,
    };
  },
  [actions.GET_CLINICIAN_DOCUMENTS](state) {
    return {
      ...state,
      documentsLoading: true,
      documentsSuccess: false,
    };
  },
  [actions.GET_CLINICIAN_DOCUMENTS_SUCCESS](state, { payload }) {
    return {
      ...state,
      documentsLoading: false,
      documentsSuccess: true,
      documents: payload,
    };
  },
  [actions.SAVE_CLINICIAN_DOCUMENT](state) {
    return {
      ...state,
      documentsLoading: true,
      documentsSuccess: false,
    };
  },
  [actions.SAVE_CLINICIAN_DOCUMENT_SUCCESS](state) {
    return {
      ...state,
      documentsLoading: false,
      documentsSuccess: true,
    };
  },
  [actions.SAVE_CLINICIAN_DOCUMENT_FAIL](state) {
    return {
      ...state,
      documentsLoading: false,
      documentsSuccess: false,
    };
  },
  [actions.REPLACE_CLINICIAN_DOCUMENT](state) {
    return {
      ...state,
      documentsSaving: true,
      documentsSuccess: false,
    };
  },
  [actions.REPLACE_CLINICIAN_DOCUMENT_SUCCESS](state) {
    return {
      ...state,
      documentsSaving: false,
      documentsSuccess: true,
    };
  },
  [actions.REPLACE_CLINICIAN_DOCUMENT_FAIL](state) {
    return {
      ...state,
      documentsSaving: false,
      documentsSuccess: false,
    };
  },
  [actions.APPROVE_CLINICIAN_DOCUMENT](state) {
    return {
      ...state,
      documentsSaving: true,
      documentsSuccess: false,
    };
  },
  [actions.APPROVE_CLINICIAN_DOCUMENT_SUCCESS](state) {
    return {
      ...state,
      documentsSaving: false,
      documentsSuccess: true,
    };
  },
  [actions.APPROVE_CLINICIAN_DOCUMENT_FAIL](state) {
    return {
      ...state,
      documentsSaving: false,
      documentsSuccess: false,
    };
  },
  [actions.REJECT_CLINICIAN_DOCUMENT](state) {
    return {
      ...state,
      documentsSaving: true,
      documentsSuccess: false,
    };
  },
  [actions.REJECT_CLINICIAN_DOCUMENT_SUCCESS](state) {
    return {
      ...state,
      documentsSaving: false,
      documentsSuccess: true,
    };
  },
  [actions.REJECT_CLINICIAN_DOCUMENT_FAIL](state) {
    return {
      ...state,
      documentsSaving: false,
      documentsSuccess: false,
    };
  },
  [actions.APPROVE_CLINICIAN_APPLICATION](state) {
    return {
      ...state,
      applicationLoading: true,
      applicationSuccess: false,
    };
  },
  [actions.APPROVE_CLINICIAN_APPLICATION_SUCCESS](state) {
    return {
      ...state,
      applicationLoading: false,
      applicationSuccess: true,
    };
  },
  [actions.APPROVE_CLINICIAN_APPLICATION_FAIL](state) {
    return {
      ...state,
      applicationLoading: false,
      applicationSuccess: false,
    };
  },
  [actions.REJECT_CLINICIAN_APPLICATION](state) {
    return {
      ...state,
      applicationLoading: true,
      applicationSuccess: false,
    };
  },
  [actions.REJECT_CLINICIAN_APPLICATION_SUCCESS](state) {
    return {
      ...state,
      applicationLoading: false,
      applicationSuccess: true,
    };
  },
  [actions.REJECT_CLINICIAN_APPLICATION_FAIL](state) {
    return {
      ...state,
      applicationLoading: false,
      applicationSuccess: false,
    };
  },
  [actions.GET_CLINICIAN_DASHBOARD](state) {
    return {
      ...state,
      dashboard: {},
      dashboardLoading: true,
      dashboardSuccess: false,
    };
  },
  [actions.GET_CLINICIAN_DASHBOARD_SUCCESS](state, { payload }) {
    return {
      ...state,
      dashboard: payload,
      dashboardLoading: false,
      dashboardSuccess: true,
    };
  },
  [actions.GET_CLINICIAN_CLIENTS](state) {
    return {
      ...state,
      clientsLoading: true,
    };
  },
  [actions.GET_CLINICIAN_CLIENTS_SUCCESS](state, { payload }) {
    return {
      ...state,
      clients: payload.clients,
      count: payload.count,
      clientsLoading: false,
    };
  },
  [actions.SET_CLINICIAN_CLIENTS_PAGE](state, { payload }) {
    return {
      ...state,
      page: payload.page,
    };
  },
  [actions.SET_CLINICIAN_CLIENTS_PER_PAGE](state, { payload }) {
    return {
      ...state,
      rowsPerPage: payload.rowsPerPage,
    };
  },
  [actions.SET_CLINICIAN_CLIENTS_SORT](state, { payload }) {
    let sortdirection = state.sortdirection;
    if (state.sort && state.sort !== payload.sort) {
      sortdirection = "asc";
    } else if (state.sort) {
      sortdirection = sortdirection === "asc" ? "desc" : "asc";
    }
    return {
      ...state,
      sort: payload.sort,
      sortdirection,
    };
  },
  [actions.GET_CLINICIAN_PAST_CLIENTS](state) {
    return {
      ...state,
      clientsLoading: true,
    };
  },
  [actions.GET_CLINICIAN_PAST_CLIENTS_SUCCESS](state, { payload }) {
    return {
      ...state,
      pastClients: payload.clients,
      pastClientsCount: payload.count,
      clientsLoading: false,
    };
  },
  [actions.SET_CLINICIAN_PAST_CLIENTS_PAGE](state, { payload }) {
    return {
      ...state,
      pastClientsPage: payload.page,
    };
  },
  [actions.SET_CLINICIAN_PAST_CLIENTS_SORT](state, { payload }) {
    let sortdirection = state.pastClientsSortDirection;
    if (state.pastClientsSort && state.pastClientsSort !== payload.sort) {
      sortdirection = "asc";
    } else if (state.pastClientsSort) {
      sortdirection = sortdirection === "asc" ? "desc" : "asc";
    }
    return {
      ...state,
      pastClientsSort: payload.sort,
      pastClientsSortDirection: sortdirection,
    };
  },
  [actions.GET_CLINICIAN_PAY_STRUCTURE](state) {
    return {
      ...state,
      payStructureLoading: true,
    };
  },
  [actions.GET_CLINICIAN_PAY_STRUCTURE_SUCCESS](state, { payload }) {
    return {
      ...state,
      payStructureLoading: false,
      payStructure: payload,
    };
  },
  [actions.SAVE_CLINICIAN_PAY_STRUCTURE](state) {
    return {
      ...state,
      payStructureSaving: true,
      payStructureSuccess: false,
    };
  },
  [actions.SAVE_CLINICIAN_PAY_STRUCTURE_SUCCESS](state) {
    return {
      ...state,
      payStructureSaving: false,
      payStructureSuccess: true,
    };
  },
  [actions.SAVE_CLINICIAN_PAY_STRUCTURE_FAIL](state) {
    return {
      ...state,
      payStructureSaving: false,
      payStructureSuccess: false,
    };
  },
  [actions.DELETE_CLINICIAN_BONUS](state) {
    return {
      ...state,
      payStructureSuccess: false,
    };
  },
  [actions.DELETE_CLINICIAN_BONUS_SUCCESS](state) {
    return {
      ...state,
      payStructureSuccess: true,
    };
  },
  [actions.DELETE_CLINICIAN_BONUS_FAIL](state) {
    return {
      ...state,
      payStructureSuccess: false,
    };
  },
  [actions.INTERVIEW_CLINICIAN_APPLICANT](state) {
    return {
      ...state,
      basicInfoLoading: true,
      basicInfoSuccess: false,
    };
  },
  [actions.REJECT_CLINICIAN_APPLICANT](state) {
    return {
      ...state,
      basicInfoLoading: true,
      basicInfoSuccess: false,
    };
  },
  [actions.INVITE_CLINICIAN](state) {
    return {
      ...state,
      basicInfoLoading: true,
      basicInfoSuccess: false,
    };
  },
  [actions.INVITE_CLINICIAN_FAIL](state) {
    return {
      ...state,
      basicInfoLoading: false,
    };
  },
  [actions.UPDATE_CLINICIAN_STATUS](state) {
    return {
      ...state,
      basicInfoLoading: true,
    };
  },
  [actions.UPDATE_CLINICIAN_STATUS_SUCCESS](state) {
    return {
      ...state,
      basicInfoLoading: false,
    };
  },
  [actions.UPDATE_CLINICIAN_STATUS_FAIL](state) {
    return {
      ...state,
      basicInfoLoading: false,
    };
  },
  [actions.SET_CLINICIAN_CREDENTIALED](state) {
    return {
      ...state,
      basicInfoLoading: true,
    };
  },
  [actions.UPDATE_LIABILITY_EXPIRATION_DATE](state) {
    return {
      ...state,
      liabilityExpirationLoading: true,
      liabilityExpirationSuccess: false,
      liabilityExpirationError: false,
    };
  },
  [actions.UPDATE_LIABILITY_EXPIRATION_DATE_SUCCESS](state) {
    return {
      ...state,
      liabilityExpirationLoading: false,
      liabilityExpirationSuccess: true,
    };
  },
  [actions.UPDATE_LIABILITY_EXPIRATION_DATE_FAIL](state) {
    return {
      ...state,
      liabilityExpirationLoading: false,
      liabilityExpirationError: true,
    };
  },
  [actions.UPDATE_CLINICIAN_EMAIL](state) {
    return {
      ...state,
      updateClinicianEmailLoading: true,
      updateClinicianEmailSuccess: false,
      updateClinicianEmailError: false,
      updateClinicianEmailErrorMessage: null,
    };
  },
  [actions.UPDATE_CLINICIAN_EMAIL_SUCCESS](state, { payload }) {
    return {
      ...state,
      updateClinicianEmailLoading: false,
      updateClinicianEmailSuccess: true,
      basicInfo: {
        ...state.basicInfo,
        email: payload,
      },
    };
  },
  [actions.UPDATE_CLINICIAN_EMAIL_FAIL](state, { payload }) {
    return {
      ...state,
      updateClinicianEmailLoading: false,
      updateClinicianEmailError: true,
      updateClinicianEmailErrorMessage: payload,
    };
  },
  [actions.GET_CLINICIAN_CLIENT_STATUS](state) {
    return {
      ...state,
      clientStatusInfoLoading: true,
    };
  },
  [actions.GET_CLINICIAN_CLIENT_STATUS_SUCCESS](state, { payload }) {
    return {
      ...state,
      clientStatusInfo: payload,
      clientStatusInfoLoading: false,
    };
  },
  [actions.GET_CLINICIAN_CLIENT_STATUS_FAIL](state, { payload }) {
    return {
      ...state,
      clientStatusInfoLoading: false,
    };
  },
  [actions.GET_CLINICIAN_SESSION_CLIENTS](state) {
    return {
      ...state,
      clientsLoading: true,
    };
  },
  [actions.GET_CLINICIAN_SESSION_CLIENTS_SUCCESS](state, { payload }) {
    return {
      ...state,
      sessionClients: payload,
      clientsLoading: false,
    };
  },
  [actions.GET_CLINICIAN_SESSION_CLIENTS_FAIL](state) {
    return {
      ...state,
      clientsLoading: false,
    };
  },
  [actions.GET_CLINICIAN_NOTES_SUCCESS](state, { payload }) {
    return {
      ...state,
      notes: payload,
    };
  },
  [actions.SAVE_CLINICIAN_NOTE](state) {
    return {
      ...state,
      noteSaving: true,
      noteSuccess: false,
    };
  },
  [actions.SAVE_CLINICIAN_NOTE_SUCCESS](state) {
    return {
      ...state,
      noteSaving: false,
      noteSuccess: true,
    };
  },
  [actions.SAVE_CLINICIAN_NOTE_FAIL](state) {
    return {
      ...state,
      noteSaving: false,
    };
  },
  [actions.EDIT_CLINICIAN_NOTE](state) {
    return {
      ...state,
      noteSaving: true,
      noteSuccess: false,
    };
  },
  [actions.EDIT_CLINICIAN_NOTE_SUCCESS](state) {
    return {
      ...state,
      noteSaving: false,
      noteSuccess: true,
    };
  },
  [actions.EDIT_CLINICIAN_NOTE_FAIL](state) {
    return {
      ...state,
      noteSaving: false,
    };
  },
  [actions.GET_CLINICIAN_NOTIFICATIONS_SUCCESS](state, { payload }) {
    return {
      ...state,
      notifications: payload,
    };
  },
  [actions.DISMISS_CLINICIAN_NOTIFICATION](state) {
    return {
      ...state,
      notificationsLoading: true,
      notificationsSuccess: false,
    };
  },
  [actions.DISMISS_CLINICIAN_NOTIFICATION_SUCCESS](state) {
    return {
      ...state,
      notificationsLoading: false,
      notificationsSuccess: true,
    };
  },
  [actions.DISMISS_CLINICIAN_NOTIFICATION_FAIL](state) {
    return {
      ...state,
      notificationsLoading: false,
      notificationsSuccess: false,
    };
  },
  [actions.GET_CLINICIAN_CALLS_NEVER_STARTED](state) {
    return {
      ...state,
      callsNeverStarted: [],
    };
  },
  [actions.GET_CLINICIAN_CALLS_NEVER_STARTED_SUCCESS](state, { payload }) {
    return {
      ...state,
      callsNeverStarted: payload,
    };
  },
  [actions.GET_CLINICIAN_CREDENTIAL_DATA_SUCCESS](state, { payload }) {
    return {
      ...state,
      clinicianCredentialData: payload,
      clinicianCredentialDataLoading: false,
      clinicianCredentialDataError: null,
      insuranceSaveFail: false,
    };
  },
  [actions.GET_CLINICIAN_CREDENTIAL_DATA_LOADING](state) {
    return {
      ...state,
      clinicianCredentialDataLoading: true,
      clinicianCredentialDataError: null,
    };
  },
  [actions.GET_CLINICIAN_CREDENTIAL_DATA_ERROR](state, { payload }) {
    return {
      ...state,
      clinicianCredentialDataError: payload,
      clinicianCredentialDataLoading: false,
    };
  },
  [actions.SET_SUCCESSFUL_CALENDAR_SYNC](state) {
    return {
      ...state,
      calendarSyncLoading: true,
      calendarSyncSuccess: false,
    };
  },
  [actions.SET_SUCCESSFUL_CALENDAR_SYNC_SUCCESS](state, { payload }) {
    return {
      ...state,
      calendarSyncLoading: false,
      calendarSyncSuccess: payload.success,
    };
  },
  [actions.SET_SUCCESSFUL_CALENDAR_SYNC_FAIL](state) {
    return {
      ...state,
      calendarSyncLoading: false,
    };
  },
};

export default createReducer(initialState, reducers);
