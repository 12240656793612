export const PERMISSIONS = {
  adminHome: {
    permission: "view_admin_dashboard",
    showInMenu: false,
    pages: {
      dashboard: {
        permission: "view_admin_dashboard",
        url: "/dashboard",
        title: "Home",
      },
    },
  },
  clinicianHome: {
    permission: "view_clinician_dashboard",
    showInMenu: false,
    pages: {
      dashboard: {
        permission: "view_clinician_dashboard",
        url: "/dashboard",
        title: "Home",
      },
    },
  },
  caregiverHome: {
    permission: "view_client_dashboard",
    showInMenu: false,
    pages: {
      dashboard: {
        permission: "view_client_dashboard",
        url: "/dashboard",
        title: "Home",
      },
    },
  },
  clinicianClients: {
    permission: "view_my_clients",
    showInMenu: true,
    pages: {
      myClients: {
        permission: "view_my_clients",
        url: "/my-clients",
        title: "My Clients",
        showInMenu: true,
      },
    },
  },
  billingItems: {
    permission: "view_billing_items",
    showInMenu: true,
    pages: {
      billingItems: {
        permission: "view_billing_items",
        url: "/billing",
        title: "Billing",
        showInMenu: true,
      },
    },
  },
  caregiverSessions: {
    permission: "view_client_dashboard",
    showInMenu: true,
    pages: {
      sessions: {
        permission: "view_client_dashboard",
        url: "/sessions",
        title: "Sessions",
        showInMenu: true,
      },
    },
  },
  chat: {
    permission: "chat",
    showInMenu: true,
    pages: {
      chat: {
        permission: "chat",
        url: "/chat",
        title: "Chat",
        showInMenu: true,
      },
    },
    title: "Chat",
  },
  clinicianSchedule: {
    permission: "view_schedule_page",
    showInMenu: true,
    pages: {
      schedule: {
        permission: "view_schedule_page",
        url: "/clinician-schedule",
        title: "Schedule",
        showInMenu: true,
      },
    },
  },
  video: {
    permission: "join_video_call",
    showInMenu: false,
    pages: {
      videoChat: {
        permission: "join_video_call",
        url: "/video",
        title: "Video Chat",
        showInMenu: false,
      },
    },
  },
  entities: {
    permission: "view_entities",
    title: "Entities",
    showInMenu: true,
    pages: {
      chats: {
        permission: "view_chat_log_with_clinician",
        url: "/chats",
        title: "Chats",
        showInMenu: true,
      },
      customers: {
        permission: "view_client_list_by_workflow_state",
        url: "/clients",
        title: "Customers",
        showInMenu: true,
      },
      savedCustomers: {
        permission: "view_client_list_by_workflow_state",
        url: "/saved-customers",
        title: "Client Intake/ Leads",
        showInMenu: true,
      },
      clinicians: {
        permission: "view_clinician_list",
        url: "/clinicians",
        title: "Clinicians",
        showInMenu: true,
      },
      customerdetail: {
        permission: "view_client_list_by_workflow_state",
        url: "/clients/:id/dashboard",
        title: "Customer Detail",
        showInMenu: false,
      },
      customerview: {
        permission: "view_my_clients",
        url: "/clients/:id/dashboard",
        title: "Customer Detail",
        showInMenu: false,
      },
      cliniciandetail: {
        permission: "view_clinician_list",
        url: "/clinicians/:id",
        title: "Clinician Detail",
        showInMenu: false,
      },
      // notifications: {
      //     role: ["Admins"],
      //     url: "/notifications",
      //     title: "Push Notifications",
      //     showInMenu: true,
      // },
      upcomingVideoCalls: {
        permission: "view_video_call_details",
        url: "/upcoming-video-calls",
        title: "Upcoming Video Calls",
        showInMenu: true,
      },
      insurances: {
        permission: "view_insurance_utility",
        url: "/insurance",
        title: "Insurances",
        showInMenu: true,
      },
    },
  },
  administration: {
    permission: "invite_admin_users",
    title: "Administration",
    showInMenu: true,
    pages: {
      users: {
        permission: "invite_admin_users",
        url: "/users",
        title: "User Management",
        showInMenu: true,
      },
    },
  },
  account: {
    permission: "view_clinician_dashboard",
    title: "Account",
    showInMenu: false,
    pages: {
      profile: {
        permission: "view_clinician_dashboard",
        url: "/profile",
        title: "Profile",
      },
      "payroll-history": {
        permission: "view_payroll_report",
        url: "/payroll-history",
        title: "Payroll History",
        showInMenu: false,
      },
    },
  },
  // library: {
  //     role: ["Caregivers"],
  //     showInMenu: true,
  //     pages: {
  //         dashboard: {
  //             role: ["Caregivers"],
  //             url: "/library",
  //             title: "Library",
  //             showInMenu: true,
  //         },
  //     },
  //     title: "Library",
  // },
  profile: {
    permission: "view_client_dashboard",
    showInMenu: true,
    pages: {
      profile: {
        permission: "view_client_dashboard",
        url: "/account",
        title: "Account",
        showInMenu: true,
      },
    },
    title: "Profile",
  },
};
/*

    home:
        admin-dashboard
        client-dashboard

    chat

    videoCalls

    reports:
        payroll
        offPlatformBilling
        historicalSummary
        videoCallSummary
        messageTags?
        assessmentSummary?

    entities:
        chats
        customers
        clinicians
        tips
        notifications
        videoCalls
        diagnosis?
        behaviors?
        specialties?
        groupchats?
        assessments?
        organizations?

    user management

    account

*/
