import { InputAdornment, TextField, Typography } from "@mui/material";
import React from "react";
import MenuItem from "@mui/material/MenuItem";
import { COVERAGE_STATES, STATES } from "../../../constants";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export const InsurancePlanForm = (data, providers, errors, onChange, formatPhoneNumber) => {
  return {
    tabs: [
      { link: 0, label: "Plan Details" },
      { link: 1, label: "Insurance Rates" },
      { link: 2, label: "Modifiers" },
    ],
    form: [
      {
        id: "insurance_id",
        tab: 0,
        input: (
          <TextField
            label="Insurance Company"
            onChange={onChange("insurance_id", null, data)}
            value={providers?.length === 1 ? providers[0].id : data.insurance_id ?? ""}
            select
            fullWidth
            disabled={providers?.length === 1}
          >
            <MenuItem disabled value="">
              Select select
            </MenuItem>
            {providers?.map(({ name, id, isMedicaid }, index) => (
              <MenuItem key={`${index}`} name={name} value={id} data-medic={isMedicaid}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        ),
        cols: 12,
      },
      {
        id: "name",
        tab: 0,
        input: (
          <TextField
            id="name"
            label="Name of Plan"
            onChange={onChange("name", null, data)}
            value={data.name ?? ""}
            fullWidth
            inputProps={{ maxLength: 1000 }}
          />
        ),
        cols: 12,
      },
      {
        id: "coverage_state",
        tab: 0,
        input: (
          <TextField
            label="Coverage State"
            onChange={onChange("coverage_state", null, data)}
            value={data.coverage_state ?? ""}
            select
            fullWidth
          >
            <MenuItem disabled value="">
              Select select
            </MenuItem>
            {Object.keys(COVERAGE_STATES).map((key) => {
              return (
                <MenuItem key={key} value={key}>
                  {COVERAGE_STATES[key]}
                </MenuItem>
              );
            })}
          </TextField>
        ),
        cols: 12,
      },
      {
        id: "is_medicaid",
        tab: 0,
        input: (
          <FormControlLabel
            control={
              <Checkbox checked={data.is_medicaid} onChange={onChange("is_medicaid", null, data)} />
            }
            label="Medicaid Plan"
          />
        ),
        cols: 12,
      },
      {
        id: "payer_id",
        tab: 0,
        input: (
          <TextField
            id="payer_id"
            label="Payor ID"
            onChange={onChange("payer_id", null, data)}
            value={data.payer_id ?? ""}
            fullWidth
            inputProps={{ maxLength: 32 }}
          />
        ),
        cols: 12,
      },
      {
        id: "phone",
        tab: 0,
        input: (
          <TextField
            label="Phone Number"
            id="phone"
            onChange={onChange("phone", "phoneNumberchange", data)}
            value={data.phone ?? ""}
            variant="outlined"
            size="small"
            fullWidth
            inputProps={{ maxLength: 14 }}
          />
        ),
      },
      {
        id: "address_1",
        tab: 0,
        input: (
          <TextField
            id="address_1"
            name="address_1"
            autoComplete="off"
            value={data.address_1 ?? ""}
            onChange={onChange("address_1", null, data)}
            variant="outlined"
            label="Address"
            fullWidth
            inputProps={{ maxLength: 1000 }}
          />
        ),
      },
      {
        id: "address_2",
        tab: 0,
        input: (
          <TextField
            id="address_2"
            label="Address 2"
            autoComplete="off"
            onChange={onChange("address_2", null, data)}
            value={data.address_2 ?? ""}
            inputProps={{ maxLength: 1000 }}
            fullWidth
          />
        ),
      },
      {
        id: "state",
        tab: 0,
        input: (
          <TextField
            id="state"
            select
            label="State"
            onChange={onChange("state", null, data)}
            value={data.state ?? ""}
            fullWidth
            autoComplete="off"
          >
            <MenuItem disabled value={""}>
              Select a state
            </MenuItem>
            {Object.keys(STATES).map((key) => {
              return (
                <MenuItem key={key} value={key}>
                  {STATES[key]}
                </MenuItem>
              );
            })}
          </TextField>
        ),
      },
      {
        id: "city",
        tab: 0,
        input: (
          <TextField
            id="city"
            label="City"
            onChange={onChange("city", null, data)}
            value={data.city ?? ""}
            fullWidth
            inputProps={{ maxLength: 255 }}
          />
        ),
        cols: 12,
      },
      {
        id: "zip",
        tab: 0,
        input: (
          <TextField
            id="zip"
            label="ZIP Code"
            onChange={onChange("zip", "zipCode", data)}
            value={data.zip ?? ""}
            fullWidth
            inputProps={{ maxLength: 5 }}
            helperText={
              errors["zip"]
                ? "Please enter ZIP Code"
                : errors["zip_format_error"]
                  ? "Invalid ZIP Code"
                  : ""
            }
          />
        ),
        cols: 12,
      },
      {
        id: "assessment_rate",
        tab: 1,
        title: "Initial Assessment Insurance Rate ($)",
        input: (
          <TextField
            id="assessment_rate"
            name="assessment_rate"
            autoComplete="off"
            value={data.assessment_rate ?? ""}
            onChange={onChange("assessment_rate", null, data)}
            type="number"
            InputProps={{
              inputProps: { min: 0 },
              startAdornment: (
                <InputAdornment style={{ marginRight: "1px" }} position="start">
                  $
                </InputAdornment>
              ),
            }}
          />
        ),
        cols: 12,
      },
      {
        id: "direct_therapy_rate",
        tab: 1,
        title: "Direct Therapy Insurance Rate ($)",
        input: (
          <TextField
            id="direct_therapy_rate"
            name="direct_therapy_rate"
            autoComplete="off"
            value={data.direct_therapy_rate ?? ""}
            onChange={onChange("direct_therapy_rate", null, data)}
            type="number"
            InputProps={{
              inputProps: { min: 0 },
              startAdornment: (
                <InputAdornment style={{ marginRight: "1px" }} position="start">
                  $
                </InputAdornment>
              ),
            }}
          />
        ),
        cols: 12,
      },
      {
        id: "protocol_modification_rate",
        tab: 1,
        title: "Protocol Modification Insurance Rate ($)",
        input: (
          <TextField
            id="protocol_modification_rate"
            name="protocol_modification_rate"
            autoComplete="off"
            value={data.protocol_modification_rate ?? ""}
            onChange={onChange("protocol_modification_rate", null, data)}
            type="number"
            InputProps={{
              inputProps: { min: 0 },
              startAdornment: (
                <InputAdornment style={{ marginRight: "1px" }} position="start">
                  $
                </InputAdornment>
              ),
            }}
          />
        ),
        cols: 12,
      },
      {
        id: "caregiver_training_rate",
        tab: 1,
        title: "Caregiver Training Insurance Rate ($)",
        input: (
          <TextField
            id="caregiver_training_rate"
            name="caregiver_training_rate"
            autoComplete="off"
            value={data.caregiver_training_rate ?? ""}
            onChange={onChange("caregiver_training_rate", null, data)}
            type="number"
            InputProps={{
              inputProps: { min: 0 },
              startAdornment: (
                <InputAdornment style={{ marginRight: "1px" }} position="start">
                  $
                </InputAdornment>
              ),
            }}
          />
        ),
        cols: 12,
      },
      {
        id: "location_modifier",
        tab: 2,
        input: (
          <TextField
            label="Location Modifier"
            onChange={onChange("location_modifier", null, data)}
            value={data.location_modifier ?? ""}
            select
            fullWidth
            disabled={providers?.length === 1}
          >
            <MenuItem disabled value="">
              Select select
            </MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="11">11</MenuItem>
            <MenuItem value="12">12</MenuItem>
          </TextField>
        ),
        cols: 12,
      },
      {
        id: "provider_modifier",
        tab: 2,
        input: (
          <TextField
            label="Provider Modifier"
            onChange={onChange("provider_modifier", null, data)}
            value={data.provider_modifier ?? ""}
            select
            fullWidth
            disabled={providers?.length === 1}
          >
            <MenuItem disabled value="">
              Select select
            </MenuItem>
            <MenuItem value="HO">HO</MenuItem>
          </TextField>
        ),
        cols: 12,
      },
      {
        id: "telehealth_modifier",
        tab: 2,
        input: (
          <TextField
            label="Telehealth Modifier"
            onChange={onChange("telehealth_modifier", null, data)}
            value={data.telehealth_modifier ?? ""}
            select
            fullWidth
            disabled={providers?.length === 1}
          >
            <MenuItem disabled value="">
              Select select
            </MenuItem>
            <MenuItem value="GT">GT</MenuItem>
            <MenuItem value="95">95</MenuItem>
          </TextField>
        ),
        cols: 12,
      },
    ],
  };
};
