import React from "react";
import withStyles from "@mui/styles/withStyles";
import styles from "../styles";
import Typography from "@mui/material/Typography";
import EditFamilyMember from "./EditFamilyMember";
import SectionBox from "../../../elements/SectionBox";
import ANFormView from "../../../elements/Forms/ANFormView";
import familyAndContactsConfig from "./FamilyAndContactsConfig";

const FamilyAndContacts = (props) => {
  const { classes, notificationContacts, editMode } = props;

  return (
    <SectionBox
      title="Family & Contacts"
      description="Family details including relationship status, and contact information"
      columnDirection
    >
      {notificationContacts && notificationContacts.length > 0 ? (
        notificationContacts.map((contact) =>
          editMode && contact.is_active ? (
            <React.Fragment key={`edit-${contact.id}`}>
              <EditFamilyMember {...props} contact={contact} />
            </React.Fragment>
          ) : (
            <ANFormView
              key={`view-${contact.id}`}
              data={contact}
              config={familyAndContactsConfig}
            />
          )
        )
      ) : (
        <Typography className={classes.notFound}>No contact info found for this client</Typography>
      )}
    </SectionBox>
  );
};
export default withStyles(styles)(FamilyAndContacts);
