import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { ClientDetailsFormStates, useClientDetailsForm } from "./ClientDetailsFormContext";
import { Box } from "@mui/material";

const ClientDetailsPageContentsButtons = (props) => {
  const {
    updatingClientInfo,
    onCloseEditMode,
    submitButtonDisabled,
    onSubmitGlobalEdit,
    resetTab,
    currentTab,
  } = props;

  const { onSubmit, loading, formState, resetState } = useClientDetailsForm();

  useEffect(() => {
    if (formState === ClientDetailsFormStates.submitSuccess) {
      resetTab();
      resetState();
    }
  }, [formState]);

  const handleSubmit = () => {
    if (currentTab === "insurance") {
      onSubmit();
    } else {
      onSubmitGlobalEdit();
    }
  };

  const handleCancel = () => {
    onCloseEditMode(true);
  };

  return (
    <Box display="flex" justifyContent="center">
      <Box width={"90%"} maxWidth={"1200px"}>
        <Box display={"flex"} flexDirection={"row"}>
          <Button
            color="secondary"
            size="medium"
            variant="contained"
            onClick={handleCancel}
            style={{ margin: "10px 0" }}
          >
            Cancel
          </Button>
          <LoadingButton
            size="medium"
            loading={updatingClientInfo || loading}
            disabled={submitButtonDisabled || loading}
            onClick={handleSubmit}
            style={{ margin: "10px 20px" }}
          >
            Save Changes
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ClientDetailsPageContentsButtons;
