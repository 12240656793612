import React from "react";
import Typography from "@mui/material/Typography";
import SectionContainer from "elements/SectionContainer";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector } from "react-redux";
import { useGetFeatureFlag } from "hooks/useGetFeatureFlag";
import * as selectors from "selectors";
import { useHistory } from "react-router-dom";

const ClinicianQuickActions = (props) => {
  const {
    clinicianWorkflowStatus,
    userPermissions,
    classes,
    clientStatusInfo,
    toggleStartDemoCallDialog,
    navigate,
    openScheduleCallDialog,
    onToggleAssessmentComplete,
    onOpenTreatmentPlanDialog,
    clinicianUserId,
    onOpenUpdateTreatmentPlan,
    showAssessmentCompleteNotification,
    selectedClientUserId,
    treatmentPlanUploading,
    customerDetails,
  } = props;

  let isPrimaryClinician = (clientClinicianUserId) =>
    clinicianUserId == clientClinicianUserId ? true : false;

  const mapClinicianStatusForNotification = () => {
    const { classes } = props;

    if (clinicianWorkflowStatus === "CAQH" || clinicianWorkflowStatus === "CREDENTIALING") {
      return {
        title: "Welcome to Answersnow",
        subtitle: "Watch for tasks in the future.",
        content:
          "Once the credentialing process is complete and you've been assigned a client, actions that must be completed will appear in this space so be sure keep a look out. For now, practice a demo call and take a look around the portal.",
        buttons: [
          userPermissions?.start_video_call && (
            <DemoCallButton
              variant="outlined"
              toggleStartDemoCallDialog={toggleStartDemoCallDialog}
              key={0}
              classes={classes}
            />
          ),
        ],
        optional: true,
      };
    } else if (clinicianWorkflowStatus === "CREDENTIALED" && clientStatusInfo.length == 0) {
      return {
        title: "You're all caught up!",
        subtitle: "Watch for tasks in the future.",
        content:
          "Actions that you need to complete will appear in this space so be sure keep a look out. For now, practice a demo call and take a look around the portal. If you run into any problems before your session, contact your clinical director.",
        buttons: [
          userPermissions?.start_video_call && (
            <DemoCallButton
              variant="outlined"
              toggleStartDemoCallDialog={toggleStartDemoCallDialog}
              key={1}
              classes={classes}
            />
          ),
        ],
        optional: true,
      };
    }
  };

  const mapClinicianClientStatusForNotification = (client) => {
    const { classes } = props;

    if (
      (client.workflow_status == "ELIGIBLE" || client.workflow_status == "ELIGIBLE_NOW") &&
      isPrimaryClinician(client.clinician_user_id)
    ) {
      if (client.billing_type != "ASSESSMENT") {
        return {
          title: "Schedule a call with the client",
          subtitle: (
            <>
              You've been assigned to be{" "}
              <span
                onClick={() => navigate(`/clients/${client.client_id}/dashboard`)}
                className={classes.clientNameLink}
              >
                {client.name}
              </span>
              's clinician!
            </>
          ),
          content:
            "Now you need to schedule an assessment call. If you need to coordinate with the client to find a time that works for you both you can do that as well.",
          buttons: [
            userPermissions?.schedule_call && (
              <ScheduleCallButton
                key={4}
                openScheduleCallDialog={() => openScheduleCallDialog(client)}
                classes={classes}
                clientId={client.client_id}
                clientName={`${client.name} ${client.child_last_name}`}
              />
            ),
            userPermissions?.chat && (
              <Button
                size="large"
                style={{ marginLeft: 50 }}
                key={5}
                onClick={() => navigate(`/chat/${client.active_conversation_url}`)}
              >
                Chat with {client.name}
              </Button>
            ),
          ],
          optional: false,
        };
      }
    } else if (
      (client.workflow_status == "ASSESSMENT_SCHEDULED" ||
        client.workflow_status == "ASSESSMENT_SCHEDULED_NOW") &&
      isPrimaryClinician(client.clinician_user_id)
    ) {
      if (client.billing_type == "ASSESSMENT" && !client.end_date) {
        return {
          title: (
            <>
              <span style={{ color: "#2a2d39" }}>You've been assigned a new client</span>
            </>
          ),
          subtitle: (
            <>
              Introduce yourself to{" "}
              <span
                onClick={() => navigate(`/clients/${client.client_id}/dashboard`)}
                className={classes.clientNameLink}
              >
                {client.name}
              </span>{" "}
              .
            </>
          ),
          content:
            "Learn about them before your first session by going to their client details page. It’ll also help them feel more comfortable if you say a few words in chat before you meet for the first time.",
          buttons: [
            userPermissions?.chat && (
              <Button key={5} onClick={() => navigate(`/chat/${client.active_conversation_url}`)}>
                Chat with {client.name}
              </Button>
            ),
            userPermissions?.view_my_clients && (
              <Button
                variant="text"
                key={3}
                onClick={() => navigate(`/clients/${client.client_id}/dashboard`)}
                style={{ marginLeft: 50 }}
              >
                Client Details
              </Button>
            ),
          ],
          optional: false,
        };
      }
      if (
        client.billing_type == "ASSESSMENT" &&
        client.end_date &&
        isPrimaryClinician(client.clinician_user_id)
      ) {
        return {
          title: "Assessment needs approval",
          subtitle: (
            <>
              Mark assessment complete or continue assessment for{" "}
              <span
                onClick={() => navigate(`/clients/${client.client_id}/dashboard`)}
                className={classes.clientNameLink}
              >
                {client.name}
              </span>
              .
            </>
          ),
          content:
            "If you've completed the assessment, mark it complete now. If the assessment needs to be continued before it is complete, schedule another call.",
          buttons: [
            userPermissions?.mark_assessment_complete && (
              <Button key={6} onClick={() => onToggleAssessmentComplete(client)}>
                ASSESSMENT COMPLETE
              </Button>
            ),
            userPermissions?.schedule_call && (
              <Button
                color="secondary"
                style={{ marginLeft: 50 }}
                key={7}
                onClick={() => openScheduleCallDialog(client)}
              >
                Schedule a Call
              </Button>
            ),
          ],
          optional: false,
        };
      }
    } else if (
      client.workflow_status == "ASSESSMENT_COMPLETED" &&
      isPrimaryClinician(client.clinician_user_id) &&
      !client.treatment_plan_id
    ) {
      return {
        optionalContent: {
          title: "Assessment Approved",
          subtitle: (
            <>
              <CheckIcon className={classes.successIcon} /> Assessment marked complete for{" "}
              <span
                onClick={() => navigate(`/clients/${client.client_id}/dashboard`)}
                className={classes.clientNameLink}
              >
                {client.name}
              </span>
              .
            </>
          ),
          content:
            "You've marked the assessment complete. Now you need to upload your treatment plan. You can do so in the area down below.",
        },
        showOptionalContent:
          client.user_id == selectedClientUserId && showAssessmentCompleteNotification,
        title: "Treatment Plan Needs to be uploaded",
        subtitle: (
          <>
            {" "}
            Upload treatment plan for{" "}
            <span
              onClick={() => navigate(`/clients/${client.client_id}/dashboard`)}
              className={classes.clientNameLink}
            >
              {client.name}
            </span>
            .
          </>
        ),
        content:
          "Now that you've marked the assessment complete, you need to upload the treatment plan. You must do this for the process to continue.",
        buttons: [
          userPermissions?.upload_treatment_plan && (
            <LoadingButton
              key={8}
              onClick={() => onOpenTreatmentPlanDialog(client.client_id)}
              loading={
                customerDetails?.details?.client_id == client.client_id && treatmentPlanUploading
              }
            >
              upload plan
            </LoadingButton>
          ),
        ],
        optional: false,
      };
    } else if (
      client.workflow_status == "APPROVE_PLAN" &&
      isPrimaryClinician(client.clinician_user_id)
    ) {
      return {
        title: "Waiting on the client",
        subtitle: (
          <>
            <span
              onClick={() => navigate(`/clients/${client.client_id}/dashboard`)}
              className={classes.clientNameLink}
            >
              {client.name}
            </span>{" "}
            needs to approve the plan.
          </>
        ),
        content:
          "You’ve uploaded the treatment plan and now we’re waiting for the client to approve it before continuing. You can update the plan if needed at this time. You can also reach out to the client through the chat to see if they have questions at this time.",
        buttons: [
          userPermissions?.upload_treatment_plan && (
            <LoadingButton
              variant="text"
              key={9}
              onClick={() => onOpenUpdateTreatmentPlan(client.client_id)}
              size="large"
              loading={
                customerDetails?.details?.client_id == client.client_id && treatmentPlanUploading
              }
            >
              Update Plan
            </LoadingButton>
          ),
          userPermissions?.chat && (
            <Button
              color="secondary"
              style={{ marginLeft: 50 }}
              key={5}
              onClick={() => navigate(`/chat/${client.active_conversation_url}`)}
            >
              Chat with {client.name}
            </Button>
          ),
        ],
        optional: true,
      };
    } else if (
      client.workflow_status == "AWAITING_INSURANCE_APPROVAL" &&
      isPrimaryClinician(client.clinician_user_id)
    ) {
      return {
        title: "Waiting on the Insurance Company",
        subtitle: (
          <>
            <span
              onClick={() => navigate(`/clients/${client.client_id}/dashboard`)}
              className={classes.clientNameLink}
            >
              {client.name}
            </span>
            's insurance needs to approve the plan.
          </>
        ),
        content:
          "The treatment plan has been uploaded and approved by the client and now the insurance company is looking through it. Come back here after the approval process for next steps.",
        buttons: [],
        optional: true,
      };
    } else if (
      client.workflow_status == "INSURANCE_APPROVED" &&
      isPrimaryClinician(client.clinician_user_id)
    ) {
      if (
        client.billing_type != "DIRECT_THERAPY" ||
        client.billing_type != "Direct Therapy" ||
        client.billing_type != "CAREGIVER_TRAINING" ||
        client.billing_type != "Caregiver Training"
      ) {
        return {
          title: "Schedule a call with the client",
          subtitle: (
            <>
              Treatment Plan has been approved for{" "}
              <span
                onClick={() => navigate(`/clients/${client.client_id}/dashboard`)}
                className={classes.clientNameLink}
              >
                {client.name}
              </span>
              .
            </>
          ),
          content:
            "All that's left is for you to coordinate with the client and get a session scheduled. If you are confused and need help, contact your clinical supervisor.",
          buttons: [
            userPermissions?.schedule_call && (
              <ScheduleCallButton
                key={10}
                openScheduleCallDialog={() => openScheduleCallDialog(client)}
                classes={classes}
                clientId={client.client_id}
                clientName={`${client.name} ${client.child_last_name}`}
              />
            ),
            userPermissions?.chat && (
              <Button
                color="secondary"
                style={{ marginLeft: 50 }}
                key={11}
                onClick={() => navigate(`/chat/${client.active_conversation_url}`)}
              >
                Chat with {client.name}
              </Button>
            ),
          ],
          optional: false,
        };
      } else {
        return {
          title: "You're good to go!",
          subtitle: (
            <>
              Watch for tasks for{" "}
              <span
                onClick={() => navigate(`/clients/${client.client_id}/dashboard`)}
                className={classes.clientNameLink}
              >
                {client.name}
              </span>{" "}
              in the future.{" "}
            </>
          ),
          content:
            "Tasks that you need to complete will appear in this space so be sure keep a look out.",
          optional: true,
        };
      }
    } else if (
      client.workflow_status == "FULL_CUSTOMER" &&
      isPrimaryClinician(client.clinician_user_id)
    ) {
      if (
        !client.end_date &&
        (client.billing_type == "DIRECT_THERAPY" ||
          client.billing_type == "Direct Therapy" ||
          client.billing_type == "CAREGIVER_TRAINING" ||
          client.billing_type == "Caregiver Training")
      ) {
        return {
          title: "You're good to go!",
          subtitle: (
            <>
              Watch for tasks for{" "}
              <span
                onClick={() => navigate(`/clients/${client.client_id}/dashboard`)}
                className={classes.clientNameLink}
              >
                {client.name}
              </span>{" "}
              in the future.
            </>
          ),
          content:
            "Tasks that you need to complete will appear in this space so be sure keep a look out.",
          optional: true,
        };
      } else {
        return {
          title: "Schedule a call with your client",
          subtitle: (
            <>
              Oops, the calendar is empty. When will you next see{" "}
              <span
                onClick={() => navigate(`/clients/${client.client_id}/dashboard`)}
                className={classes.clientNameLink}
              >
                {client.name}
              </span>{" "}
              ?
            </>
          ),
          content:
            "It looks like you don't have a call scheduled. It's okay if it changes later but let's put one on the calendar for now.",
          buttons: [
            userPermissions?.schedule_call && (
              <ScheduleCallButton
                key={12}
                openScheduleCallDialog={() => openScheduleCallDialog(client)}
                classes={classes}
                variant="outlined"
                clientId={client.client_id}
                clientName={`${client.name} ${client.child_last_name}`}
              />
            ),
          ],
          optional: false,
        };
      }
    } else {
      return null;
    }
  };

  const clinicianNotification = mapClinicianStatusForNotification();
  const clinicianClientNotification = clientStatusInfo.map((client) =>
    mapClinicianClientStatusForNotification(client)
  );
  return (
    <>
      {clinicianNotification && (
        <SectionContainer columnDirection>
          <Typography
            variant="h4"
            className={
              clinicianNotification.optional
                ? classes.optionalNotificationTitle
                : classes.notificationTitle
            }
          >
            {clinicianNotification?.title}
          </Typography>
          <Typography variant="h5" className={classes.notificationSubtitle}>
            {clinicianNotification?.subtitle}
          </Typography>
          <Typography variant="subtitle1" className={classes.notificationContent}>
            {clinicianNotification?.content}
          </Typography>
          <div className={classes.notificationButtonContainer}>
            {clinicianNotification?.buttons?.map((button) => button)}
          </div>
        </SectionContainer>
      )}
      {clinicianClientNotification.map((clientInfo, index) => (
        <React.Fragment key={index}>
          {clientInfo && (
            <SectionContainer columnDirection>
              {clientInfo.showOptionalContent && clientInfo.optionalContent && (
                <div style={{ marginBottom: 20 }}>
                  <Typography variant="h4" className={classes.optionalNotificationTitle}>
                    {clientInfo.optionalContent.title}
                  </Typography>
                  <Typography variant="h5" className={classes.notificationSubtitle}>
                    {clientInfo.optionalContent.subtitle}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.notificationContent}>
                    {clientInfo.optionalContent.content}
                  </Typography>
                </div>
              )}
              <Typography
                variant="h4"
                className={
                  clientInfo.optional
                    ? classes.optionalNotificationTitle
                    : classes.notificationTitle
                }
              >
                {clientInfo?.title}
              </Typography>
              <Typography variant="h5" className={classes.notificationSubtitle}>
                {clientInfo?.subtitle}
              </Typography>
              <Typography variant="subtitle1" className={classes.notificationContent}>
                {clientInfo?.content}
              </Typography>
              <div className={classes.notificationButtonContainer}>
                {clientInfo?.buttons?.map((button) => button)}
              </div>
            </SectionContainer>
          )}
        </React.Fragment>
      ))}
    </>
  );
};
export default ClinicianQuickActions;

const ScheduleCallButton = (props) => {
  const { onToggleScheduleClientVideo, clientId, clientName } = props;
  const history = useHistory();
  const userId = useSelector(selectors.getUserId);
  const { data: schedulePageFeatureFlag } = useGetFeatureFlag(userId, "scheduling_page");
  return (
    <Button
      variant="text"
      onClick={() => {
        if (schedulePageFeatureFlag)
          history.push(`/clinician-schedule/create-event?client=${clientId}&name=${clientName}`);
        else onToggleScheduleClientVideo(false);
      }}
      size="large"
    >
      Schedule Call
    </Button>
  );
};

const DemoCallButton = (props) => {
  const { toggleStartDemoCallDialog, classes } = props;
  return (
    <Button onClick={toggleStartDemoCallDialog} className={classes.scheduleCallButton}>
      Demo Call
    </Button>
  );
};
