import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import ANEditItem from "./ANEditItem";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, Divider } from "@mui/material";
import { ClientDetailsFormProvider } from "../../components/ClientDetails/ClientDetailsFormContext";

const AnFormEdit = ({ inputsForm, classes }) => {
  const keys = Object.keys(inputsForm);

  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      {keys[0] === "tabs" && (
        <Box
          sx={{
            backgroundColor: "#FFF",
            width: "100%",
            padding: "16px 0px 8px 0px",
          }}
        >
          <Tabs
            indicatorColor="secondary"
            textColor="primary"
            value={currentTab}
            onChange={handleTabChange}
            aria-label="client-details-tabs"
            variant="fullWidth"
          >
            {inputsForm.tabs.map((tab) => (
              <Tab key={tab.link} value={tab.link} label={tab.label} disableRipple />
            ))}
          </Tabs>
        </Box>
      )}
      <Grid container spacing={5} style={{ display: "contents", paddingTop: "8px" }}>
        {inputsForm && keys[0] === "tabs" && (
          <>
            {inputsForm.form.map(
              (item, index) =>
                !item.hide &&
                item.tab === currentTab && (
                  <ANEditItem
                    classes={classes}
                    key={index}
                    input={item.input}
                    title={item.title}
                    xs={12}
                    sm={item.cols}
                    panelMode={false}
                    divider={item.divider}
                  />
                )
            )}
          </>
        )}
        {inputsForm &&
          keys[0] !== "tabs" &&
          inputsForm.map(
            (item, index) =>
              !item.hide && (
                <ANEditItem
                  classes={classes}
                  key={index}
                  input={item.input}
                  title={item.title}
                  xs={12}
                  sm={item.cols}
                  panelMode={false}
                  divider={item.divider}
                />
              )
          )}
      </Grid>
    </>
  );
};

export default AnFormEdit;
