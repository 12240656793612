import React, { useEffect } from "react";
import { useFormik } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Grid,
  Box,
} from "@mui/material";
import { X } from "@phosphor-icons/react";
import LoadingButton from "@mui/lab/LoadingButton";

import ANUploader from "elements/ANUploader";
import ANDatePicker from "elements/ANDatePicker";
import { certificationValidationSchema } from "./validationSchema";
import { getDate } from "./utils";
import { isAfter, isBefore, isEqual } from "date-fns";
/** @typedef {import("api/typesDef").Certification} Certification  */

/**
 * The `CertificationDialog` function is a React component that displays a dialog for adding or editing
 * Certifications.
 * @component
 * @param {Object} props - The props of the component.
 * @param {Boolean} props.open - The open state of the dialog.
 * @param {Certification} props.currentCertification - The current selected certification object, for editing.
 * @param {boolean} props.isNew - The boolean to determine if the certification is new.
 * @param {Function} props.handleClose - The function to close the dialog.
 * @param {Function} props.onSubmit - The function to submit the form data to the server.
 * @return {JSX.Element} The `<CertificationDialog />` component.
 */
export default function CertificationDialog({
  open,
  currentCertification,
  isNew,
  handleClose,
  onSubmit,
  certifications,
}) {
  const initialValues = {
    certificationNumber: isNew ? "" : currentCertification?.certification_number || "",
    issuedOn: isNew ? null : getDate(currentCertification?.issued_on),
    expiryDate: isNew ? null : getDate(currentCertification?.expiry_date),
    certificationFile:
      isNew || !currentCertification?.image_name
        ? []
        : [
            {
              alreadyUploaded: true,
              errors: false,
              file: {
                name: currentCertification.display_filename || currentCertification.image_name,
                size: currentCertification.file_size,
              },
            },
          ],
  };

  const handleOverlapping = (values) => {
    const errors = {};

    const toDate = (dateStr) => new Date(dateStr);

    const isOverlappingIssuedOn = (newIssuedOn, certIssuedOn, certExpiryDate) => {
      return (
        (isAfter(newIssuedOn, certIssuedOn) || isEqual(newIssuedOn, certIssuedOn)) &&
        (isBefore(newIssuedOn, certExpiryDate) || isEqual(newIssuedOn, certExpiryDate))
      );
    };

    const isOverlappingExpiryDate = (newExpiryDate, certIssuedOn, certExpiryDate) => {
      return (
        (isAfter(newExpiryDate, certIssuedOn) || isEqual(newExpiryDate, certIssuedOn)) &&
        (isBefore(newExpiryDate, certExpiryDate) || isEqual(newExpiryDate, certExpiryDate))
      );
    };

    const isEnclosing = (newIssuedOn, newExpiryDate, certIssuedOn, certExpiryDate) => {
      return (
        (isBefore(newIssuedOn, certIssuedOn) || isEqual(newIssuedOn, certIssuedOn)) &&
        (isAfter(newExpiryDate, certExpiryDate) || isEqual(newExpiryDate, certExpiryDate))
      );
    };

    certifications?.forEach(({ pkey, certification_number, issued_on, expiry_date }) => {
      if(currentCertification?.pkey === pkey) {
        return;
      }
      if (certification_number === values.certificationNumber) {
        const newIssueOn = toDate(values.issuedOn);
        const newExpiryDate = toDate(values.expiryDate);
        const certIssueOn = toDate(issued_on);
        const certExpiryDate = toDate(expiry_date);

        if (isOverlappingIssuedOn(newIssueOn, certIssueOn, certExpiryDate)) {
          errors.issuedOn = 'This date overlaps with an existing certification of the same ID number';
        }

        if (isOverlappingExpiryDate(newExpiryDate, certIssueOn, certExpiryDate) || isEnclosing(newIssueOn, newExpiryDate, certIssueOn, certExpiryDate)) {
          errors.expiryDate = 'This date overlaps with an existing certification of the same ID number ';
        }
      }
    });
    return errors;
  };

  // Formik Configuration
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    touched,
    values,
    setFieldValue,
    dirty,
    isSubmitting,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: certificationValidationSchema,
    validate: (values) => {
      return handleOverlapping(values);
    },
    onSubmit: (values) => {
      onSubmit(values).then(() => {
        resetForm();
      });
    },
  });

  const handleFileUpload = (file) => {
    setFieldValue("certificationFile", [
      {
        errors: !!file.fileRejections?.[0],
        file: file.acceptedFiles[0] || file.fileRejections[0],
      },
    ]);
  };

  const internalHandleClose = () => {
    if (!isSubmitting) {
      resetForm();
      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={internalHandleClose} maxWidth="sm">
      <DialogTitle>
        {isNew ? "Add" : "Edit"} Certification
        <IconButton aria-label="close" onClick={internalHandleClose} disabled={isSubmitting}>
          <X />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="BCBA Certification Number"
                name="certificationNumber"
                placeholder="0000001"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.certificationNumber}
                error={touched.certificationNumber && !!errors.certificationNumber}
                helperText={touched.certificationNumber && errors.certificationNumber}
              />
            </Grid>
          </Grid>

          <Grid container columnSpacing={{ sm: 2, md: 5 }} columns={{ sm: 4, lg: 8 }}>
            <Grid item sm={2} md={4} marginTop={"24px"}>
              <Box display={"flex"} width={"100%"} flexDirection={"column"}>
                <ANDatePicker
                  label="Issued Date"
                  format="MM/dd/yyyy"
                  disableFuture
                  style={{ fontSize: 14 }}
                  name="issuedOn"
                  value={values.issuedOn}
                  onChange={(value) => setFieldValue("issuedOn", value, true)}
                  onBlur={handleBlur}
                  slotProps={{
                    textField: {
                      placeholder: "01/01/2023",
                      error: touched.issuedOn && !!errors.issuedOn,
                      helperText: touched.issuedOn && errors.issuedOn,
                    },
                    popper: { disablePortal: false },
                  }}
                />
              </Box>
            </Grid>

            <Grid item sm={2} md={4} marginTop={"24px"}>
              <Box display={"flex"} width={"100%"} flexDirection={"column"}>
                <ANDatePicker
                  label="Expiration Date"
                  format="MM/dd/yyyy"
                  disablePast={isNew ? true : false}
                  style={{ fontSize: 14 }}
                  name="expiryDate"
                  value={values.expiryDate}
                  onChange={(value) => setFieldValue("expiryDate", value, true)}
                  onBlur={handleBlur}
                  slotProps={{
                    textField: {
                      placeholder: "01/01/2024",
                      error: touched.expiryDate && !!errors.expiryDate,
                      helperText: touched.expiryDate && errors.expiryDate,
                    },
                    popper: { disablePortal: false },
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <ANUploader
            handleFileUpload={handleFileUpload}
            dropzoneFiles={values.certificationFile}
            useDropzoneOptions={{ multiple: false }}
            handleFileDelete={() => {
              setFieldValue("certificationFile", []);
            }}
            handleLoadedFileDelete={() => {
              setFieldValue("certificationFile", []);
            }}
            error={touched.certificationFile && !!errors.certificationFile}
            errorText={errors.certificationFile}
            dropZoneStyle={{ margin: "24px 0 0" }}
            filePreviewStyle={{ margin: "24px 0 0" }}
            hideDropzone={values.certificationFile.length > 0}
            removeFileButtonProps={{ disabled: isSubmitting }}
          />
        </DialogContent>

        <DialogActions>
          <Button color="secondary" onClick={internalHandleClose} disabled={isSubmitting}>
            Cancel
          </Button>

          <LoadingButton type="submit" loading={isSubmitting} disabled={!dirty}>
            {isNew ? "Add Certification" : "Save Changes"}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
