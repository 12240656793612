import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { Auth } from "@aws-amplify/auth";
import { getLogin } from "selectors";
import actions from "actions";
import { LoadingScreen } from "components/EventScheduling/EventSchedulingUtils";
import { apiGet } from "utils/securerequest";
import { Dialog, Box } from "@mui/material";
import { useGlobalToast } from "components/GlobalToastProvider";
import ReactPlayer from "react-player";
import useVideoPlayerControls from "./useVideoPlayerControls";
import VideoPlayerControls from "./VideoPlayerControls";
import VideoPlayerHeader from "./VideoPlayerHeader";
import useMouseEvents from "./useMouseEvents";
import VideoPlayerErrorContent from "./VideoPlayerErrorContent";

const CLIENT_VIDEO_SESSION_BASE_URL = process.env.CLIENT_VIDEO_SESSION_BASE_URL;

const VideoPlayer = ({
  videoKey,
  videoId,
  videoPlayerTitle = "Video Player",
  asModal = false,
  modalControl,
}) => {
  const dispatch = useDispatch();
  const [url, setUrl] = useState(null);
  const [vId, setVid] = useState(videoId || 0);
  const [token, setToken] = useState();
  const login = useSelector((state) => getLogin(state));
  const { showToast } = useGlobalToast();
  const playerRef = useRef(null);
  const videoContainerRef = useRef(null);
  const fullscreenContainerRef = useRef(null);

  // Update vId on videoId change
  useEffect(() => {
    setVid(videoId || 0);
  }, [videoId]);

  // Fetch authentication token
  useEffect(() => {
    if (!login) return;

    const fetchAuthData = async () => {
      try {
        const session = await Auth.currentSession();
        const accessToken = session.getAccessToken();
        setToken(accessToken.getJwtToken());
        dispatch(actions.getUser());
      } catch (error) {
        console.error("Error fetching auth session:", error);
        showToast({
          message: "There was an issue with authentication",
          errorState: true,
        });
        if (modalControl) {
          modalControl.closeModal();
        }
      }
    };

    dispatch(actions.checkAuthState());
    fetchAuthData();
  }, [login]);

  const { data, refetch, error, isLoading } = useQuery(
    ["videoCallByKey", videoKey],
    () => apiGet(`/video/key/${videoKey}`),
    {
      enabled: !!videoKey && !!token && !videoId,
      retry: 3,
      onError: (error) => {
        console.error("Error fetching video call:", error);
        showToast({
          message: "There was an issue loading the video",
          errorState: true,
          retryHandler: retryHandler,
        });
      },
    }
  );

  // Retry handler for fetching data
  const retryHandler = () => {
    refetch();
  };

  // Update video ID when data changes
  useEffect(() => {
    if (data) {
      setVid(data.data.id);
    }
  }, [data]);

  // Update video URL when video ID or formattedResolution changes
  useEffect(() => {
    if (vId && formattedResolution) {
      setUrl(`${CLIENT_VIDEO_SESSION_BASE_URL}${vId}/playlist.m3u8?res=${formattedResolution}`);
    }
  }, [vId, formattedResolution, CLIENT_VIDEO_SESSION_BASE_URL]);

  // Handle errors in the ReactPlayer
  const handlePlayerError = (error, data) => {
    console.error("Error in ReactPlayer:", error);
    if (data?.fatal) {
      showToast({
        message: "There was an issue playing the video",
        errorState: true,
        retryHandler: retryHandler,
      });
    }
  };

  // Custom hook for video player controls
  const {
    playing,
    muted,
    volume,
    played,
    playbackRate,
    anchorElSpeed,
    anchorElQuality,
    anchorElVolume,
    quality,
    handlePlayPause,
    handleMute,
    handleVolumeChange,
    handleSeekChange,
    handleSpeedMenu,
    handleSpeedClose,
    handleQualityMenu,
    handleQualityClose,
    handleVolumeMenu,
    handleVolumeClose,
    handlePlaybackRateChange,
    handleQualityChange,
    toggleFullscreen,
    isFullscreen,
    controlsVisible,
    handleControlsVisibility,
    formattedResolution,
  } = useVideoPlayerControls(playerRef, videoContainerRef);

  // Custom hook for mouse events
  const { handleSingleClick } = useMouseEvents(
    videoContainerRef,
    handleControlsVisibility,
    toggleFullscreen,
    handlePlayPause
  );

  // Player content to render the video player
  const playerContent = (
    <Box
      ref={videoContainerRef}
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
      onMouseMove={() => handleControlsVisibility(true)}
    >
      {url ? (
        <Box sx={{ minHeight: "100%" }}>
          <Box onClick={handleSingleClick}>
            <ReactPlayer
              ref={playerRef}
              url={url}
              playing={playing}
              muted={muted}
              volume={volume}
              playbackRate={playbackRate}
              onError={handlePlayerError}
              controls={false} // Ensure built-in controls are disabled
              onProgress={({ played }) => handleSeekChange(null, played)}
              config={{
                file: {
                  attributes: {
                    crossOrigin: "anonymous",
                  },
                  hlsOptions: {
                    xhrSetup: (xhr) => {
                      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
                    },
                    fetchSetup: function (context, initParams) {
                      return new Request(context.url, {
                        ...initParams,
                        headers: {
                          ...(initParams.headers || {}),
                          Authorization: `Bearer ${token}`,
                        },
                      });
                    },
                    stretchShortVideoTrack: true,
                    forceKeyFrameOnDiscontinuity: false,
                    progressive: true,
                    startFragPrefetch: true,
                    lowLatencyMode: false,
                    maxBufferLength: 300,
                    maxBufferSize: 500,
                    maxBufferHole: 0.1,
                    maxStarvationDelay: 10,
                    maxLoadingDelay: 10,
                    manifestLoadPolicy: {
                      default: {
                        maxTimeToFirstByteMs: Infinity,
                        maxLoadTimeMs: 30000,
                        timeoutRetry: {
                          maxNumRetry: 2,
                          retryDelayMs: 0,
                          maxRetryDelayMs: 0,
                        },
                        errorRetry: {
                          maxNumRetry: 1,
                          retryDelayMs: 1000,
                          maxRetryDelayMs: 8000,
                        },
                      },
                    },
                    playlistLoadPolicy: {
                      default: {
                        maxTimeToFirstByteMs: 20000,
                        maxLoadTimeMs: 30000,
                        timeoutRetry: {
                          maxNumRetry: 2,
                          retryDelayMs: 0,
                          maxRetryDelayMs: 0,
                        },
                        errorRetry: {
                          maxNumRetry: 2,
                          retryDelayMs: 1000,
                          maxRetryDelayMs: 8000,
                        },
                      },
                    },
                    fragLoadPolicy: {
                      default: {
                        maxTimeToFirstByteMs: 20000,
                        maxLoadTimeMs: 320000,
                        timeoutRetry: {
                          maxNumRetry: 4,
                          retryDelayMs: 0,
                          maxRetryDelayMs: 0,
                        },
                        errorRetry: {
                          maxNumRetry: 6,
                          retryDelayMs: 1000,
                          maxRetryDelayMs: 8000,
                        },
                      },
                    },
                  },
                },
              }}
              width="100%"
              height="100%"
              style={{ pointerEvents: "none" }}
            />
          </Box>
          {isFullscreen && (
            <Box
              ref={fullscreenContainerRef}
              sx={{ position: "absolute", width: "100%", height: "100%" }}
            />
          )}
          {asModal && (
            <VideoPlayerHeader
              videoPlayerTitle={videoPlayerTitle}
              controlsVisible={controlsVisible}
              modalControl={modalControl}
            />
          )}
          <Box
            className="controls"
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "white",
              opacity: controlsVisible ? 1 : 0,
              transition: "opacity 0.3s",
              padding: 2,
              color: "#fff",
            }}
          >
            <VideoPlayerControls
              playing={playing}
              muted={muted}
              volume={volume}
              played={played}
              playbackRate={playbackRate}
              anchorElSpeed={anchorElSpeed}
              anchorElQuality={anchorElQuality}
              anchorElVolume={anchorElVolume}
              quality={quality}
              handlePlayPause={handlePlayPause}
              handleMute={handleMute}
              handleVolumeChange={handleVolumeChange}
              handleSeekChange={handleSeekChange}
              handleSpeedMenu={handleSpeedMenu}
              handleSpeedClose={handleSpeedClose}
              handleQualityMenu={handleQualityMenu}
              handleQualityClose={handleQualityClose}
              handleVolumeMenu={handleVolumeMenu}
              handleVolumeClose={handleVolumeClose}
              handlePlaybackRateChange={handlePlaybackRateChange}
              handleQualityChange={handleQualityChange}
              playerRef={playerRef}
              toggleFullscreen={toggleFullscreen}
              isFullscreen={isFullscreen}
              fullscreenContainer={fullscreenContainerRef.current}
            />
          </Box>
        </Box>
      ) : (
        isLoading && (
          <>
            <VideoPlayerHeader
              videoPlayerTitle={videoPlayerTitle}
              controlsVisible={controlsVisible}
              modalControl={modalControl}
            />
            <LoadingScreen />
          </>
        )
      )}
    </Box>
  );

  // Conditional rendering based on the modal state and loading/error states
  if (asModal && modalControl) {
    return (
      <Dialog
        open={modalControl?.isOpen}
        onClose={modalControl?.closeModal}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            padding: 0,
            borderRadius: "24px",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <>
            {error && (
              <VideoPlayerErrorContent
                asModal={asModal}
                modalControl={modalControl}
                retryHandler={retryHandler}
              />
            )}
            {!error && playerContent}
          </>
        )}
      </Dialog>
    );
  }

  return isLoading ? (
    <LoadingScreen />
  ) : error ? (
    <VideoPlayerErrorContent
      asModal={asModal}
      modalControl={modalControl}
      retryHandler={retryHandler}
    />
  ) : (
    playerContent
  );
};

export default VideoPlayer;
