const moment = require("moment-timezone");

export const mergeClientAvailability = (ranges, newTimezone = moment.tz.guess()) => {
  if (ranges?.length > 0) {
    const slotsSet = new Set();

    ranges.forEach((range) => {
      let current = moment.utc(range.start).tz(newTimezone);
      const end = moment.utc(range.end).tz(newTimezone);
      const preferred = range.preferred;

      while (current.isBefore(end)) {
        const next = moment(current).add(15, "minutes");
        const slotIdentifier = `${current.toISOString()}|${next.toISOString()}|${preferred}`;
        slotsSet.add(slotIdentifier);
        current = next;
      }
    });

    // Convert the set back to an array of slot objects
    const slotsArray = Array.from(slotsSet).map((slot) => {
      const [start, end, preferred] = slot.split("|");
      return {
        start,
        end,
        preferred: preferred === "true",
      };
    });

    // Sort the slots by start time in the new timezone
    slotsArray.sort((a, b) => moment(a.start).diff(moment(b.start)));

    return slotsArray;
  } else {
    return [];
  }
};
