import { getClientDocuments } from "api/customers";
import { useQuery } from "react-query";
import { getInsurancePlansAsync } from "../api/insurance";
import { GET_INSURANCE_PLAN } from "../constants/reactQueryKeys";

export const useGetInsurancePlansQuery = (state, insuranceid, params) => {
  const { page, size, sort, direction, planList, companyList, coverageList } = params;
  return useQuery({
    queryKey: [GET_INSURANCE_PLAN, insuranceid],
    queryFn: () =>
      getInsurancePlansAsync({
        state,
        insuranceid,
        page,
        size,
        sort,
        direction,
        planList,
        companyList,
        coverageList,
      }),
  });
};
