import { call, put, takeEvery } from "redux-saga/effects";
import actions from "../actions";
import { getUserList, createUser, getUserDeactivationReasons } from "../api/users";
import { registerCognitoUser } from "../api/register";

function* getUsersAsync() {
  try {
    const userList = yield call(getUserList);
    yield put(actions.setUserList(userList.data, parseInt(userList.data[0].count)));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    console.error(e);
  }
}

function* createUserAsync({ payload }) {
  try {
    const cognitoId = yield call(registerCognitoUser, payload, true);
    const createUserParams = { ...payload, cognitoId };
    yield call(createUser, createUserParams);
    yield put(actions.createUserSuccess());
    yield put(actions.getUserList());
  } catch (e) {
    yield put(actions.createUserFail(e));
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getUserDeactivationReasonsAsync() {
  try {
    const reasons = yield call(getUserDeactivationReasons);
    yield put(actions.getUserDeactivationReasonsSuccess(reasons.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

export default function* startSaga() {
  yield takeEvery(actions.GET_USER_LIST, getUsersAsync);
  yield takeEvery(actions.CREATE_USER, createUserAsync);
  yield takeEvery(actions.GET_USER_DEACTIVATION_REASONS, getUserDeactivationReasonsAsync);
}
