export const InsurancePlanConfig = [
  {
    id: "insurance_id",
    label: "Insurance Id",
    dataKey: "insurance_id",
    cols: 12,
    required: true,
    hide: true,
  },
  {
    id: "id",
    label: "Insurance Id",
    dataKey: "id",
    cols: 12,
    hide: true,
  },
  {
    id: "insurance_name",
    label: "Insurance Company",
    dataKey: "insurance_name",
    cols: 12,
  },
  {
    id: "contract_type",
    label: "Contract Type",
    dataKey: "contract_type",
    catalog: true,
    cols: 12,
    divider: true,
  },
  {
    id: "name",
    label: "Name of Plan",
    dataKey: "name",
    cols: 12,
    required: true,
  },
  {
    id: "coverage_state",
    label: "Coverage State",
    dataKey: "coverage_state",
    cols: 12,
    required: true,
  },
  {
    id: "is_medicaid",
    label: "Medicaid Plan",
    dataKey: "is_medicaid",
    cols: 12,
    type: "yes_no",
  },
  {
    id: "payer_id",
    label: "Payor ID",
    dataKey: "payer_id",
    cols: 12,
    required: true,
  },
  {
    id: "phone",
    label: "Phone Number",
    dataKey: "phone",
    cols: 12,
    required: true,
  },
  {
    id: "address_1",
    label: "Address",
    dataKey: "address_1",
    cols: 12,
    required: true,
  },
  {
    id: "address_2",
    label: "Address 2",
    dataKey: "address_2",
    cols: 12,
    required: false,
  },
  {
    id: "state",
    label: "State",
    dataKey: "state",
    cols: 12,
    required: true,
  },
  {
    id: "city",
    label: "City",
    dataKey: "city",
    cols: 12,
    required: true,
  },
  {
    id: "zip",
    label: "ZIP Code",
    dataKey: "zip",
    cols: 12,
    required: true,
    divider: true,
  },
  {
    id: "assessment_rate",
    label: "Initial Assessment Insurance Rate",
    dataKey: "assessment_rate",
    cols: 12,
    required: true,
    type: "money",
  },
  {
    id: "direct_therapy_rate",
    label: "Direct Therapy Insurance Rate",
    dataKey: "direct_therapy_rate",
    cols: 12,
    required: true,
    type: "money",
  },
  {
    id: "protocol_modification_rate",
    label: "Protocol Modification Insurance Rate",
    dataKey: "protocol_modification_rate",
    cols: 12,
    required: true,
    type: "money",
  },
  {
    id: "caregiver_training_rate",
    label: "Caregiver Training Insurance Rate",
    dataKey: "caregiver_training_rate",
    cols: 12,
    required: true,
    type: "money",
    divider: true,
  },
  {
    id: "location_modifier",
    label: "Location Modifier",
    dataKey: "location_modifier",
    cols: 12,
    required: true,
  },
  {
    id: "provider_modifier",
    label: "Provider Modifier",
    dataKey: "provider_modifier",
    cols: 12,
    required: true,
  },
  {
    id: "telehealth_modifier",
    label: "Telehealth Modifier",
    dataKey: "telehealth_modifier",
    cols: 12,
    required: true,
  },
];
