import React from "react";
import { Typography, Box, Accordion, AccordionSummary } from "@mui/material";
import Button from "@mui/material/Button";
import { CaretDown } from "@phosphor-icons/react";
const PracticeArea = (props) => {
  const { classes, userPermissions, toggleStartDemoCallDialog, onOpenDemoCallDialog } = props;
  return (
    <Box padding="0 16px">
      <Accordion
        defaultExpanded={false}
        square
        disableGutters
        sx={{
          "&::before": {
            display: "none",
          },
          "&.Mui-expanded": {
            margin: 0,
          },
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          sx={{ padding: 0 }}
          expandIcon={<CaretDown size={20} className="expandIcon" />}
        >
          <Typography variant="subtitle1">Practice Area</Typography>
        </AccordionSummary>
        <div className={classes.practiceAreaContainer}>
          <div className={classes.instructionsContainer}>
            <Typography component="h6">
              These demos will not send notifications to clients or families. Practice as much as
              you like!
            </Typography>
            <Typography component="p">
              Practicing with the video portion of the platfrom is a great way to become familiar
              with the features at your disposal outside of sessions.
            </Typography>
            {userPermissions?.schedule_call && (
              <Button color="secondary" onClick={toggleStartDemoCallDialog}>
                Demo Call
              </Button>
            )}
          </div>
        </div>
      </Accordion>
    </Box>
  );
};

export default PracticeArea;
