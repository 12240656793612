import { call, put, takeEvery } from "redux-saga/effects";
import actions from "../actions";
import {
  inviteClinician,
  getClinicianStatus,
  getClinicianBasicInfo,
  saveClinicianBasicInfo,
  saveClinicianDriversLicense,
  getClinicianCredentials,
  saveClinicianCredentials,
  getClinicianStateLicense,
  getClinicianStateLicenseById,
  saveClinicianStateLicense,
  updateClinicianStateLicense,
  deleteClinicianStateLicense,
  getClinicianAvailability,
  saveClinicianAvailability,
  saveClinicianCertification,
  getClinicianDocuments,
  saveClinicianDocument,
  replaceClinicianDocument,
  approveClinicianDocument,
  rejectClinicianDocument,
  getClinicianInsurances,
  updateClinicianInsurance,
  addClinicianInsurance,
  updateClinicianBio,
  updateClinicianPicture,
  updateClinicianStatus,
  approveClinicianApplication,
  rejectClinicianApplication,
  setClinicianCredentialed,
  getClinicianDashboard,
  syncClinicianCalendar,
  getClinicianClients,
  getClinicianPastClients,
  getClinicianPayStructure,
  saveClinicianPayStructure,
  deleteClinicianBonus,
  interviewClinicianApplicant,
  rejectClinicianApplicant,
  updateLiabilityExpirationDate,
  updateClinicianEmail,
  getClinicianClientStatus,
  getClinicianSessionClients,
  getClinicianNotes,
  saveClinicianNote,
  editClinicianNote,
  getClinicianNotifications,
  dismissClinicianNotification,
  getClinicianCallsNeverStarted,
  getClinicianCredentialData,
  setSuccessfulCalendarSync,
} from "../api/clinician";

function* getClinicianDetailsAsync({ payload }) {
  try {
    yield put(actions.getClinicianBasicInfo(payload.clinicianId));
    yield put(actions.getClinicianStatus(payload.clinicianId));
    yield put(actions.getClinicianCredentials(payload.clinicianId));
    yield put(actions.getClinicianDocuments(payload.clinicianId));
    yield put(actions.getClinicianStateLicense(payload.clinicianId));
    yield put(actions.getClinicianInsurances(payload.clinicianId));
    yield put(actions.getClinicianDashboard(payload.clinicianId));
    const basicInfo = yield call(getClinicianBasicInfo, payload.clinicianId);
    yield put(actions.getUserEmailLog(basicInfo.data.email));
    yield put(actions.getClinicianClients(basicInfo.data.user_id));
    yield put(actions.getClinicianPastClients(basicInfo.data.user_id));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* inviteClinicianAsync({ payload }) {
  try {
    yield call(inviteClinician, payload);
    yield put(actions.inviteClinicianSuccess());
    yield put(actions.getClinicianBasicInfo(payload));
    yield put(actions.getClinicianStatus(payload));
  } catch (e) {
    if (e.response && e.response.data && e.response.data.error) {
      yield put(actions.inviteClinicianFail(e.response.data.error));
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getClinicianStatusAsync({ payload }) {
  try {
    const status = yield call(getClinicianStatus, payload);
    yield put(actions.getClinicianStatusSuccess(status.data));
  } catch (e) {
    yield put(actions.getClinicianStatusFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getClinicianBasicInfoAsync({ payload }) {
  try {
    const basicInfo = yield call(getClinicianBasicInfo, payload);
    yield put(actions.getClinicianBasicInfoSuccess(basicInfo.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* saveClinicianBasicInfoAsync({ payload }) {
  try {
    yield call(saveClinicianBasicInfo, payload);
    yield put(actions.saveClinicianBasicInfoSuccess());
    yield put(actions.getClinicianBasicInfo(payload.clinicianId));
  } catch (e) {
    yield put(actions.saveClinicianBasicInfoFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* saveClinicianDriversLicenseAsync({ payload }) {
  try {
    yield call(saveClinicianDriversLicense, payload.license);
    yield put(actions.saveClinicianDriversLicenseSuccess());
    if (payload.fetch) {
      yield put(actions.getClinicianBasicInfo(payload.license.clinicianId));
    }
  } catch (e) {
    yield put(actions.saveClinicianDriversLicenseFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getClinicianCredentialsAsync({ payload }) {
  try {
    const credentials = yield call(getClinicianCredentials, payload);
    yield put(actions.getClinicianCredentialsSuccess(credentials.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* saveClinicianCredentialsAsync({ payload }) {
  try {
    yield call(saveClinicianCredentials, payload);
    yield put(actions.saveClinicianCredentialsSuccess());
    yield put(actions.getClinicianCredentials(payload.clinicianId));
  } catch (e) {
    yield put(actions.saveClinicianCredentialsFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getClinicianStateLicenseAsync({ payload }) {
  try {
    const stateLicense = yield call(getClinicianStateLicense, payload);
    yield put(actions.getClinicianStateLicenseSuccess(stateLicense.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getClinicianStateLicenseByIdAsync({ payload }) {
  try {
    const stateLicense = yield call(getClinicianStateLicenseById, payload);
    yield put(actions.getClinicianStateLicenseByIdSuccess(stateLicense.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* saveClinicianStateLicenseAsync({ payload }) {
  try {
    yield call(saveClinicianStateLicense, payload);
    yield put(actions.saveClinicianStateLicenseSuccess());
    yield put(actions.getClinicianStateLicense(payload.clinicianId));
  } catch (e) {
    yield put(actions.saveClinicianStateLicenseFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* updateClinicianStateLicenseAsync({ payload }) {
  try {
    yield call(updateClinicianStateLicense, payload);
    yield put(actions.updateClinicianStateLicenseSuccess());
    yield put(actions.getClinicianStateLicense(payload.clinicianId));
  } catch (e) {
    yield put(actions.updateClinicianStateLicenseFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* deleteClinicianStateLicenseAsync({ payload }) {
  try {
    yield call(deleteClinicianStateLicense, payload);
    yield put(actions.deleteClinicianStateLicenseSuccess());
    yield put(actions.getClinicianStateLicense(payload.clinicianId));
  } catch (e) {
    yield put(actions.deleteClinicianStateLicenseFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* saveClinicianCertificationAsync({ payload }) {
  try {
    yield call(saveClinicianCertification, payload);
    yield put(actions.saveClinicianCertificationSuccess());
    yield put(actions.getClinicianCredentials(payload.clinicianId));
  } catch (e) {
    yield put(actions.saveClinicianCertificationFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getClinicianInsurancesAsync({ payload }) {
  try {
    const insurances = yield call(getClinicianInsurances, payload);
    yield put(actions.getClinicianInsurancesSuccess(insurances.data));
  } catch (e) {
    yield put(actions.getClinicianInsurancesFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* updateClinicianInsuranceAsync({ payload }) {
  try {
    yield call(updateClinicianInsurance, payload);
    yield put(actions.updateClinicianInsuranceSuccess());
    yield put(actions.getClinicianInsurances(payload.clinicianId));
  } catch (e) {
    yield put(actions.updateClinicianInsuranceFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* addClinicianInsuranceAsync({ payload }) {
  try {
    yield call(addClinicianInsurance, payload);
    yield put(actions.addClinicianInsuranceSuccess());
    yield put(actions.getClinicianInsurances(payload.clinicianId));
  } catch (e) {
    yield put(actions.addClinicianInsuranceFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getClinicianAvailabilityAsync({ payload }) {
  try {
    const availability = yield call(getClinicianAvailability, payload);
    yield put(actions.getClinicianAvailabilitySuccess(availability.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* saveClinicianAvailabilityAsync({ payload }) {
  try {
    yield call(saveClinicianAvailability, payload);
    yield put(actions.saveClinicianAvailabilitySuccess());
    yield put(actions.getClinicianAvailability(payload.clinicianId));
  } catch (e) {
    yield put(actions.saveClinicianAvailabilityFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getClinicianDocumentsAsync({ payload }) {
  try {
    const documents = yield call(getClinicianDocuments, payload);
    yield put(actions.getClinicianDocumentsSuccess(documents.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* saveClinicianDocumentAsync({ payload }) {
  try {
    yield call(saveClinicianDocument, payload);
    yield put(actions.getClinicianDocuments(payload.clinicianId));
  } catch (e) {
    yield put(actions.saveClinicianDocumentFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* replaceClinicianDocumentAsync({ payload }) {
  try {
    yield call(replaceClinicianDocument, payload);
    yield put(actions.replaceClinicianDocumentSuccess());
    yield put(actions.getClinicianDocuments(payload.clinicianId));
  } catch (e) {
    yield put(actions.replaceClinicianDocumentFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* approveClinicianDocumentAsync({ payload }) {
  try {
    yield call(approveClinicianDocument, payload);
    yield put(actions.approveClinicianDocumentSuccess());
    yield put(actions.getClinicianDocuments(payload.clinicianId));
  } catch (e) {
    yield put(actions.approveClinicianDocumentFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* rejectClinicianDocumentAsync({ payload }) {
  try {
    yield call(rejectClinicianDocument, payload);
    yield put(actions.rejectClinicianDocumentSuccess());
    yield put(actions.getClinicianDocuments(payload.clinicianId));
  } catch (e) {
    yield put(actions.rejectClinicianDocumentFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* updateClinicianBioAsync({ payload }) {
  try {
    yield call(updateClinicianBio, payload);
    yield put(actions.getClinicianBasicInfo(payload.clinicianId));
    yield put(actions.updateClinicianBioSuccess());
  } catch (e) {
    yield put(actions.updateClinicianBioFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* updateClinicianPictureAsync({ payload }) {
  try {
    yield call(updateClinicianPicture, payload);
    yield put(actions.updateClinicianPictureSuccess());
  } catch (e) {
    yield put(actions.updateClinicianPictureFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* approveClinicianApplicationAsync({ payload }) {
  try {
    yield call(approveClinicianApplication, payload);
    yield put(actions.getClinicianStatus(payload));
    yield put(actions.approveClinicianApplicationSuccess());
  } catch (e) {
    yield put(actions.approveClinicianApplicationFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* rejectClinicianApplicationAsync({ payload }) {
  try {
    yield call(rejectClinicianApplication, payload);
    yield put(actions.rejectClinicianApplicationSuccess());
    yield put(actions.getClinicianStatus(payload.clinicianId));
  } catch (e) {
    yield put(actions.rejectClinicianApplicationFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* setClinicianCredentialedAsync({ payload }) {
  try {
    yield call(setClinicianCredentialed, payload);
    yield put(actions.setClinicianCredentialedSuccess());
    yield put(actions.getClinicianBasicInfo(payload));
    yield put(actions.getClinicianStatus(payload));
  } catch (e) {
    yield put(actions.setClinicianCredentialedFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* updateClinicianStatusAsync({ payload }) {
  try {
    yield call(updateClinicianStatus, payload);
    yield put(actions.getClinicianBasicInfo(payload.clinicianId));
    yield put(actions.getClinicianStatus(payload.clinicianId));
  } catch (e) {
    yield put(actions.updateClinicianStatusFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
  }
}

function* syncClinicianCalendarAsync({ payload }) {
  try {
    yield call(syncClinicianCalendar, payload);
    yield put(actions.syncClinicianCalendarSuccess());
    yield put(actions.getClinicianAvailability(payload));
  } catch (e) {
    yield put(actions.syncClinicianCalendarFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
  }
}

function* getClinicianDashboardAsync({ payload }) {
  try {
    const dashboard = yield call(getClinicianDashboard, payload);
    yield put(actions.getClinicianDashboardSuccess(dashboard.data));
  } catch (e) {
    yield put(actions.getClinicianDashboardFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
  }
}

function* getClinicianClientsAsync({ payload }) {
  try {
    const clientList = yield call(getClinicianClients, payload);
    let clients = [...clientList.data];
    clients.map((client) => {
      client.billableTime = { loading: true };
      client.documentStatus = { loading: true };
      return client;
    });
    yield put(
      actions.getClinicianClientsSuccess(
        clients,
        clientList.data.length > 0 ? parseInt(clientList.data[0].count) : 0
      )
    );
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getClinicianPastClientsAsync({ payload }) {
  try {
    const pastClientList = yield call(getClinicianPastClients, payload);
    yield put(
      actions.getClinicianPastClientsSuccess(
        pastClientList.data,
        pastClientList.data.length > 0 ? parseInt(pastClientList.data[0].count) : 0
      )
    );
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getClinicianPayStructureAsync({ payload }) {
  try {
    const payStructure = yield call(getClinicianPayStructure, payload);
    yield put(actions.getClinicianPayStructureSuccess(payStructure.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* saveClinicianPayStructureAsync({ payload }) {
  try {
    yield call(saveClinicianPayStructure, payload);
    yield put(actions.saveClinicianPayStructureSuccess());
    yield put(actions.getClinicianPayStructure(payload.clinicianId));
  } catch (e) {
    yield put(actions.saveClinicianPayStructureFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* deleteClinicianBonusAsync({ payload }) {
  try {
    yield call(deleteClinicianBonus, payload);
    yield put(actions.deleteClinicianBonusSuccess());
    yield put(actions.getClinicianPayStructure(payload));
  } catch (e) {
    yield put(actions.deleteClinicianBonusFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* rejectClinicianApplicantAsync({ payload }) {
  try {
    yield call(rejectClinicianApplicant, payload);
    yield put(actions.getClinicianStatus(payload.clinicianId));
    yield put(actions.getClinicianBasicInfo(payload.clinicianId));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* interviewClinicianApplicantAsync({ payload }) {
  try {
    yield call(interviewClinicianApplicant, payload);
    yield put(actions.getClinicianStatus(payload));
    yield put(actions.getClinicianBasicInfo(payload));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* updateLiabilityExpirationDateAsync({ payload }) {
  try {
    yield call(updateLiabilityExpirationDate, payload);
    const basicInfo = yield call(getClinicianBasicInfo, payload.clinicianId);
    yield put(actions.getClinicianBasicInfoSuccess(basicInfo.data));
    yield put(actions.updateLiabilityExpirationDateSuccess());
  } catch (e) {
    yield put(actions.updateLiabilityExpirationDateFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* updateClinicianEmailAsync({ payload }) {
  try {
    yield call(updateClinicianEmail, payload);
    const basicInfo = yield call(getClinicianBasicInfo, payload.clinicianId);
    yield put(actions.updateClinicianEmailSuccess(basicInfo.data.email));
  } catch (e) {
    if (e?.response?.data?.error) {
      yield put(actions.updateClinicianEmailFail(e.response.data.error));
    } else {
      yield put(
        actions.updateClinicianEmailFail(
          "An error has occurred while inviting this user. Please try again."
        )
      );
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error();
  }
}

function* getClinicianClientStatusAsync({ payload }) {
  try {
    const status = yield call(getClinicianClientStatus, payload);
    yield put(actions.getClinicianClientStatusSuccess(status.data));
  } catch (e) {
    yield put(actions.getClinicianClientStatusFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getClinicianSessionClientsAsync({ payload }) {
  try {
    const clientList = yield call(getClinicianSessionClients, payload);
    yield put(actions.getClinicianSessionClientsSuccess(clientList.data));
  } catch (e) {
    yield put(actions.getClinicianSessionClientsFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getClinicianNotesAsync({ payload }) {
  try {
    const notes = yield call(getClinicianNotes, payload);
    yield put(actions.getClinicianNotesSuccess(notes.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* saveClinicianNoteAsync({ payload }) {
  try {
    yield call(saveClinicianNote, payload);
    yield put(actions.getClinicianNotes(payload.clinicianId));
    yield put(actions.saveClinicianNoteSuccess());
  } catch (e) {
    yield put(actions.saveClinicianNoteFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* editClinicianNoteAsync({ payload }) {
  try {
    yield call(editClinicianNote, payload);
    yield put(actions.editClinicianNoteSuccess());
    yield put(actions.getClinicianNotes(payload.clinicianId));
  } catch (e) {
    yield put(actions.editClinicianNoteFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getClinicianNotificationsAsync({ payload }) {
  try {
    const notifications = yield call(getClinicianNotifications, payload);
    yield put(actions.getClinicianNotificationsSuccess(notifications.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* dismissClinicianNotificationAsync({ payload }) {
  try {
    yield call(dismissClinicianNotification, payload);
    yield put(actions.dismissClinicianNotificationSuccess());
    yield put(actions.getClinicianNotifications(payload.clinicianId));
  } catch (e) {
    yield put(actions.dismissClinicianNotificationFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getClinicianCallsNeverStartedAsync({ payload }) {
  try {
    let calls = yield call(getClinicianCallsNeverStarted, payload);
    yield put(actions.getClinicianCallsNeverStartedSuccess(calls.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getClinicianCredentialDataAsync({ payload }) {
  yield call(actions.getClinicianCredentialDataLoading);
  try {
    const res = yield call(getClinicianCredentialData, payload);
    yield put(actions.getClinicianCredentialDataSuccess(res?.data));
  } catch (e) {
    console.log(e);
    yield put(actions.getClinicianCredentialDataError());
  }
}

function* setSuccessfulCalendarSyncAsync({ payload }) {
  try {
    const res = yield call(setSuccessfulCalendarSync, payload);
    yield put(actions.setSuccessfulCalendarSyncSuccess(res.data));
  } catch (e) {
    yield put(actions.setSuccessfulCalendarSyncFail());
  }
}

export default function* startSaga() {
  yield takeEvery(actions.LOAD_CLINICIAN_DETAILS, getClinicianDetailsAsync);
  yield takeEvery(actions.INVITE_CLINICIAN, inviteClinicianAsync);
  yield takeEvery(actions.GET_CLINICIAN_STATUS, getClinicianStatusAsync);
  yield takeEvery(actions.GET_CLINICIAN_BASIC_INFO, getClinicianBasicInfoAsync);
  yield takeEvery(actions.SAVE_CLINICIAN_BASIC_INFO, saveClinicianBasicInfoAsync);
  yield takeEvery(actions.SAVE_CLINICIAN_DRIVERS_LICENSE, saveClinicianDriversLicenseAsync);
  yield takeEvery(actions.GET_CLINICIAN_CREDENTIALS, getClinicianCredentialsAsync);
  yield takeEvery(actions.SAVE_CLINICIAN_CREDENTIALS, saveClinicianCredentialsAsync);
  yield takeEvery(actions.GET_CLINICIAN_STATE_LICENSE, getClinicianStateLicenseAsync);
  yield takeEvery(actions.GET_CLINICIAN_STATE_LICENSE_BY_ID, getClinicianStateLicenseByIdAsync);
  yield takeEvery(actions.SAVE_CLINICIAN_STATE_LICENSE, saveClinicianStateLicenseAsync);
  yield takeEvery(actions.UPDATE_CLINICIAN_STATE_LICENSE, updateClinicianStateLicenseAsync);
  yield takeEvery(actions.DELETE_CLINICIAN_STATE_LICENSE, deleteClinicianStateLicenseAsync);
  yield takeEvery(actions.SAVE_CLINICIAN_CERTIFICATION, saveClinicianCertificationAsync);
  yield takeEvery(actions.GET_CLINICIAN_INSURANCES, getClinicianInsurancesAsync);
  yield takeEvery(actions.UPDATE_CLINICIAN_INSURANCE, updateClinicianInsuranceAsync);
  yield takeEvery(actions.ADD_CLINICIAN_INSURANCE, addClinicianInsuranceAsync);
  yield takeEvery(actions.GET_CLINICIAN_AVAILABILITY, getClinicianAvailabilityAsync);
  yield takeEvery(actions.SAVE_CLINICIAN_AVAILABILITY, saveClinicianAvailabilityAsync);
  yield takeEvery(actions.GET_CLINICIAN_DOCUMENTS, getClinicianDocumentsAsync);
  yield takeEvery(actions.SAVE_CLINICIAN_DOCUMENT, saveClinicianDocumentAsync);
  yield takeEvery(actions.REPLACE_CLINICIAN_DOCUMENT, replaceClinicianDocumentAsync);
  yield takeEvery(actions.REJECT_CLINICIAN_DOCUMENT, rejectClinicianDocumentAsync);
  yield takeEvery(actions.APPROVE_CLINICIAN_DOCUMENT, approveClinicianDocumentAsync);
  yield takeEvery(actions.UPDATE_CLINICIAN_BIO, updateClinicianBioAsync);
  yield takeEvery(actions.UPDATE_CLINICIAN_PICTURE, updateClinicianPictureAsync);
  yield takeEvery(actions.APPROVE_CLINICIAN_APPLICATION, approveClinicianApplicationAsync);
  yield takeEvery(actions.REJECT_CLINICIAN_APPLICATION, rejectClinicianApplicationAsync);
  yield takeEvery(actions.SET_CLINICIAN_CREDENTIALED, setClinicianCredentialedAsync);
  yield takeEvery(actions.UPDATE_CLINICIAN_STATUS, updateClinicianStatusAsync);
  yield takeEvery(actions.SYNC_CLINICIAN_CALENDAR, syncClinicianCalendarAsync);
  yield takeEvery(actions.GET_CLINICIAN_DASHBOARD, getClinicianDashboardAsync);
  yield takeEvery(actions.GET_CLINICIAN_CLIENTS, getClinicianClientsAsync);
  yield takeEvery(actions.GET_CLINICIAN_PAST_CLIENTS, getClinicianPastClientsAsync);
  yield takeEvery(actions.GET_CLINICIAN_PAY_STRUCTURE, getClinicianPayStructureAsync);
  yield takeEvery(actions.SAVE_CLINICIAN_PAY_STRUCTURE, saveClinicianPayStructureAsync);
  yield takeEvery(actions.DELETE_CLINICIAN_BONUS, deleteClinicianBonusAsync);
  yield takeEvery(actions.REJECT_CLINICIAN_APPLICANT, rejectClinicianApplicantAsync);
  yield takeEvery(actions.INTERVIEW_CLINICIAN_APPLICANT, interviewClinicianApplicantAsync);
  yield takeEvery(actions.UPDATE_LIABILITY_EXPIRATION_DATE, updateLiabilityExpirationDateAsync);
  yield takeEvery(actions.UPDATE_CLINICIAN_EMAIL, updateClinicianEmailAsync);
  yield takeEvery(actions.GET_CLINICIAN_CLIENT_STATUS, getClinicianClientStatusAsync);
  yield takeEvery(actions.GET_CLINICIAN_SESSION_CLIENTS, getClinicianSessionClientsAsync);
  yield takeEvery(actions.GET_CLINICIAN_NOTES, getClinicianNotesAsync);
  yield takeEvery(actions.SAVE_CLINICIAN_NOTE, saveClinicianNoteAsync);
  yield takeEvery(actions.EDIT_CLINICIAN_NOTE, editClinicianNoteAsync);
  yield takeEvery(actions.GET_CLINICIAN_NOTIFICATIONS, getClinicianNotificationsAsync);
  yield takeEvery(actions.DISMISS_CLINICIAN_NOTIFICATION, dismissClinicianNotificationAsync);
  yield takeEvery(actions.GET_CLINICIAN_CALLS_NEVER_STARTED, getClinicianCallsNeverStartedAsync);
  yield takeEvery(actions.GET_CLINICIAN_CREDENTIAL_DATA, getClinicianCredentialDataAsync);
  yield takeEvery(actions.SET_SUCCESSFUL_CALENDAR_SYNC, setSuccessfulCalendarSyncAsync);
}
