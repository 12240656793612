import React, { Component } from "react";
import * as AWS from "aws-sdk";
import { DropzoneDialogBase } from "mui-file-dropzone";
import DriversLicenseDialog from "./DriversLicenseDialog";
import RequestApplicationChangesDialog from "./RequestApplicationChangesDialog";
import UpdateClinicianStatusDialog from "./UpdateClinicianStatusDialog";
import ApproveApplicationDialog from "./ApproveApplicationDialog";
import ConfirmCredentialedDialog from "./ConfirmCredentialedOpenDialog";
import RejectApplicantDialog from "./RejectApplicantDialog";
import InterviewApplicantDialog from "./InterviewApplicantDialog";
import InviteClinicianDialog from "./InviteClinicianDialog";
import MarkCAQHCompleteDialog from "./MarkCAQHCompleteDialog";
import UpdateClinicianEmailDialog from "./UpdateClinicianEmailDialog";
import { getAWSCredentialsForCurrentUserSession } from "utils/aws";

const initialState = () => {
  return {
    fileObjects: [],
    uploadError: false,
    licenseFilename: "",
    licenseNumber: "",
    licenseState: "",
    driversLicenseImage: [],
    uploadDriversLicenseOpen: false,
    changesRequested: "",
    applicationSuccess: false,
    applicationError: false,
    updatedClinicianStatus: "",
    updateClinicianStatusRisks: false,
    isDirty: false,
    rejectApplicantReason: "",
    newEmail: "",
    confirmNewEmail: "",
    confirmEmailError: false,
    updateUserEmailRisks: false,
  };
};

class DialogsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState(),
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.editDriversLicenseOpen && this.props.editDriversLicenseOpen) {
      const { state_id_number, dl_state, image_name } = this.props.basicInfo;
      this.setState({
        licenseState: dl_state,
        licenseNumber: state_id_number,
        licenseFilename: image_name,
        uploadError: false,
      });
    }
  }

  setIsDirty = (isDirty) => {
    this.setState({ isDirty });
  };

  onChange = (name) => (e) => {
    const { value } = e.target;
    this.setState({ [name]: value });
    this.setIsDirty(true);
  };

  onSaveDriversLicense = async () => {
    const { driversLicenseImage, licenseNumber, licenseState } = this.state;
    const { clinicianId, basicInfo } = this.props;
    const { cognito_id } = basicInfo;
    this.setState({ uploadError: false });
    let filename;
    try {
      if (driversLicenseImage[0]) {
        const credentials = await getAWSCredentialsForCurrentUserSession();
        const s3 = new AWS.S3({
          credentials,
          apiVersion: "2006-03-01",
          params: { Bucket: process.env.AWS_USER_DOC_BUCKET },
        });
        let params = {
          ACL: "authenticated-read",
          Body: driversLicenseImage[0].file,
          ContentType: driversLicenseImage[0].file.type,
          Key: `${cognito_id}/drivers_license.${driversLicenseImage[0].file.name.split(".").pop()}`,
        };
        let upload = await s3.upload(params).promise();
        filename = upload.Key.split("/")[1];
        this.setState({ licenseFilename: filename });
      }
      const driversLicenseData = {
        state: licenseState,
        licenseNumber,
        filename: driversLicenseImage[0] ? filename : this.state.licenseFilename,
        clinicianId,
      };
      this.props.saveDriversLicense(driversLicenseData, true);
      this.setIsDirty(false);
    } catch (error) {
      this.setState({ uploadError: true });
      console.log(error);
    }
  };

  submitRequestChanges = (e) => {
    this.props.handleToggleDialog("requestChangesOpen")(e);
    this.props.rejectApplication({
      clinicianId: this.props.clinicianId,
      note: this.state.changesRequested,
    });
  };

  openFileDialog = (name) => (e) => {
    this.setState({ [name]: true });
  };

  closeFileDialog = (name) => (e) => {
    this.setState({ [name]: false, fileObjects: [] });
  };

  addFileObjects = (fileObjects) => {
    this.setState({ fileObjects });
  };

  persistFileObjects = (name, dialog) => {
    const { fileObjects } = this.state;
    this.setState({
      [name]: fileObjects,
      fileObjects: [],
      [dialog]: false,
    });
  };

  clearImages = (name) => (e) => {
    this.setState({ [name]: [] });
  };

  onSubmitUpdateClinicianStatus = (e) => {
    this.props.handleToggleDialog("updateClinicianStatusOpen")(e);
    this.props.updateClinicianStatus({
      status: this.state.updatedClinicianStatus,
      clinicianId: this.props.clinicianId,
    });
    this.setState({
      updateClinicianStatusRisks: false,
      updatedClinicianStatus: "",
    });
  };

  onClickCheckbox = (name) => {
    this.setState({ [name]: !this.state[name] });
  };

  onChangeClinicianStatus = (e) => {
    this.setState({ updatedClinicianStatus: e.target.value });
  };

  submitApproveApplication = (e) => {
    const { basicInfo } = this.props;
    this.props.approveApplication(this.props.clinicianId);

    this.props.handleToggleDialog("approveApplicationOpen")(e);
  };

  submitCredentialed = (e) => {
    this.props.handleToggleDialog("confirmCredentialedOpen")(e);

    this.props.setClinicianCredentialed(this.props.clinicianId);
  };

  onSubmitRejectApplicant = (e) => {
    this.props.rejectClinicianApplicant({
      clinicianId: this.props.clinicianId,
      reason: this.state.rejectApplicantReason,
      withoutEmail: this.props.rejectApplicantWithoutEmail,
    });
    this.props.handleToggleRejectApplicantDialog(false);
  };

  onSubmitInterviewApplicant = (e) => {
    this.props.interviewClinicianApplicant(this.props.clinicianId);
    this.props.handleToggleDialog("interviewApplicantOpen")(e);
  };

  onSubmitInviteClinician = (e) => {
    this.props.inviteClinician(this.props.clinicianId);
    this.props.handleToggleDialog("inviteClinicianOpen")(e);
  };

  onSubmitCAQHComplete = (e) => {
    this.props.updateClinicianStatus({
      clinicianId: this.props.clinicianId,
      status: "CREDENTIALING",
    });
    this.props.handleToggleDialog("markCAQHCompleteOpen")(e);
  };

  onToggleDriversLicenseDialog = async (e) => {
    await this.props.handleToggleDialog("editDriversLicenseOpen")(e);
    if (
      !this.props.editDriversLicenseOpen &&
      (this.props.driversLicenseSuccess || this.props.driversLicenseError)
    ) {
      this.setState({
        licenseState: "",
        licenseNumber: "",
        licenseFilename: "",
        uploadError: false,
      });
      this.props.clearClinicianDriversLicenceInfo();
    }
  };

  setError = (name, error) => {
    this.setState({ [name]: error });
  };

  onSubmitUpdateEmail = () => {
    const { newEmail } = this.state;
    this.props.updateClinicianEmail({
      clinicianId: this.props.clinicianId,
      newEmail: newEmail.toLowerCase(),
    });
  };

  validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  render() {
    return (
      <React.Fragment>
        <UpdateClinicianStatusDialog
          onClickCheckbox={this.onClickCheckbox}
          onSubmitUpdateClinicianStatus={this.onSubmitUpdateClinicianStatus}
          onChangeClinicianStatus={this.onChangeClinicianStatus}
          onToggleDialog={this.props.handleToggleDialog("updateClinicianStatusOpen")}
          {...this.props}
          {...this.state}
        />
        <ApproveApplicationDialog
          submitApproveApplication={this.submitApproveApplication}
          onToggleDialog={this.props.handleToggleDialog("approveApplicationOpen")}
          {...this.props}
          {...this.state}
        />
        <RequestApplicationChangesDialog
          onChange={this.onChange}
          onToggleDialog={this.props.handleToggleDialog("requestChangesOpen")}
          success={this.state.applicationSuccess}
          error={this.state.applicationError}
          submitRequestChanges={this.submitRequestChanges}
          {...this.props}
          {...this.state}
        />
        <ConfirmCredentialedDialog
          submitCredentialed={this.submitCredentialed}
          onToggleDialog={this.props.handleToggleDialog("confirmCredentialedOpen")}
          {...this.props}
          {...this.state}
        />
        <RejectApplicantDialog
          onChange={this.onChange}
          onSubmitRejectApplicant={this.onSubmitRejectApplicant}
          {...this.props}
          {...this.state}
        />
        <InterviewApplicantDialog
          onSubmitInterviewApplicant={this.onSubmitInterviewApplicant}
          {...this.props}
          {...this.state}
        />
        <InviteClinicianDialog
          onSubmitInviteClinician={this.onSubmitInviteClinician}
          {...this.props}
          {...this.state}
        />
        <MarkCAQHCompleteDialog
          onSubmitCAQHComplete={this.onSubmitCAQHComplete}
          onToggleDialog={this.props.handleToggleDialog("markCAQHCompleteOpen")}
          {...this.props}
          {...this.state}
        />
        <UpdateClinicianEmailDialog
          onSubmitUpdateEmail={this.onSubmitUpdateEmail}
          onToggleDialog={this.props.handleToggleDialog("updateEmailOpen")}
          onChange={this.onChange}
          onClickCheckbox={this.onClickCheckbox}
          validateEmail={this.validateEmail}
          setError={this.setError}
          {...this.props}
          {...this.state}
        />
        <DriversLicenseDialog
          onChange={this.onChange}
          onToggleDialog={this.onToggleDriversLicenseDialog}
          onSaveDriversLicense={this.onSaveDriversLicense}
          openFileDialog={this.openFileDialog("uploadDriversLicenseOpen")}
          clearImages={this.clearImages("driversLicenseImage")}
          dialogOpen={this.props.editDriversLicenseOpen}
          filename={this.state.licenseFilename}
          uploadSuccess={this.props.driversLicenseSuccess}
          {...this.props}
          {...this.state}
        />
        <DropzoneDialogBase
          dialogTitle="Update Drivers License Image"
          acceptedFiles={[
            "image/*",
            ".pdf",
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ]}
          fileObjects={this.state.fileObjects}
          filesLimit={1}
          cancelButtonText={"Cancel"}
          submitButtonText={"OK"}
          maxFileSize={15728640}
          open={this.state.uploadDriversLicenseOpen}
          onDelete={(deleteFileObj) => {
            this.addFileObjects([]);
          }}
          onAdd={(newFileObjs) => {
            this.addFileObjects(newFileObjs);
          }}
          onClose={this.closeFileDialog("uploadDriversLicenseOpen")}
          onSave={() => {
            this.persistFileObjects("driversLicenseImage", "uploadDriversLicenseOpen");
          }}
          showPreviews={true}
          showFileNamesInPreview={true}
          dialogProps={{
            style: { zIndex: 40003 },
          }}
        />
      </React.Fragment>
    );
  }
}

export default DialogsContainer;
