const ACTIONS = {
  GET_INSURANCE_PROVIDERS: "GET_INSURANCE_PROVIDERS",
  GET_INSURANCE_PROVIDERS_SUCCESS: "GET_INSURANCE_SUCCESS",
  GET_INSURANCE_PROVIDERS_NOT_ACCEPTED_SUCCESS: "GET_INSURANCE_PROVIDERS_NOT_ACCEPTED_SUCCESS",
  GET_INSURANCE_CODES: "GET_INSURANCE_CODES",
  GET_INSURANCE_CODES_SUCCESS: "GET_INSURANCE_CODES_SUCCESS",
  GET_APPROVED_UNITS: "GET_APPROVED_UNITS",
  GET_APPROVED_UNITS_SUCCESS: "GET_APPROVED_UNITS_SUCCESS",
  CREATE_APPROVED_UNITS: "CREATE_APPROVED_UNITS",
  UPDATE_APPROVED_UNITS: "UPDATE_APPROVED_UNITS",
  GET_AUTHORIZATION_CODES: "GET_AUTHORIZATION_CODES",
  GET_AUTHORIZATION_CODES_SUCCESS: "GET_AUTHORIZATION_CODES_SUCCESS",
  CREATE_AUTHORIZATION_CODE: "CREATE_AUTHORIZATION_CODES",
  CREATE_AUTHORIZATION_CODE_SUCCESS: "CREATE_AUTHORIZATION_CODE_SUCCESS",
  UPDATE_AUTHORIZATION_CODE: "UPDATE_AUTHORIZATION_CODE",
  UPDATE_AUTHORIZATION_CODE_SUCCESS: "UPDATE_AUTHORIZATION_CODE_SUCCESS",
  GET_INSURANCE_CODES_BY_USER: "GET_INSURANCE_CODES_BY_USER",
  GET_INSURANCE_CODES_BY_USER_SUCCESS: "GET_INSURANCE_CODES_BY_USER_SUCCESS",
  GET_INSURANCE_CODES_BY_USER_FAIL: "GET_INSURANCE_CODES_BY_USER_FAIL",

  GET_INSURANCE_PROVIDERS_NOT_ACCEPTED_SUCCESS: "GET_INSURANCE_PROVIDERS_NOT_ACCEPTED_SUCCESS",
  GET_USER_INSURANCE: "GET_USER_INSURANCE",
  GET_USER_INSURANCE_SUCCESS: "GET_USER_INSURANCE_SUCCESS",
  GET_USER_INSURANCE_FAIL: "GET_USER_INSURANCE_FAIL",
  UPDATE_USER_INSURANCE: "UPDATE_USER_INSURANCE",
  CREATE_USER_INSURANCE: "CREATE_USER_INSURANCE",
  DISABLE_USER_INSURANCE: "DISABLE_USER_INSURANCE",

  GET_BILLABLE_TIME: "GET_BILLABLE_TIME",
  GET_BILLABLE_TIME_SUCCESS: "GET_BILLABLE_TIME_SUCCESS",
  GET_BILLABLE_TIME_FAIL: "GET_BILLABLE_TIME_FAIL",
  CREATE_OFF_PLATFORM_WORK: "CREATE_OFF_PLATFORM_WORK",
  CREATE_OFF_PLATFORM_WORK_SUCCESS: "CREATE_OFF_PLATFORM_WORK_SUCCESS",
  CREATE_OFF_PLATFORM_WORK_FAIL: "CREATE_OFF_PLATFORM_WORK_FAIL",
  GET_OFF_PLATFORM_WORK: "GET_OFF_PLATFORM_WORK",
  GET_OFF_PLATFORM_WORK_SUCCESS: "GET_OFF_PLATFORM_WORK_SUCCESS",
  GET_OFF_PLATFORM_WORK_FAIL: "GET_OFF_PLATFORM_WORK_FAIL",
  APPROVE_OFF_PLATFORM_WORK: "APPROVE_OFF_PLATFORM_WORK",
  DENY_OFF_PLATFORM_WORK: "DENY_OFF_PLATFORM_WORK",

  UPDATE_BILLABLE_TIME_INSURANCE_CODE: "UPDATE_BILLABLE_TIME_INSURANCE_CODE",
  UPDATE_BILLABLE_TIME_INSURANCE_CODE_SUCCESS: "UPDATE_BILLABLE_TIME_INSURANCE_CODE_SUCCESS",
  UPDATE_BILLABLE_TIME_INSURANCE_CODE_FAIL: "UPDATE_BILLABLE_TIME_INSURANCE_CODE_FAIL",
  CLEAR_UPDATE_BILLABLE_TIME_INSURANCE_CODE_INFO: "CLEAR_UPDATE_BILLABLE_TIME_INSURANCE_CODE_INFO",

  GET_ALL_AVAILABLE_CODES: "GET_ALL_AVAILABLE_CODES",
  GET_ALL_AVAILABLE_CODES_SUCCESS: "GET_ALL_AVAILABLE_CODES_SUCCESS",

  GET_REFERRING_PHYSICIANS: "GET_REFERRING_PHYSICIANS",
  GET_REFERRING_PHYSICIANS_SUCCESS: "GET_REFERRING_PHYSICIANS_SUCCESS",
  CREATE_REFERRING_PHYSICIAN: "CREATE_REFERRING_PHYSICIAN",
  CREATE_REFERRING_PHYSICIAN_SUCCESS: "CREATE_REFERRING_PHYSICIAN_SUCCESS",
  CREATE_REFERRING_PHYSICIAN_FAIL: "CREATE_REFERRING_PHYSICIAN_FAIL",

  CREATE_INSURANCE_PROVIDER: "CREATE_INSURANCE_PROVIDER",
  CREATE_INSURANCE_PROVIDER_SUCCESS: "CREATE_INSURANCE_PROVIDER_SUCCESS",
  CREATE_INSURANCE_PROVIDER_FAIL: "CREATE_INSURANCE_PROVIDER_FAIL",
  UPDATE_INSURANCE_PROVIDER: "UPDATE_INSURANCE_PROVIDER",
  UPDATE_INSURANCE_PROVIDER_SUCCESS: "UPDATE_INSURANCE_PROVIDER_SUCCESS",
  UPDATE_INSURANCE_PROVIDER_FAIL: "UPDATE_INSURANCE_PROVIDER_FAIL",

  GET_INSURANCE_PROVIDERS_LIST: "GET_INSURANCE_PROVIDERS_LIST",
  GET_INSURANCE_PROVIDERS_LIST_SUCCESS: "GET_INSURANCE_PROVIDERS_LIST_SUCCESS",
  GET_INSURANCE_PLANS: "GET_INSURANCE_PLANS",
  GET_INSURANCE_PLANS_SUCCESS: "GET_INSURANCE_PLANS_SUCCESS",
  CREATE_INSURANCE_PLAN: "CREATE_INSURANCE_PLAN",
  CREATE_INSURANCE_PLAN_SUCCESS: "CREATE_INSURANCE_PLAN_SUCCESS",
  CREATE_INSURANCE_PLAN_FAIL: "CREATE_INSURANCE_PLAN_FAIL",
  UPDATE_INSURANCE_PLAN: "UPDATE_INSURANCE_PLAN",
  UPDATE_INSURANCE_PLAN_SUCCESS: "UPDATE_INSURANCE_PLAN_SUCCESS",
  UPDATE_INSURANCE_PLAN_FAIL: "UPDATE_INSURANCE_PLAN_FAIL",
  SET_INSURANCE_LIST_STATE: "SET_INSURANCE_LIST_STATE",
  SET_INSURANCE_LIST_PROVIDER_ID: "SET_INSURANCE_LIST_PROVIDER_ID",
  CLEAR_INSURANCE_LIST_FILTERS: "CLEAR_INSURANCE_LIST_FILTERS",
  SET_INSURANCE_PLANS_PAGE: "SET_INSURANCE_PLANS_PAGE",
  SET_INSURANCE_PLANS_ROWS_PER_PAGE: "SET_INSURANCE_PLANS_ROWS_PER_PAGE",
  SET_INSURANCE_PLANS_SORT: "SET_INSURANCE_PLANS_SORT",
  GET_INSURANCE_PLAN: "GET_INSURANCE_PLAN",
  GET_INSURANCE_PLAN_SUCCESS: "GET_INSURANCE_PLAN_SUCCESS",
  GET_INSURANCE_PLAN_FAIL: "GET_INSURANCE_PLAN_FAIL",
  GET_INSURANCE_PROVIDER: "GET_INSURANCE_PROVIDER",
  GET_INSURANCE_PROVIDER_SUCCESS: "GET_INSURANCE_PROVIDER_SUCCESS",
  GET_INSURANCE_PROVIDER_FAIL: "GET_INSURANCE_PROVIDER_FAIL",
};

const ACTION_CREATORS = {
  getInsuranceProviders(state) {
    return {
      type: ACTIONS.GET_INSURANCE_PROVIDERS,
      payload: state,
    };
  },
  getInsuranceProvidersSuccess(providers) {
    return {
      type: ACTIONS.GET_INSURANCE_PROVIDERS_SUCCESS,
      payload: providers,
    };
  },
  getInsuranceProvidersNotAcceptedSuccess(providers) {
    return {
      type: ACTIONS.GET_INSURANCE_PROVIDERS_NOT_ACCEPTED_SUCCESS,
      payload: providers,
    };
  },
  getInsuranceCodes() {
    return {
      type: ACTIONS.GET_INSURANCE_CODES,
    };
  },
  getInsuranceCodesSuccess(codes) {
    return {
      type: ACTIONS.GET_INSURANCE_CODES_SUCCESS,
      payload: codes,
    };
  },
  getApprovedUnits(clientId) {
    return {
      type: ACTIONS.GET_APPROVED_UNITS,
      payload: clientId,
    };
  },
  getApprovedUnitsSuccess(units) {
    return {
      type: ACTIONS.GET_APPROVED_UNITS_SUCCESS,
      payload: units,
    };
  },
  createApprovedUnits(unitObject) {
    return {
      type: ACTIONS.CREATE_APPROVED_UNITS,
      payload: unitObject,
    };
  },
  updateApprovedUnits(unitObject) {
    return {
      type: ACTIONS.UPDATE_APPROVED_UNITS,
      payload: unitObject,
    };
  },
  getAuthorizationCodes(clientId) {
    return {
      type: ACTIONS.GET_AUTHORIZATION_CODES,
      payload: clientId,
    };
  },
  getAuthorizationCodesSuccess(codes) {
    return {
      type: ACTIONS.GET_AUTHORIZATION_CODES_SUCCESS,
      payload: codes,
    };
  },
  createAuthorizationCode(authObj) {
    return {
      type: ACTIONS.CREATE_AUTHORIZATION_CODE,
      payload: authObj,
    };
  },
  createAuthorizationCodeSuccess() {
    return {
      type: ACTIONS.CREATE_AUTHORIZATION_CODE_SUCCESS,
    };
  },
  updateAuthorizationCode(authObj) {
    return {
      type: ACTIONS.UPDATE_AUTHORIZATION_CODE,
      payload: authObj,
    };
  },
  updateAuthorizationCodeSuccess() {
    return {
      type: ACTIONS.UPDATE_AUTHORIZATION_CODE_SUCCESS,
    };
  },
  getInsuranceCodesByUser(payload) {
    return {
      type: ACTIONS.GET_INSURANCE_CODES_BY_USER,
      payload,
    };
  },
  getInsuranceCodesByUserSuccess(codes) {
    return {
      type: ACTIONS.GET_INSURANCE_CODES_BY_USER_SUCCESS,
      payload: codes,
    };
  },
  getInsuranceCodesByUserFail() {
    return {
      type: ACTIONS.GET_INSURANCE_CODES_BY_USER_FAIL,
    };
  },
  getUserInsurance(clientId) {
    return {
      type: ACTIONS.GET_USER_INSURANCE,
      payload: clientId,
    };
  },
  getUserInsuranceSuccess(payload) {
    return {
      type: ACTIONS.GET_USER_INSURANCE_SUCCESS,
      payload,
    };
  },
  getUserInsuranceFail() {
    return {
      type: ACTIONS.GET_USER_INSURANCE_FAIL,
    };
  },
  updateUserInsurance(payload) {
    return {
      type: ACTIONS.UPDATE_USER_INSURANCE,
      payload,
    };
  },
  createUserInsurance(payload) {
    return {
      type: ACTIONS.CREATE_USER_INSURANCE,
      payload,
    };
  },
  disableUserInsurance(payload) {
    return {
      type: ACTIONS.DISABLE_USER_INSURANCE,
      payload,
    };
  },
  getBillableTime(payload) {
    return {
      type: ACTIONS.GET_BILLABLE_TIME,
      payload,
    };
  },
  getBillableTimeSuccess(payload) {
    return {
      type: ACTIONS.GET_BILLABLE_TIME_SUCCESS,
      payload,
    };
  },
  getBillableTimeFail() {
    return {
      type: ACTIONS.GET_BILLABLE_TIME_FAIL,
    };
  },
  createOffPlatformWork(payload) {
    return {
      type: ACTIONS.CREATE_OFF_PLATFORM_WORK,
      payload,
    };
  },
  createOffPlatformWorkSuccess() {
    return {
      type: ACTIONS.CREATE_OFF_PLATFORM_WORK_SUCCESS,
    };
  },
  createOffPlatformWorkFail() {
    return {
      type: ACTIONS.CREATE_OFF_PLATFORM_WORK_FAIL,
    };
  },
  getOffPlatformWork() {
    return {
      type: ACTIONS.GET_OFF_PLATFORM_WORK,
    };
  },
  getOffPlatformWorkSuccess(payload) {
    return {
      type: ACTIONS.GET_OFF_PLATFORM_WORK_SUCCESS,
      payload,
    };
  },
  getOffPlatformWorkFail() {
    return {
      type: ACTIONS.GET_OFF_PLATFORM_WORK_FAIL,
    };
  },
  approveOffPlatformWork(payload) {
    return {
      type: ACTIONS.APPROVE_OFF_PLATFORM_WORK,
      payload,
    };
  },
  denyOffPlatformWork(payload) {
    return {
      type: ACTIONS.DENY_OFF_PLATFORM_WORK,
      payload,
    };
  },
  updateBillableTimeInsuranceCode(payload) {
    return {
      type: ACTIONS.UPDATE_BILLABLE_TIME_INSURANCE_CODE,
      payload,
    };
  },
  updateBillableTimeInsuranceCodeSuccess() {
    return {
      type: ACTIONS.UPDATE_BILLABLE_TIME_INSURANCE_CODE_SUCCESS,
    };
  },
  updateBillableTimeInsuranceCodeFail() {
    return {
      type: ACTIONS.UPDATE_BILLABLE_TIME_INSURANCE_CODE_FAIL,
    };
  },
  clearUpdateBillableTimeInsuranceCodeInfo() {
    return {
      type: ACTIONS.CLEAR_UPDATE_BILLABLE_TIME_INSURANCE_CODE_INFO,
    };
  },
  getAllAvailableInsuranceCodes(payload) {
    return {
      type: ACTIONS.GET_ALL_AVAILABLE_CODES,
      payload,
    };
  },
  getAllAvailableInsuranceCodesSuccess(payload) {
    return {
      type: ACTIONS.GET_ALL_AVAILABLE_CODES_SUCCESS,
      payload,
    };
  },
  getReferringPhysicians() {
    return {
      type: ACTIONS.GET_REFERRING_PHYSICIANS,
    };
  },
  getReferringPhysiciansSuccess(payload) {
    return {
      type: ACTIONS.GET_REFERRING_PHYSICIANS_SUCCESS,
      payload,
    };
  },
  createReferringPhysician(payload) {
    return {
      type: ACTIONS.CREATE_REFERRING_PHYSICIAN,
      payload,
    };
  },
  createReferringPhysicianSuccess(payload) {
    return {
      type: ACTIONS.CREATE_REFERRING_PHYSICIAN_SUCCESS,
      payload,
    };
  },
  createReferringPhysicianFail() {
    return {
      type: ACTIONS.CREATE_REFERRING_PHYSICIAN_FAIL,
    };
  },
  getInsuranceProvidersList(payload) {
    return {
      type: ACTIONS.GET_INSURANCE_PROVIDERS_LIST,
      payload,
    };
  },
  getInsuranceProvidersListSuccess(payload) {
    return {
      type: ACTIONS.GET_INSURANCE_PROVIDERS_LIST_SUCCESS,
      payload,
    };
  },
  getInsuranceProvider(payload) {
    return {
      type: ACTIONS.GET_INSURANCE_PROVIDER,
      payload,
    };
  },
  getInsuranceProviderSuccess(payload) {
    return {
      type: ACTIONS.GET_INSURANCE_PROVIDER_SUCCESS,
      payload,
    };
  },
  getInsuranceProviderFail() {
    return {
      type: ACTIONS.GET_INSURANCE_PROVIDER_FAIL,
    };
  },
  createInsuranceProvider(payload) {
    return {
      type: ACTIONS.CREATE_INSURANCE_PROVIDER,
      payload,
    };
  },
  createInsuranceProviderSuccess() {
    return {
      type: ACTIONS.CREATE_INSURANCE_PROVIDER_SUCCESS,
    };
  },
  createInsuranceProviderFail() {
    return {
      type: ACTIONS.CREATE_INSURANCE_PROVIDER_FAIL,
    };
  },
  updateInsuranceProvider(payload) {
    return {
      type: ACTIONS.UPDATE_INSURANCE_PROVIDER,
      payload,
    };
  },
  updateInsuranceProviderSuccess() {
    return {
      type: ACTIONS.UPDATE_INSURANCE_PROVIDER_SUCCESS,
    };
  },
  updateInsuranceProviderFail() {
    return {
      type: ACTIONS.UPDATE_INSURANCE_PROVIDER_FAIL,
    };
  },
  getInsurancePlan(payload) {
    return {
      type: ACTIONS.GET_INSURANCE_PLAN,
      payload,
    };
  },
  getInsurancePlanSuccess(payload) {
    return {
      type: ACTIONS.GET_INSURANCE_PLAN_SUCCESS,
      payload,
    };
  },
  getInsurancePlanFail() {
    return {
      type: ACTIONS.GET_INSURANCE_PLAN_FAIL,
    };
  },
  getInsurancePlans(payload) {
    return {
      type: ACTIONS.GET_INSURANCE_PLANS,
      payload,
    };
  },
  getInsurancePlansSuccess(payload) {
    return {
      type: ACTIONS.GET_INSURANCE_PLANS_SUCCESS,
      payload,
    };
  },
  createInsurancePlan(payload) {
    return {
      type: ACTIONS.CREATE_INSURANCE_PLAN,
      payload,
    };
  },
  createInsurancePlanSuccess() {
    return {
      type: ACTIONS.CREATE_INSURANCE_PLAN_SUCCESS,
    };
  },
  createInsurancePlanFail() {
    return {
      type: ACTIONS.CREATE_INSURANCE_PLAN_FAIL,
    };
  },
  updateInsurancePlan(payload) {
    return {
      type: ACTIONS.UPDATE_INSURANCE_PLAN,
      payload,
    };
  },
  updateInsurancePlanSuccess() {
    return {
      type: ACTIONS.UPDATE_INSURANCE_PLAN_SUCCESS,
    };
  },
  updateInsurancePlanFail() {
    return {
      type: ACTIONS.UPDATE_INSURANCE_PLAN_FAIL,
    };
  },
  setInsuranceListState(payload) {
    return {
      type: ACTIONS.SET_INSURANCE_LIST_STATE,
      payload,
    };
  },
  setInsuranceListProviderId(payload) {
    return {
      type: ACTIONS.SET_INSURANCE_LIST_PROVIDER_ID,
      payload,
    };
  },
  clearInsuranceListFilters() {
    return {
      type: ACTIONS.CLEAR_INSURANCE_LIST_FILTERS,
    };
  },
  setInsurancePlansPage(payload) {
    return {
      type: ACTIONS.SET_INSURANCE_PLANS_PAGE,
      payload,
    };
  },
  setInsurancePlansRowsPerPage(payload) {
    return {
      type: ACTIONS.SET_INSURANCE_PLANS_ROWS_PER_PAGE,
      payload,
    };
  },
  setInsurancePlansSort(payload) {
    return {
      type: ACTIONS.SET_INSURANCE_PLANS_SORT,
      payload,
    };
  },
};

export default { ...ACTIONS, ...ACTION_CREATORS };
