// Stale Time
export const STALE_TIME_60_MINUTES = 60 * 1000 * 60; // 60 minutes;
export const STALE_TIME_5_MINUTES = 60 * 1000 * 5; // 5 minutes;
export const STALE_TIME_10_MINUTES = 60 * 1000 * 10; // 5 minutes;
export const STALE_TIME_1_MINUTES = 60 * 1000;

// Keys
export const ASSESSMENTS_BATCH = "assessmentsBatch";
export const CLINICIAN_CREDENTIALS = "clinicianCredentials";
export const CLINICIAN_STATE_LICENSE = "clinicianStateLicense";
export const CLINICIAN_CERTIFICATION = "clinicianCertification";
export const CLINICIAN_LIABILITY_INSURANCE = "clinicianLiabilityInsurance";
export const USER_PERMISSIONS = "userPermissions";
export const CLIENT_ASSESSMENTS = "clientAssessments";
export const GET_INSURANCE_VOB = "getInsuranceVob";
export const GET_INSURANCE_PLAN = "getInsurancePlans";
