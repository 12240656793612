import React from "react";
import { Typography, Tooltip, Link } from "@mui/material";
import { COVERAGE_STATES } from "../../constants";
import { InsurancePlanConfig } from "../AnLibraries/plan/InsurancePlanConfig";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import ANNoResultsOverlay from "../../elements/ANNoResultsOverlay";
import ANTooltip from "../../elements/ANTooltip";

const InsuranceUtilityTable = (props) => {
  const {
    insurancePlansData,
    isFetchingData,
    copyToDataPanel,
    updateTitlePanel,
    setIsPanelOpen,
    isPanelOpen,
    setConfigPanel,
    setEditMode,
    paginationModel,
    setPaginationModel,
    rowCount,
    setPlanFilter,
    setCompanyFilter,
    setCoverageStateFilter,
  } = props;

  const handleSelect = (row) => {
    if (isPanelOpen) {
      setEditMode(true);
      updateTitlePanel(null);
      setIsPanelOpen(false);
      copyToDataPanel([]);
      setIsPanelOpen(false);
      setConfigPanel(null);
    } else {
      setEditMode(false);
      updateTitlePanel(row.name);
      setIsPanelOpen(true);
      copyToDataPanel({ ...row });
      setIsPanelOpen(true);
      setConfigPanel(InsurancePlanConfig);
    }
  };
  const getColumnDefinitions = (handleSelect) => {
    const defaultColAttrs = {
      sortable: false,
      flex: 1,
    };

    const columns = [
      {
        field: "name",
        headerName: "Insurance Plan",
        ...defaultColAttrs,
        renderCell: (params) => {
          return (
            <ANTooltip
              component={Link}
              style={{ maxWidth: "100%", display: "block" }}
              onClick={(e) => handleSelect(params)}
            >
              {params.value}
            </ANTooltip>
          );
        },
      },
      { field: "insurance_name", headerName: "Insurance Company", ...defaultColAttrs },
      {
        field: "address",
        headerName: "Address",
        ...defaultColAttrs,
        renderCell: (params) => {
          const address = [
            `${params.row.address_1?.trim()}${params.row.address_2 ? " " + params.row.address_2?.trim() : ""} `,
            `${params.row.city?.trim()}, ${params.row.state} ${params.row.zip}`,
          ];
          return (
            <ANTooltip
              component={Typography}
              variant="body2"
              style={{ maxWidth: "100%", display: "block" }}
            >
              {address}
            </ANTooltip>
          );
        },
      },
      {
        field: "coverage_state",
        headerName: "Coverage State",
        ...defaultColAttrs,
        renderCell: (params) => {
          return <Typography variant="body2">{COVERAGE_STATES[params.value]}</Typography>;
        },
      },
      {
        field: "assessment_rate",
        headerName: "Initial Assessment Rate",
        ...defaultColAttrs,
        renderCell: (params) => {
          return <Typography variant="body2">${params.value ?? "0.00"}</Typography>;
        },
      },
      {
        field: "protocol_modification_rate",
        headerName: "Direct Therapy Rate",
        ...defaultColAttrs,
        renderCell: (params) => {
          return <Typography variant="body2">${params.value ?? "0.00"}</Typography>;
        },
      },
      {
        field: "caregiver_training_rate",
        headerName: "Protocol Modification Rate",
        ...defaultColAttrs,
        renderCell: (params) => {
          return <Typography variant="body2">${params.value ?? "0.00"}</Typography>;
        },
      },
      {
        field: "direct_therapy_rate",
        headerName: "Caregiver Training Rate",
        ...defaultColAttrs,
        renderCell: (params) => {
          return <Typography variant="body2">${params.value ?? "0.00"}</Typography>;
        },
      },
    ];
    return columns;
  };

  const handleClearFilters = () => {
    setPlanFilter([]);
    setCompanyFilter([]);
    setCoverageStateFilter([]);
  };

  const customNoRowsOverlay = {
    NoResults: {
      component: ANNoResultsOverlay,
      height: "236px",
      props: { handleClearFilters },
    },
  };
  const overlayName = "NoResults";

  return (
    <DataGrid
      rows={insurancePlansData}
      loading={isFetchingData && !batchFiles}
      pageSizeOptions={[5, 10, 20]}
      columns={getColumnDefinitions(({ row }) => {
        handleSelect(row);
      })}
      onRowClick={(params) => handleSelect(params.row)}
      autoHeight
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      slots={{
        noRowsOverlay: customNoRowsOverlay[overlayName].component,
      }}
      slotProps={{ noRowsOverlay: { ...customNoRowsOverlay[overlayName].props } }}
      sx={{
        "--DataGrid-overlayHeight": customNoRowsOverlay[overlayName].height,
        "& .MuiDataGrid-selectedRowCount": {
          visibility: "hidden",
        },
      }}
      hideFooter={!rowCount}
      paginationMode="server"
      rowCount={rowCount}
      disableSelectionOnClick
    />
  );
};

export default InsuranceUtilityTable;
