import { call, put, all, select, takeEvery } from "redux-saga/effects";
import actions from "../actions";
import {
  getCustomerList,
  getInsuranceCustomerList,
  getCustomer,
  getCustomerChildren,
  getCustomerClinicians,
  switchClinician,
  markEligible,
  markApproved,
  getCustomerTreatmentPlans,
  uploadTreatmentPlan,
  getCustomerDocuments,
  uploadCustomerDocument,
  getDocumentTypes,
  updateTreatmentPlan,
  updateUserStatus,
  resendLoginEmail,
  assignClinician,
  assignSecondaryClinician,
  removeSecondaryClinician,
  toggleCustomerActivation,
  getCustomerPhysicians,
  createInsuranceCustomer,
  updateEligibility,
  createUnsignedRequest,
  getAllDocumentRequests,
  getSingleCustomerSearch,
  getDocumentMatrix,
  approveCustomerDocument,
  getCompletedDocumentStatus,
  getDeactivatedCustomerList,
  getCustomerDeactivationReasons,
  getCustomerDepartureOptions,
  getCustomerStatusUpdates,
  getCustomerPastClinicians,
  saveCustomerForLater,
  getSavedForLaterCustomers,
  getSavedCustomerDetails,
  updatePatientInfo,
  updateGuardianInfo,
  saveCustomerPhysician,
  updateCustomerPhysician,
  getCustomerBillableItems,
  switchPrimaryAccountHolder,
  updateCustomerEmail,
  updateCustomerTimezone,
  getCustomerPastCalls,
  deactivateClient,
  enableClientStaticPage,
  disableClientStaticPage,
  getClientTaskList,
  markClientTaskComplete,
  getClientSessionStatistics,
  getClientCriticalNeeds,
  snoozeClientTask,
  getAllActiveClients,
  updateLinkPhysician,
  getAllPhysicians,
} from "../api/customers";
import { getCustomersBillableTimePeriod, getCustomerClientId } from "../selectors";
import { getNotesList } from "../api/notes";
import { getApprovedUnits, getBillableTime } from "../api/insurance";
import { sendEligibilityRejectionInfo } from "../api/parent";

function* getCustomers() {
  try {
    const customerList = yield call(getCustomerList);
    yield put(
      actions.setCustomerList(
        customerList.data,
        customerList.data.length > 0 ? parseInt(customerList.data[0].count) : 0
      )
    );
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    console.error(e);
  }
}

function* getAllActiveClientsAsync({ payload }) {
  try {
    const customerList = yield call(getAllActiveClients, payload);
    yield put(actions.setCustomerList(customerList.data, 0));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    console.error(e);
  }
}

function* getPaidCustomersAsync() {
  try {
    const customerList = yield call(getPaidCustomerList);
    yield put(
      actions.setCustomerList(
        customerList.data,
        customerList.data.length > 0 ? parseInt(customerList.data[0].count) : 0
      )
    );
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    console.error(e);
  }
}

function* getInsuranceCustomersAsync() {
  try {
    const customerList = yield call(getInsuranceCustomerList);
    let customers = [...customerList.data];
    customers.map((customer) => {
      customer.billableTime = { loading: true };
      customer.documentStatus = { loading: true };
      return customer;
    });
    yield put(
      actions.setCustomerList(
        customers,
        customerList.data.length > 0 ? parseInt(customerList.data[0].count) : 0
      )
    );
    for (let i = 0; i < customers.length; i++) {
      let billableTime = yield call(mapBillableTimeToCustomer, customers[i]);
      yield put(actions.setCustomerBillableTime(billableTime));
      let docStatus = yield call(getCompletedDocumentStatusAsync, customers[i]);
      yield put(actions.setCustomerDocumentStatus(docStatus));
    }
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.getInsuranceCustomerListFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getDeactivatedCustomerListAsync() {
  try {
    const deactivated = yield call(getDeactivatedCustomerList);
    yield put(actions.getDeactivatedCustomerListSuccess(deactivated.data));
  } catch (e) {
    yield put(actions.getDeactivatedCustomerListFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getCustomerDeactivationReasonsAsync() {
  try {
    const reasons = yield call(getCustomerDeactivationReasons);
    yield put(actions.getCustomerDeactivationReasonsSuccess(reasons.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* getCustomerDepartureOptionsAsync() {
  try {
    const res = yield call(getCustomerDepartureOptions);
    yield put(actions.getCustomerDepartureOptionsSuccess(res.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* mapBillableTimeToCustomer(customer) {
  try {
    let period = yield select(getCustomersBillableTimePeriod);
    let billableTime = yield call(getBillableTime, {
      clientId: customer.client_id,
      period,
    });
    let filteredBillableTime = billableTime.data;
    let customerBillableTime = {
      loading: false,
      clientId: customer.client_id,
      userId: customer.user_id || customer.id,
    };
    customerBillableTime.unitsUsed = filteredBillableTime.reduce((a, b) => {
      return a + b.used_units;
    }, 0);
    customerBillableTime.approvedUnits = filteredBillableTime.reduce((a, b) => {
      return a + b.approved_units;
    }, 0);
    return customerBillableTime;
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getCustomerDetails({ payload }) {
  let clientId = payload.clientId;
  try {
    const { customerDetail, customerChildren } = yield all({
      customerDetail: call(getCustomer, clientId),
      customerChildren: call(getCustomerChildren, clientId),
    });
    let notes = {};
    notes = yield call(getNotesList, clientId);
    // yield put(actions.getOldSessionNotesByUser(clientId));
    yield put(
      actions.setCustomerDetails([
        customerDetail.data.customer,
        customerChildren.data.children,
        notes.data,
      ])
    );
    yield put(actions.getCustomerClinicians(clientId));
    yield put(actions.getCustomerUpcomingCalls(clientId));
    yield put(actions.getCustomerPhysicians(clientId));
    yield put(actions.getCustomerPastClinicians(clientId));
    yield put(actions.getCustomerDocuments(clientId));
    yield put(actions.getCustomerTreatmentPlans(clientId));
    yield put(actions.getCustomerApprovedUnits(clientId));
    yield put(actions.getCustomerStatusUpdates(clientId));
    yield put(actions.getSessionNotesByUser(clientId));
    yield put(actions.getSMSThreadByClient(clientId));
    yield put(actions.getUserEmailLog(customerDetail.data.customer.email));
    yield put(actions.getClientTaskList(clientId));
    yield put(actions.getClientVideoSessionStatistics(clientId));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getCustomerCliniciansAsync({ payload }) {
  try {
    const clinicians = yield call(getCustomerClinicians, payload);
    yield put(actions.getCustomerCliniciansSuccess(clinicians.data));
  } catch (e) {
    yield put(actions.getCustomerCliniciansFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error();
  }
}

function* getCustomerDocumentsAsync({ payload }) {
  try {
    const documents = yield call(getCustomerDocuments, payload);
    yield put(actions.getCustomerDocumentsSuccess(documents.data));
  } catch (e) {
    yield put(actions.getCustomerDocumentsFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error();
  }
}

function* getCustomerPhysiciansAsync({ payload }) {
  try {
    const physicians = yield call(getCustomerPhysicians, payload);
    yield put(actions.getCustomerPhysiciansSuccess(physicians.data));
  } catch (e) {
    yield put(actions.getCustomerPhysiciansFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error();
  }
}

function* getCustomerPastCliniciansAsync({ payload }) {
  try {
    const pastClinicians = yield call(getCustomerPastClinicians, payload);
    yield put(actions.getCustomerPastCliniciansSuccess(pastClinicians.data));
  } catch (e) {
    yield put(actions.getCustomerPastCliniciansFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error();
  }
}

function* getCustomerTreatmentPlansAsync({ payload }) {
  try {
    const treatmentPlans = yield call(getCustomerTreatmentPlans, payload);
    yield put(actions.getCustomerTreatmentPlansSuccess(treatmentPlans.data));
  } catch (e) {
    yield put(actions.getCustomerTreatmentPlansFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error();
  }
}

function* getCustomerApprovedUnitsAsync({ payload }) {
  try {
    const approvedUnits = yield call(getApprovedUnits, payload);
    yield put(actions.getCustomerApprovedUnitsSuccess(approvedUnits.data));
  } catch (e) {
    yield put(actions.getCustomerApprovedUnitsFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error();
  }
}

function* switchClinicianAsync({ payload }) {
  try {
    const clientId = yield select(getCustomerClientId);
    yield call(switchClinician, clientId, payload.clinicianId);
    yield put(actions.getCustomerClinicians(clientId));
    yield put(actions.getCustomerPastClinicians(clientId));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* assignClinicianAsync({ payload }) {
  try {
    yield call(assignClinician, payload.clientId, payload.clinicianUserId);
    yield put(actions.getCustomerClinicians(payload.clientId));
    yield put(actions.getClientTaskList(payload.clientId));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* assignSecondaryClinicianAsync({ payload }) {
  try {
    yield call(assignSecondaryClinician, payload.clientId, payload.clinicianUserId);
    yield put(actions.getCustomerClinicians(payload.clientId));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* removeSecondaryClinicianAsync({ payload }) {
  try {
    yield call(removeSecondaryClinician, payload.clientId);
    yield put(actions.getCustomerClinicians(payload.clientId));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* markEligibleAsync() {
  try {
    const clientId = yield select(getCustomerClientId);
    yield call(markEligible, clientId);
    yield put(actions.markEligibleSuccess());
    yield put(actions.loadCustomerDetails(clientId));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* markApprovedAsync() {
  try {
    const clientId = yield select(getCustomerClientId);
    yield call(markApproved, clientId);
    yield put(actions.markApprovedSuccess());
    yield put(actions.loadCustomerDetails(clientId));
  } catch (e) {
    if (e.response && e.response.status === 401) {
      yield put(actions.signOut());
      return;
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getCustomerInfoAsync({ payload }) {
  let clientId = payload.clientId;
  try {
    const details = yield call(getCustomer, clientId);
    const children = yield call(getCustomerChildren, clientId);
    yield put(
      actions.getCustomerInfoSuccess({
        details: details.data.customer,
        children: children.data?.children,
      })
    );
    yield put(actions.getCustomerClinicians(clientId));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* uploadTreatmentPlanAsync({ payload }) {
  try {
    const documentId = yield call(uploadCustomerDocument, payload);
    const treatmentPlan = {
      documentId: documentId.data.document_id,
      clientId: payload.clientId,
      clinicianUserId: payload.clinicianUserId,
    };
    yield call(uploadTreatmentPlan, treatmentPlan);
    yield put(actions.uploadTreatmentPlanSuccess());
    yield put(actions.getCustomerTreatmentPlans(payload.clientId));
    yield put(actions.getCustomerInfo(payload.clientId));
    yield put(actions.getClientTaskList(payload.clientId));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* updateTreatmentPlanAsync({ payload }) {
  try {
    yield call(updateTreatmentPlan, payload);
    yield put(actions.updateTreatmentPlanSuccess());
    yield put(actions.getCustomerTreatmentPlans(payload.clientId));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* uploadCustomerDocumentAsync({ payload }) {
  try {
    const documentId = yield call(uploadCustomerDocument, payload);
    if (payload.type === "TREATMENT_PLAN" && payload.clinicianUserId) {
      const treatmentPlan = {
        documentId: documentId.data.document_id,
        clientId: payload.clientId,
        clinicianUserId: payload.clinicianUserId,
        billableTime: payload.billableTime,
      };
      yield call(uploadTreatmentPlan, treatmentPlan);
    }
    yield put(actions.uploadCustomerDocumentSuccess());
    yield put(actions.getCustomerDocuments(payload.clientId));
    if (["TREATMENT_PLAN", "DIAGNOSIS_FORM"].includes(payload.type)) {
      yield put(actions.getClientTaskList(payload.clientId));
    }
  } catch (e) {
    yield put(actions.uploadCustomerDocumentFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getDocumentTypesAsync() {
  try {
    const docTypes = yield call(getDocumentTypes);
    yield put(actions.getDocumentTypesSuccess(docTypes.data));
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getDocumentMatrixAsync({ payload }) {
  try {
    const matrix = yield call(getDocumentMatrix, payload);
    yield put(actions.getDocumentMatrixSuccess(matrix.data));
  } catch (e) {
    yield put(actions.getDocumentMatrixFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* approveCustomerDocumentAsync({ payload }) {
  try {
    yield call(approveCustomerDocument, payload);
    yield put(actions.approveCustomerDocumentSuccess());
  } catch (e) {
    yield put(actions.approveCustomerDocumentFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
  }
}

function* getCompletedDocumentStatusAsync(customer) {
  try {
    const { workflow_status } = customer;
    let userStatus;
    if (
      workflow_status === "ASSESSMENT_COMPLETED" ||
      workflow_status === "APPROVE_PLAN" ||
      workflow_status === "AWAITING_INSURANCE_APPROVAL" ||
      workflow_status === "INSURANCE_APPROVED" ||
      workflow_status === "FULL_CUSTOMER"
    ) {
      userStatus = "TREATMENT";
    } else {
      userStatus = "ASSESSMENT";
    }
    const docStatus = yield call(getCompletedDocumentStatus, {
      clientId: customer.client_id,
      status: userStatus,
    });
    const { completed, total } = docStatus.data;
    let documentStatus = {
      loading: false,
      clientId: customer.client_id,
      status: completed && total ? `${completed} of ${total}` : "N/A",
    };
    return documentStatus;
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.log(e);
  }
}

function* updateUserStatusAsync({ payload }) {
  try {
    yield call(updateUserStatus, payload);
    yield put(actions.loadCustomerDetails(payload.clientId));
    yield put(actions.updateUserInsuranceStatusSuccess());
  } catch (e) {
    yield put(actions.updateUserInsuranceStatusFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* resendLoginEmailAsync({ payload }) {
  try {
    yield call(resendLoginEmail, payload);
    yield put(actions.resendLoginEmailSuccess());
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* toggleCustomerActivationAsync({ payload }) {
  try {
    yield call(toggleCustomerActivation, payload);
    yield put(actions.loadCustomerDetails(payload.clientId));
    yield put(actions.toggleCustomerActivationSuccess());
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* deactivateClientAsync({ payload }) {
  try {
    yield call(deactivateClient, payload);
    yield put(actions.loadCustomerDetails(payload.clientId));
    yield put(actions.deactivateClientSuccess());
  } catch (e) {
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* createInsuranceCustomerAsync({ payload }) {
  try {
    const response = yield call(createInsuranceCustomer, payload);
    yield put(actions.createInsuranceCustomerSuccess(response.data));
  } catch (e) {
    if (e.response && e.response.data && e.response.data.error) {
      yield put(actions.createInsuranceCustomerFail(e.response.data.error));
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error();
  }
}

function* updateEligibilityAsync({ payload }) {
  try {
    yield call(updateEligibility, payload);
    yield put(actions.updateEligibilitySuccess());
    yield put(actions.loadCustomerDetails(payload.client));
  } catch (e) {
    if (e.response && e.response.data && e.response.data.error) {
      yield put(actions.updateEligibilityFail(e.response.data.error));
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error();
  }
}
function* saveCustomerForLaterAsync({ payload }) {
  try {
    const response = yield call(saveCustomerForLater, payload);
    yield put(actions.saveCustomerForLaterSuccess(response.data));
  } catch (e) {
    yield put(actions.saveCustomerForLaterFail(e.response.data.error));
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error();
  }
}

function* createUnsignedDocumentRequestAsync({ payload }) {
  try {
    yield call(createUnsignedRequest, payload);
    yield put(actions.createUnsignedDocumentRequestSuccess());
  } catch (e) {
    yield put(actions.createUnsignedDocumentRequestFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getAllDocumentRequestsAsync({ payload }) {
  try {
    const requests = yield call(getAllDocumentRequests, payload);
    yield put(actions.getAllDocumentRequestsSuccess(requests.data));
  } catch (e) {
    yield put(actions.getAllDocumentRequestsFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getSingleCustomerSearchAsync({ payload }) {
  try {
    const customers = yield call(getSingleCustomerSearch, payload);
    yield put(actions.getSingleCustomerSearchSuccess(customers.data));
  } catch (e) {
    yield put(actions.getSingleCustomerSearchFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getCustomerStatusUpdatesAsync({ payload }) {
  try {
    const statusUpdates = yield call(getCustomerStatusUpdates, payload);
    yield put(actions.getCustomerStatusUpdatesSuccess(statusUpdates.data));
  } catch (e) {
    yield put(actions.getCustomerStatusUpdatesFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getSavedForLaterCustomersAsync() {
  try {
    const savedCustomers = yield call(getSavedForLaterCustomers);
    yield put(
      actions.getSavedForLaterCustomersSuccess(
        savedCustomers.data,
        savedCustomers.data.length > 0 ? parseInt(savedCustomers.data[0].count) : 0
      )
    );
  } catch (e) {
    yield put(actions.getSavedForLaterCustomersFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getSavedCustomerDetailsAsync({ payload }) {
  try {
    const customer = yield call(getSavedCustomerDetails, payload);
    yield put(actions.getSavedCustomerDetailsSuccess(customer.data));
  } catch (e) {
    yield put(actions.getSavedCustomerDetailsFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* saveLeadAsync({ payload }) {
  try {
    yield call(sendEligibilityRejectionInfo, payload);
    yield put(actions.saveLeadSuccess());
  } catch (e) {
    yield put(actions.saveLeadFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* updatePatientInfoAsync({ payload }) {
  try {
    yield call(updatePatientInfo, payload);
    yield put(actions.updatePatientInfoSuccess());
    yield put(actions.loadCustomerDetails(payload.clientId));
  } catch (e) {
    if (e.response && e.response.data && e.response.data.error) {
      yield put(actions.updatePatientInfoFail(e.response.data.error));
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error();
  }
}

function* updateGuardianInfoAsync({ payload }) {
  try {
    yield call(updateGuardianInfo, payload);
    yield put(actions.updateGuardianInfoSuccess());
    yield put(actions.loadCustomerDetails(payload.clientId));
  } catch (e) {
    if (e.response && e.response.data && e.response.data.error) {
      yield put(actions.updateGuardianInfoFail(e.response.data.error));
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error();
  }
}

function* saveCustomerPhysicianAsync({ payload }) {
  try {
    yield call(saveCustomerPhysician, payload);
    yield put(actions.saveCustomerPhysicianSuccess());
    yield put(actions.loadCustomerDetails(payload.clientId));
  } catch (e) {
    if (e.response && e.response.data && e.response.data.error) {
      yield put(actions.saveCustomerPhysicianFail(e.response.data.error));
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error();
  }
}
function* updateCustomerPhysicianAsync({ payload }) {
  try {
    yield call(updateCustomerPhysician, payload);
    yield put(actions.updateCustomerPhysicianSuccess());
    yield put(actions.loadCustomerDetails(payload.clientId));
  } catch (e) {
    if (e.response && e.response.data && e.response.data.error) {
      yield put(actions.updateCustomerPhysicianFail(e.response.data.error));
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error();
  }
}

function* getCustomerBillableItemsAsync({ payload }) {
  try {
    const billableItems = yield call(getCustomerBillableItems, payload);
    yield put(
      actions.getCustomerBillableItemsSuccess(
        billableItems.data,
        billableItems.data.length > 0 ? parseInt(billableItems.data[0].count) : 0
      )
    );
  } catch (e) {
    yield put(actions.getCustomerBillableItemsFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error();
  }
}

function* switchPrimaryAccountHolderAsync({ payload }) {
  try {
    yield call(switchPrimaryAccountHolder, payload);
    yield put(actions.switchPrimaryAccountHolderSuccess());
    yield put(actions.loadCustomerDetails(payload.clientId));
  } catch (e) {
    yield put(actions.switchPrimaryAccountHolderFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* updateCustomerEmailAsync({ payload }) {
  try {
    yield call(updateCustomerEmail, payload);
    const customerDetails = yield call(getCustomer, payload.clientId);
    yield put(actions.updateCustomerEmailSuccess(customerDetails.data.customer));
  } catch (e) {
    if (e?.response?.data?.error) {
      yield put(actions.updateCustomerEmailFail(e.response.data.error));
    } else {
      yield put(
        actions.updateCustomerEmailFail(
          "An error has occurred while inviting this user. Please try again."
        )
      );
    }
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error();
  }
}

function* updateCustomerTimezoneAsync({ payload }) {
  try {
    yield call(updateCustomerTimezone, payload);
    const customerDetails = yield call(getCustomer, payload.clientId);
    yield put(actions.updateCustomerTimezoneSuccess(customerDetails.data.customer));
  } catch (e) {
    yield put(actions.updateCustomerTimezoneFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error();
  }
}

function* getCustomerPastCallsAsync({ payload }) {
  try {
    const pastCalls = yield call(getCustomerPastCalls, payload);
    yield put(actions.getCustomerPastCallsSuccess(pastCalls.data));
  } catch (e) {
    yield put(actions.getCustomerPastCallsFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error();
  }
}

function* enableClientStaticPageAsync({ payload }) {
  try {
    yield call(enableClientStaticPage, payload);
    yield put(actions.enableClientStaticPageSuccess());
  } catch (e) {
    yield put(actions.enableClientStaticPageFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* disableClientStaticPageAsync({ payload }) {
  try {
    yield call(disableClientStaticPage, payload);
    yield put(actions.disableClientStaticPageSuccess());
  } catch (e) {
    yield put(actions.disableClientStaticPageFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getClientTaskListAsync({ payload }) {
  try {
    const result = yield call(getClientTaskList, payload);
    yield put(actions.getClientTaskListSuccess(result.data));
  } catch (e) {
    yield put(actions.getClientTaskListFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* markClientTaskCompleteAsync({ payload }) {
  try {
    yield call(markClientTaskComplete, payload);
    yield put(actions.markClientTaskCompleteSuccess());
    yield put(actions.getClientTaskList(payload.clientId));
  } catch (e) {
    yield put(actions.markClientTaskCompleteFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getClientVideoSessionStatisticsAsync({ payload }) {
  try {
    const result = yield call(getClientSessionStatistics, payload);
    yield put(actions.getClientVideoSessionStatisticsSuccess(result.data));
  } catch (e) {
    yield put(actions.getClientVideoSessionStatisticsFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getClientCriticalNeedsAsync() {
  try {
    const result = yield call(getClientCriticalNeeds);
    yield put(actions.getClientCriticalNeedsSuccess(result.data));
  } catch (e) {
    yield put(actions.getClientCriticalNeedsFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* snoozeClientTaskAsync({ payload }) {
  try {
    yield call(snoozeClientTask, payload);
    yield put(actions.snoozeClientTaskSuccess());
    yield put(actions.getClientCriticalNeeds());
  } catch (e) {
    yield put(actions.snoozeClientTaskFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* updateLinkPhysicianAsync({ payload }) {
  try {
    const calls = yield call(updateLinkPhysician, payload);
    yield put(actions.updateLinkPhysicianSuccess(calls.data));
    yield put(actions.getCustomerPhysicians(payload.clientId));
  } catch (e) {
    yield put(actions.updateLinkPhysicianFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

function* getAllPhysiciansAsync({ payload }) {
  try {
    const physicians = yield call(getAllPhysicians, payload);
    yield put(actions.getAllPhysiciansSuccess(physicians.data));
  } catch (e) {
    yield put(actions.getAllPhysiciansFail());
    yield put(actions.logError({ errorMessage: e, errorType: "API" }));
    console.error(e);
  }
}

export default function* startSaga() {
  yield takeEvery(actions.LOAD_CUSTOMER_LIST, getCustomers);
  yield takeEvery(actions.GET_INSURANCE_CUSTOMER_LIST, getInsuranceCustomersAsync);
  yield takeEvery(actions.GET_DEACTIVATED_CUSTOMER_LIST, getDeactivatedCustomerListAsync);
  yield takeEvery(actions.GET_CUSTOMER_DEACTIVATION_REASONS, getCustomerDeactivationReasonsAsync);
  yield takeEvery(actions.GET_CUSTOMER_DEPARTURE_OPTIONS, getCustomerDepartureOptionsAsync);
  yield takeEvery(actions.LOAD_CUSTOMER_DETAILS, getCustomerDetails);
  yield takeEvery(actions.SWITCH_CLINICIAN, switchClinicianAsync);
  yield takeEvery(actions.ASSIGN_CLINICIAN, assignClinicianAsync);
  yield takeEvery(actions.ASSIGN_SECONDARY_CLINICIAN, assignSecondaryClinicianAsync);
  yield takeEvery(actions.REMOVE_SECONDARY_CLINICIAN, removeSecondaryClinicianAsync);
  yield takeEvery(actions.MARK_ELIGIBLE, markEligibleAsync);
  yield takeEvery(actions.MARK_APPROVED, markApprovedAsync);
  yield takeEvery(actions.GET_CUSTOMER_INFO, getCustomerInfoAsync);
  yield takeEvery(actions.UPLOAD_TREATMENT_PLAN, uploadTreatmentPlanAsync);
  yield takeEvery(actions.UPDATE_TREATMENT_PLAN, updateTreatmentPlanAsync);
  yield takeEvery(actions.UPLOAD_CUSTOMER_DOCUMENT, uploadCustomerDocumentAsync);
  yield takeEvery(actions.GET_DOCUMENT_TYPES, getDocumentTypesAsync);
  yield takeEvery(actions.GET_DOCUMENT_MATRIX, getDocumentMatrixAsync);
  yield takeEvery(actions.APPROVE_CUSTOMER_DOCUMENT, approveCustomerDocumentAsync);
  yield takeEvery(actions.UPDATE_USER_INSURANCE_STATUS, updateUserStatusAsync);
  yield takeEvery(actions.RESEND_LOGIN_EMAIL, resendLoginEmailAsync);
  yield takeEvery(actions.TOGGLE_CUSTOMER_ACTIVATION, toggleCustomerActivationAsync);
  yield takeEvery(actions.DEACTIVATE_CLIENT, deactivateClientAsync);
  yield takeEvery(actions.CREATE_INSURANCE_CUSTOMER, createInsuranceCustomerAsync);
  yield takeEvery(actions.UPDATE_ELIGIBILITY, updateEligibilityAsync);
  yield takeEvery(actions.SAVE_CUSTOMER_FOR_LATER, saveCustomerForLaterAsync);
  yield takeEvery(actions.GET_CUSTOMER_CLINICIANS, getCustomerCliniciansAsync);
  yield takeEvery(actions.GET_CUSTOMER_DOCUMENTS, getCustomerDocumentsAsync);
  yield takeEvery(actions.GET_CUSTOMER_PHYSICIANS, getCustomerPhysiciansAsync);
  yield takeEvery(actions.GET_CUSTOMER_PAST_CLINICIANS, getCustomerPastCliniciansAsync);
  yield takeEvery(actions.GET_CUSTOMER_TREATMENT_PLANS, getCustomerTreatmentPlansAsync);
  yield takeEvery(actions.GET_CUSTOMER_APPROVED_UNITS, getCustomerApprovedUnitsAsync);
  yield takeEvery(actions.CREATE_UNSIGNED_DOCUMENT_REQUEST, createUnsignedDocumentRequestAsync);
  yield takeEvery(actions.GET_ALL_DOCUMENT_REQUESTS, getAllDocumentRequestsAsync);
  yield takeEvery(actions.GET_SINGLE_CUSTOMER_SEARCH, getSingleCustomerSearchAsync);
  yield takeEvery(actions.GET_CUSTOMER_STATUS_UPDATES, getCustomerStatusUpdatesAsync);
  yield takeEvery(actions.GET_SAVED_FOR_LATER_CUSTOMERS, getSavedForLaterCustomersAsync);
  yield takeEvery(actions.GET_SAVED_CUSTOMER_DETAILS, getSavedCustomerDetailsAsync);
  yield takeEvery(actions.SAVE_LEAD, saveLeadAsync);
  yield takeEvery(actions.UPDATE_PATIENT_INFO, updatePatientInfoAsync);
  yield takeEvery(actions.UPDATE_GUARDIAN_INFO, updateGuardianInfoAsync);
  yield takeEvery(actions.SAVE_CUSTOMER_PHYSICIAN, saveCustomerPhysicianAsync);
  yield takeEvery(actions.UPDATE_CUSTOMER_PHYSICIAN, updateCustomerPhysicianAsync);
  yield takeEvery(actions.GET_CUSTOMER_BILLABLE_ITEMS, getCustomerBillableItemsAsync);
  yield takeEvery(actions.SWITCH_PRIMARY_ACCOUNT_HOLDER, switchPrimaryAccountHolderAsync);
  yield takeEvery(actions.UPDATE_CUSTOMER_EMAIL, updateCustomerEmailAsync);
  yield takeEvery(actions.UPDATE_CUSTOMER_TIMEZONE, updateCustomerTimezoneAsync);
  yield takeEvery(actions.GET_CUSTOMER_PAST_CALLS, getCustomerPastCallsAsync);
  yield takeEvery(actions.ENABLE_CLIENT_STATIC_PAGE, enableClientStaticPageAsync);
  yield takeEvery(actions.DISABLE_CLIENT_STATIC_PAGE, disableClientStaticPageAsync);
  yield takeEvery(actions.GET_CLIENT_TASK_LIST, getClientTaskListAsync);
  yield takeEvery(actions.MARK_CLIENT_TASK_COMPLETE, markClientTaskCompleteAsync);
  yield takeEvery(
    actions.GET_CLIENT_VIDEO_SESSION_STATISTICS,
    getClientVideoSessionStatisticsAsync
  );
  yield takeEvery(actions.GET_CLIENT_CRITICAL_NEEDS, getClientCriticalNeedsAsync);
  yield takeEvery(actions.SNOOZE_CLIENT_TASK, snoozeClientTaskAsync);
  yield takeEvery(actions.GET_ALL_ACTIVE_CLIENTS, getAllActiveClientsAsync);
  yield takeEvery(actions.UPDATE_LINK_PHYSICIAN, updateLinkPhysicianAsync);
  yield takeEvery(actions.GET_ALL_PHYSICIANS, getAllPhysiciansAsync);
}
