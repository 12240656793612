import React, { Component } from "react";
import { connect } from "react-redux";

import { Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { getInitialState } from "./InitialState";
import { mapStateToProps } from "./mapStateToProps";
import { mapDispatchToProps } from "./mapDispatchToProps";
import withStyles from "@mui/styles/withStyles";
import styles from "../ClientDetails/styles";
import AnLibrariesPageContents from "./AnLibrariesPageContents";
import AnLibrariesTabs from "./AnLibrariesTabs";

class AnLibraries extends Component {
  state = {
    ...getInitialState(this.props),
  };
  assignBlockRef = null;
  smsPolling = null;

  componentDidMount() {
    this.props.setPageDetails({
      pageName: "Libraries",
      hideDivider: true,
    });
  }

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {}

  navigate = (url) => {
    this.props.history.push(url);
  };

  changeTab = (tab) => {
    this.setState({ currentTab: tab, nextTab: null });
  };

  handleTabChange = (event, newValue) => {
    if (this.props.currentTab !== newValue && this.state.unsavedChanges) {
      this.setState({ openWarning: true, nextTab: newValue });
    } else {
      this.changeTab(newValue);
    }
  };

  getTabs = () => {
    const { userPermissions } = this.props;

    return [
      { label: "Insurance", link: "insurance", hide: !userPermissions?.view_insurance_utility },
      { label: "Providers", link: "providers", hide: !userPermissions?.view_physician_utility },
      {
        label: "Assessments",
        link: "assessments",
        hide: !userPermissions?.view_assessment_library,
      },
    ];
  };

  setUnsavedChanges = (value) => {
    this.setState({ unsavedChanges: value });
  };

  setOpenWarning = (value) => {
    this.setState({ openWarning: value });
  };

  render() {
    const { classes, loaded, customerDetails, userPermissions } = this.props;
    const { snackBarOpen, snackBarMessage, currentTab } = this.state;

    return (
      userPermissions && (
        <div>
          <AnLibrariesTabs
            currentTab={currentTab}
            handleTabChange={this.handleTabChange}
            userPermissions={userPermissions}
            classes={classes}
            tabs={this.getTabs()}
          />
          <div className={classes.root}>
            <AnLibrariesPageContents
              {...this.props}
              {...this.state}
              visibleTabs={this.getTabs().filter(({ hide }) => !hide)}
              userPermissions={userPermissions}
              setEditMode={(newState) => this.setState({ editMode: newState })}
              navigate={this.navigate}
              assignBlockRef={(ref) => (this.assignBlockRef = ref)}
              setUnsavedChanges={this.setUnsavedChanges}
              setOpenWarning={this.setOpenWarning}
              changeTab={this.changeTab}
            />
          </div>
          <Snackbar
            autoHideDuration={10000}
            onClose={() =>
              this.setState({
                snackBarOpen: false,
                snackBarError: false,
                snackBarMessage: "",
              })
            }
            open={snackBarOpen}
          >
            <Typography>{snackBarMessage}</Typography>
          </Snackbar>
        </div>
      )
    );
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AnLibraries));
