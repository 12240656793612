import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { FormProvider, useForm, useWatch } from "react-hook-form";

import { ClientDetailsFormStates, useClientDetailsForm } from "../../../ClientDetailsFormContext";
import GenericSelect from "../../GenericFormFields/GenericSelect";
import GenericTextField from "../../GenericFormFields/GenericTextField";
import { GenericCalendarInput } from "../../GenericFormFields/GenericCalendarInput";
import DisableInsuranceButton from "./DisableInsuranceButton";
import useGetInsurancePlans from "./useGetInsurancePlans";
import Button from "@mui/material/Button";

const areBothInvalidDates = (date1, date2) => isNaN(Date.parse(date1)) && isNaN(Date.parse(date2));
const areEqualDates = (date1, date2) => date1?.getTime() === date2?.getTime();

const dateFields = ["effectiveDate", "expirationDate", "insuranceHolderDOB"];

const InsuranceDetailsEdit = ({
  insurance,
  clientId,
  privatePayClient,
  classes,
  insuranceProvidersList,
  primaryInsuranceId,
  setPrimaryInsuranceId,
  getUserInsurance,
  onCancelCreateInsurance,
  onSave,
  onError,
  setUnsavedChanges,
}) => {
  const defaultValues = {
    ...insurance,
    insuranceid: null,
    insurancePlanId: null,
    effectiveDate: insurance.effectiveDate ? new Date(insurance.effectiveDate) : null,
    expirationDate: insurance.expirationDate ? new Date(insurance.expirationDate) : null,
    insuranceHolderDOB: insurance.insuranceHolderDOB
      ? new Date(insurance.insuranceHolderDOB)
      : null,
  };

  const formMethods = useForm({
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    control,
    watch,
  } = formMethods;

  const insuranceid = useWatch({ control, name: "insuranceid" });
  const insurancePlanId = useWatch({ control, name: "insurancePlanId" });
  const isPrimary = useWatch({ control, name: "isPrimary" });

  const { formState } = useClientDetailsForm();

  const insurancePlans = useGetInsurancePlans({ insuranceid });

  const fieldNames = Object.keys(defaultValues);
  const watchedFields = watch(fieldNames);
  const isUserChanged =
    isDirty &&
    watchedFields.some((field, index) => {
      const fieldName = fieldNames[index];
      const originalValue = defaultValues[fieldName];

      const isDateField = dateFields.some((v) => v === fieldName);

      if (isDateField) {
        return !areBothInvalidDates(field, originalValue) && !areEqualDates(field, originalValue);
      }

      return field !== originalValue;
    });

  useEffect(() => {
    setUnsavedChanges(isUserChanged);
  }, [isUserChanged]);

  useEffect(() => {
    if (!insuranceid && insurance.insuranceName) {
      const insuranceIdValue = insuranceProvidersList?.find(
        ({ name }) => name === insurance.insuranceName
      )?.id;
      setValue("insuranceid", insuranceIdValue);
    }

    if (!insurancePlanId && insurance.planName) {
      const insurancePlanIdValue = insurancePlans?.find(
        ({ name }) => name === insurance.planName
      )?.id;

      setValue("insurancePlanId", insurancePlanIdValue);
    }
  }, [insurance]);

  useEffect(() => {
    setValue("isPrimary", primaryInsuranceId === insurance.id);
  }, [primaryInsuranceId]);

  useEffect(() => {
    onError({ id: insurance.id, value: Object.keys(errors ?? {}).length > 0 });
  }, [errors]);

  useEffect(() => {
    if (formState === ClientDetailsFormStates.onsubmit) {
      handleSubmit((values) => onSave({ ...values }))();
    }
  }, [formState]);

  const insurancePlanOptions = insurancePlans?.filter(({ insurance_id }) => parseInt(insurance_id) === parseInt(insuranceid))
    .map(({ id, name }) => ({
      label: name,
      value: id,
    }));

  useEffect(() => {
    if (insuranceid) {
      const defaultInsuranceId = insuranceProvidersList?.find(
        ({ name }) => name === insurance.insuranceName
      )?.id;

      if (insurance.insuranceName && defaultInsuranceId && insuranceid !== defaultInsuranceId) {
        setValue("insurancePlanId", "");
      }
    }
  }, [insuranceid]);

  const onChangePrimaryInsurance = (e) => {
    if (e.target.checked) {
      setPrimaryInsuranceId(insurance.id);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Grid
        container
        justifyContent="flex-start"
        rowSpacing={7}
        columnSpacing={5}
        classes={{ root: classes.gridRoot }}
      >
        <Grid item xs={12} sm={6} align="left">
          <GenericSelect
            name="insuranceid"
            label="Insurance Company"
            required
            options={insuranceProvidersList?.map(({ id, name }) => ({ label: name, value: id }))}
            helperText={"Please select an insurer"}
          />
        </Grid>
        <Grid item xs={12} sm={6} align="left">
          <GenericSelect
            name="insurancePlanId"
            label="Insurance Plan"
            required
            options={insurancePlanOptions}
            helperText={"Please select an insurance plan"}
          />
        </Grid>
        <Grid item xs={12} sm={6} align="left">
          <GenericTextField
            name="memberId"
            helperText={"Please enter the Member ID"}
            placeholder="Member ID"
            label="Member ID"
            required
            disabled={privatePayClient}
          />
        </Grid>
        <Grid item xs={12} sm={6} align="left">
          <GenericTextField
            name="groupNumber"
            placeholder="Group Number"
            label="Group Number"
            disabled={privatePayClient}
          />
        </Grid>
        <Grid item xs={12} sm={6} align="left">
          <GenericCalendarInput
            name={"effectiveDate"}
            label="Effective Date"
            disabled={privatePayClient}
          />
        </Grid>
        <Grid item xs={12} sm={6} align="left">
          <GenericCalendarInput
            name={"expirationDate"}
            label="Expiration Date"
            disabled={privatePayClient}
          />
        </Grid>
        <Grid item xs={12} sm={6} align="left">
          <GenericTextField
            name="insuranceHolderName"
            helperText={"Please enter the name"}
            placeholder="First name last name"
            label="Insurance Holder Name"
            required
            disabled={privatePayClient}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <GenericCalendarInput
            required
            name={"insuranceHolderDOB"}
            label="Insurance Holder Date of Birth"
            disableFuture
            helperText={"Please enter the date of birth"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <GenericSelect
            name="insurance_holder_relationship"
            label="Relationship of Client to Holder"
            required
            options={[
              { label: "Self", value: "Self" },
              { label: "Child", value: "Child" },
              { label: "Other", value: "Other" },
            ]}
            helperText={"Please select an option"}
          />
        </Grid>

        <Grid item xs={12} sm={12} align="left">
          <FormControlLabel
            control={<Checkbox onChange={onChangePrimaryInsurance} checked={isPrimary} />}
            label="Primary Insurance"
            disabled={isPrimary}
            error={errors.isPrimary}
          />
        </Grid>

        {!isPrimary && (
          <Grid item xs={12} align="left">
            {insurance?.id ? (
              <DisableInsuranceButton
                insurance={insurance}
                onSuccess={() => getUserInsurance(clientId)}
              />
            ) : (
              <Button variant={"text"} color="error" onClick={onCancelCreateInsurance}>
                Remove Insurance
              </Button>
            )}
          </Grid>
        )}
      </Grid>
    </FormProvider>
  );
};

export default InsuranceDetailsEdit;
