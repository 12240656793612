import React, { Component } from "react";
import SideNav from "../SideNav/SideNav";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import actions from "actions";
import { PERMISSIONS } from "../../constants/rolemapping";
import {
  getSignedinState,
  getPasswordChange,
  getGroups,
  getCurrentPage,
  getMenu,
  getSigninClear,
  getClinicianWorkflowStatus,
  getUserClinicianId,
  getCurrentClinicianId,
  isClinician,
  isCaregiver,
  isLegacyUser,
  isSubscribed,
  workflowStatus,
  getLogin,
  getUserPermissionsList,
  getUserId,
  getUser,
  getClientId,
  getSendbirdConnection,
  getUnreadMessageCount,
  getBuildVersionCode,
} from "../../selectors";
import RefreshPageDialog from "./RefreshPageDialog";
import { HeaderMobile } from "./HeaderMobile";
import { Header } from "./Header";
import Box from "@mui/material/Box";
import {
  ChatCenteredDots,
  Fingerprint,
  Password,
  Pen,
  Question,
  SignOut,
  User,
} from "@phosphor-icons/react";
import { showChatPanel } from "components/IntercomAi";
import { PageTitle } from "./HeaderSections";

const mapStateToProps = (state) => ({
  signedInState: getSignedinState(state),
  passwordChange: getPasswordChange(state),
  groups: getGroups(state),
  menu: getMenu(state),
  currentPage: getCurrentPage(state),
  clear: getSigninClear(state),
  clinicianWorkflowStatus: getClinicianWorkflowStatus(state),
  clinicianId: getCurrentClinicianId(state),
  userClinicianId: getUserClinicianId(state),
  isClinician: isClinician(state),
  isCaregiver: isCaregiver(state),
  userId: getUserId(state),
  subscribed: isSubscribed(state),
  isLegacyUser: isLegacyUser(state),
  workflowStatus: workflowStatus(state),
  user: getUser(state),
  login: getLogin(state),
  userPermissions: getUserPermissionsList(state),
  sendbirdConnection: getSendbirdConnection(state),
  unreadMessageCount: getUnreadMessageCount(state),
  versionCode: getBuildVersionCode(state),
  clientId: getClientId(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signOut: actions.signOut,
      checkAuthState: actions.checkAuthState,
      clearBasicDetails: actions.clearBasicDetails,
      clearCustomerSearch: actions.clearCustomerSearch,
      getSendbirdConnection: actions.getSendbirdConnection,
      disconnectSendbird: actions.disconnectSendbird,
      getUnreadChannelCount: actions.getUnreadChannelCount,
      getBuildVersion: actions.getBuildVersion,
      setWeekStartDate: actions.setWeekStartDate,
      setWeekEndDate: actions.setWeekEndDate,
    },
    dispatch
  );

class HeaderContainer extends Component {
  state = {
    drawerOpen: false,
    displayHeader: true,
    refreshPageOpen: false,
    checkAuthState: true,
  };

  componentDidMount() {
    if (
      (this.props.location &&
        (this.props.location.pathname === "/force-password-change" ||
          this.props.location.pathname === "/reset-password" ||
          this.props.location.pathname.includes("/setup/") ||
          this.props.location.pathname === "/test" ||
          this.props.location.pathname === "/chat")) ||
      this.props.location.pathname.includes("/static-video-page") ||
      this.props.location.pathname.includes("/video/") ||
      this.props.location.pathname.includes("/chat/") ||
      this.props.location.pathname.includes("/document-viewer/")
    ) {
      let checkAuthState = true;
      if (this.props.location.pathname.includes("/static-video-page")) {
        checkAuthState = false;
      }
      this.setState({ displayHeader: false, checkAuthState });
    } else {
      this.checkAccess(this.props);
      this.setState({ displayHeader: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentPage !== prevProps.currentPage) {
      this.checkAccess(this.props);
    }
    if (!prevProps.userPermissions && this.props.userPermissions) {
      this.checkAccess(this.props);
    }
    if (!prevProps.signedInState && this.props.signedInState) {
      this.props.getBuildVersion();
    }
    if (
      (!prevProps.versionCode && this.props.versionCode) ||
      (prevProps.versionCode !== this.props.versionCode && this.props.versionCode)
    ) {
      this.checkVersionCode();
    }
    if (
      (prevProps.signedInState && !this.props.signedInState) ||
      (prevProps.signedInState === null && this.props.signedInState === false)
    ) {
      const url = this.props.history.location.pathname;

      if (this.props.clear) {
        this.props.history.replace("/");
        return;
      }
      if (
        url !== "/" &&
        url !== "/forgot-password" &&
        url !== "/reset-password" &&
        !url.includes("/setup/")
      ) {
        this.props.history.replace(`/?redirect=${url}`);
      }
    }

    if (
      prevProps.location &&
      (prevProps.location.pathname.indexOf("get-started") >= 0 ||
        prevProps.location.pathname.indexOf("/referral") >= 0)
    ) {
      return false;
    }

    if (prevProps.location && prevProps.location.pathname !== this.props.location.pathname) {
      if (
        this.props.location.pathname !== "/" &&
        this.props.location.pathname !== "/force-password-change" &&
        this.props.location.pathname !== "/reset-password" &&
        this.props.location.pathname !== "/chat" &&
        !this.props.location.pathname.includes("/setup/") &&
        !this.props.location.pathname.includes("/static-video-page") &&
        !this.props.location.pathname.includes("/video/") &&
        !this.props.location.pathname.includes("/chat/") &&
        !this.props.location.pathname.includes("/document-viewer/")
      ) {
        this.setState({ displayHeader: true });
      } else {
        this.setState({ displayHeader: false });
      }
    }

    if (
      (!prevProps.user?.first_name ||
        !prevProps.userId ||
        !prevProps.signedInState ||
        !prevProps.userPermissions) &&
      this.props.user?.first_name &&
      this.props.userId &&
      this.props.signedInState &&
      this.props.userPermissions?.chat
    ) {
      this.props.getSendbirdConnection(
        this.props.userId,
        this.props.user.first_name,
        process.env.SENDBIRD_APP_ID
      );
    }
    if (
      !prevProps.sendbirdConnection &&
      this.props.sendbirdConnection &&
      this.props.signedInState
    ) {
      this.getUnreadMessages = setInterval(() => {
        this.props.getUnreadChannelCount(this.props.sendbirdConnection);
      }, 5000);
    }
    if (!prevProps.signedInState && prevProps.sendbirdConnection && this.props.sendbirdConnection) {
      if (this.getUnreadMessages) {
        clearInterval(this.getUnreadMessages);
      }
      this.props.disconnectSendbird(this.props.sendbirdConnection);
    }

    if (prevProps.groups.length === 0 && this.props.groups.length > 0) {
      this.checkAccess(this.props);
    }

    if (
      (prevProps.location?.pathname?.includes("/video-calls/") ||
        prevProps.location?.pathname == "/upcoming-video-calls" ||
        prevProps.location?.pathname == "/clinician-schedule") &&
      !this.props.location?.pathname?.includes("/video-calls/") &&
      this.props.location?.pathname !== "/upcoming-video-calls" &&
      this.props.location?.pathname !== "/clinician-schedule"
    ) {
      this.props.setWeekStartDate("");
      this.props.setWeekEndDate("");
    }
  }

  componentWillUnmount() {
    if (this.getUnreadMessages) {
      clearInterval(this.getUnreadMessages);
    }
    this.props.disconnectSendbird(this.props.sendbirdConnection);
    localStorage.removeItem("currentAudioInput");
    localStorage.removeItem("currentAudioOutput");
    localStorage.removeItem("currentCameraInput");
  }

  checkAccess(props) {
    if (this.state.checkAuthState) {
      this.props.checkAuthState();
    }
    if (props.currentPage === "404") {
      return;
    }
    // check Access
    if (props.menu && props.currentPage && props.userPermissions) {
      const permission = PERMISSIONS[props.menu].pages[props.currentPage].permission;
      if (!props.userPermissions[permission]) {
        this.props.history.replace("/not-found");
      }
    }
  }

  checkVersionCode = () => {
    const { versionCode } = this.props;
    if (
      versionCode &&
      versionCode != APP_VERSION.split("-")[0] &&
      process.env.NODE_ENV === "production"
    ) {
      this.setState({ refreshPageOpen: true });
    }
  };

  onClickRefreshPage = () => {
    window.location.reload(window.location.href + "?r=t");
  };

  navigate = (routeName) => {
    this.props.history.push(routeName);
  };

  navigateMobile = (routeName) => {
    this.handleDrawerToggle();
    this.props.history.push(routeName);
  };

  logout = () => {
    this.props.signOut(true);
  };

  handleMenuClicked = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  renderMenuItems = () => {
    const { userPermissions } = this.props;
    if (!userPermissions) {
      return [];
    }
    let menuItems = [];
    if (userPermissions["view_client_dashboard"]) {
      menuItems.push({
        value: "client_account",
        label: "Account",
        icon: User,
        clickHandler: () => {
          this.handleMenuClicked();
          this.navigate("/account");
        },
      });
    }
    if (userPermissions["view_clinician_dashboard"]) {
      menuItems.push({
        value: "clinician_profile",
        label: "Profile",
        icon: User,
        clickHandler: () => {
          this.handleMenuClicked();
          this.navigate("/my-info/profile");
        },
      });
    }
    if (!userPermissions["view_client_dashboard"]) {
      menuItems.push({
        value: "chatSupport",
        label: "Chat with Support",
        icon: ChatCenteredDots,
        clickHandler: () => {
          this.handleMenuClicked();
          showChatPanel();
        },
      });
    }
    if (!userPermissions["view_client_dashboard"]) {
      menuItems.push({
        value: "helpCenter",
        label: "Help Center",
        icon: Question,
        clickHandler: () => {
          this.handleMenuClicked();
          window.open("https://intercom.help/ansupport/en/", "_blank", "noopener=1");
        },
      });
    }
    menuItems.push({
      value: "changePassword",
      label: "Reset Password",
      icon: Password,
      clickHandler: () => {
        this.handleMenuClicked();
        this.navigate("/change-password");
      },
    });
    menuItems.push({
      value: "privacy",
      label: "Privacy Policy",
      icon: Fingerprint,
      clickHandler: () => {
        this.handleMenuClicked();
        window.open("https://www.getanswersnow.com/privacy", "_blank");
      },
    });
    menuItems.push({
      value: "terms",
      label: "Terms of Use",
      icon: Pen,
      divider: true,
      clickHandler: () => {
        this.handleMenuClicked();
        window.open("https://www.getanswersnow.com/terms", "_blank");
      },
    });
    menuItems.push({
      value: "logout",
      label: "Sign Out",
      icon: SignOut,
      clickHandler: this.logout,
    });
    menuItems.push({
      value: "version",
      label: `AnswersNow © ${new Date().getFullYear()} v${APP_VERSION}`,
      disabled: true,
      typography: true,
    });
    return menuItems;
  };

  handleSearchChange = (e, value, allUsers) => {
    if (allUsers) {
      this.props.history.replace(`/users/${value.id}`);
    } else if (value?.client_id) {
      this.props.history.replace(`/clients/${value.client_id}/dashboard`);
    } else if (value?.clinician_id) {
      let activateGo = this.props.location?.pathname?.includes(`/clinicians/`);
      this.props.history.replace(`/clinicians/${value.clinician_id}/profile`);
      if (activateGo) {
        this.props.history.go();
      }
    }
  };

  onClickRefreshPage = () => {
    window.location.reload(window.location.href + "?r=t");
  };

  handleDrawerToggle = () => {
    this.setState((state) => ({ drawerOpen: !state.drawerOpen }));
  };

  hideDrawer = () => {
    this.setState({ drawerOpen: false });
  };

  render() {
    const { user, userPermissions } = this.props;
    if (!this.props.signedInState) {
      return <React.Fragment />;
    }
    const userFullName = user?.first_name ? `${user.first_name} ${user.last_name}` : "";
    const userInitials = user?.first_name
      ? `${user.first_name[0].toUpperCase()}${user.last_name[0].toUpperCase()}`
      : "";
    return (
      <>
        {this.state.displayHeader && (
          <>
            <Box sx={{ display: { xs: "none", lg: "block" }, padding: "0 16px" }}>
              <Header
                handleMenuClicked={this.handleMenuClicked}
                menuExpanded={this.state.menuOpen}
                menuItems={this.renderMenuItems()}
                userFullName={userFullName}
                userInitials={userInitials}
                userAvatarSrc={user?.image_url || null}
                handleSearchChange={this.handleSearchChange}
                userPermissions={userPermissions}
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                top: 0,
                left: 0,
                display: { xs: "flex", md: "none" },
              }}
            >
              <HeaderMobile
                handleMenuClicked={this.handleMenuClicked}
                menuExpanded={this.state.menuOpen}
                menuItems={this.renderMenuItems()}
                userInitials={userInitials}
                userAvatarSrc={user?.image_url || null}
                handleDrawerToggle={this.handleDrawerToggle}
              />
              <SideNav
                {...this.state}
                {...this.props}
                navigate={this.navigateMobile}
                handleDrawerToggle={this.handleDrawerToggle}
                hideDrawer={this.hideDrawer}
                mobile={true}
              />
            </Box>
          </>
        )}
        <RefreshPageDialog
          open={this.state.refreshPageOpen}
          onClickRefreshPage={this.onClickRefreshPage}
        />
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderContainer));
