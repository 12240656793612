import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Chip,
  IconButton,
  InputAdornment,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import {
  CaretDown,
  FunnelSimple,
  MagnifyingGlass,
  MapPin,
  SlidersHorizontal,
  X,
} from "@phosphor-icons/react";
import { formatFilename } from "utils/snakeCasetoTitleCase";
import { datePickerPubSub } from "../utils/pubSub";

/**
 * Renders a table filter component with ordering and filtering functionality.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.orderingMenuItems - The menu items for ordering.
 * Example:
 * [
 *  {
 label: "Uploaded Date",
 value: "uploaded-date-asc",
 icon: <Calendar weight="duotone" />, // Optional
 caption: "Ascending", // Optional
 },
 {...More items...}
 * ]
 *
 * @param {string} props.orderBy - The state that controls the ordering, a string value.
 * @param {Function} props.setOrderBy - The function to set the ordering value.
 * For example, setOrderBy("uploaded-date-asc").
 * @param {Array} props.filtersMenuItems - The menu items for filtering.
 * Example:
 * [
 * {
 *   label: "Document Type",
 *   value: "document-type",
 *   icon: <DocumentText weight="duotone" />, // Optional
 *   customComponent: <FancyComponent />, // Optional (this component should handle its own state, and will not be controlled by state and setState)
 *   countObject: [ // This object has to have this structure.
 *    { id: "1", display_name: "Invoice", count: 10 },
 *    { id: "2", display_name: "Receipt", count: 5 },
 *    { id: "3", display_name: "Others", count: 3 },
 *   ],
 *   state: [], // The state that controls the selected filter values, an array of strings. Required if customComponent is not provided.
 *   setState: () => {}, // The function to set the selected filter values. Required if customComponent is not provided.
 *   filterChipProps: { color: "primary" }, // Optional
 *   showChipConditionCustomComponent: false, // Required if customComponent is provided.
 * },
 * {...More items...}
 * ]
 *
 * @param {Object} props.filtersButtonProps - The props for the filters button (the props that are passed to the MUI Button component).
 * @returns {JSX.Element} The rendered component.
 */
export default function ANTableFilters({
  orderingMenuItems = [],
  orderBy,
  setOrderBy,
  filtersMenuItems = [],
  filtersButtonProps,
  searchInput,
}) {
  const theme = useTheme();
  const [anchorOrderingEl, setAnchorOrderingEl] = useState(null);
  const [searchText, setSearchText] = useState("");
  const openOrdering = Boolean(anchorOrderingEl);
  const handleOrderingOpen = (event) => setAnchorOrderingEl(event.currentTarget);
  const handleOrderingClose = () => setAnchorOrderingEl(null);
  const [anchorFiltersEl, setAnchorFiltersEl] = useState(null);
  const openFilters = Boolean(anchorFiltersEl);
  const handleFiltersOpen = (event) => setAnchorFiltersEl(event.currentTarget);
  const handleFiltersClose = () => {
    setAnchorFiltersEl(null);
    setSearchText("");
    setDataItems(filtersMenuItemsLookup[filterByMenu]?.countObject ?? []);
    setTimeout(() => setFilterByMenu(null), 250); // Timeout avoids flickering
  };
  const [filterByMenu, setFilterByMenu] = useState(null);
  const [dataItems, setDataItems] = useState(null);

  const orderingItemsLookup = orderingMenuItems.reduce((acc, item) => {
    acc[item.value] = item.label;
    return acc;
  }, {});

  const filtersMenuItemsLookup = filtersMenuItems.reduce((acc, item) => {
    acc[item.value] = item;
    return acc;
  }, {});

  const onSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchText(searchValue);

    const filteredItems = filtersMenuItemsLookup[filterByMenu].countObject.filter((el) =>
      el.display_name.toLowerCase().includes(searchValue)
    );

    setDataItems(filteredItems);
  };

  useEffect(() => {
    if (filterByMenu) {
      setDataItems(filtersMenuItemsLookup[filterByMenu].countObject);
    }
  }, [filterByMenu]);

  const avoidBubblingUp = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      <Menu
        id="ordering-menu"
        anchorEl={anchorOrderingEl}
        open={openOrdering}
        onClose={handleOrderingClose}
        MenuListProps={{ "aria-labelledby": "open-ordering" }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="301px"
          padding={theme.spacing(1, 7)}
        >
          <Typography variant="subtitle2">Ordering</Typography>
          <TextField
            select
            sx={{ width: "168px" }}
            SelectProps={{ renderValue: (selected) => orderingItemsLookup[selected] }}
            value={orderBy}
            onChange={(e) => {
              setOrderBy(e.target.value);
              handleOrderingClose();
            }}
          >
            {orderingMenuItems.map((item) => (
              <MenuItem key={`${item.value}`} value={item.value}>
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                {filtersMenuItemsLookup[filterByMenu]?.valueUncapitalize !== false
                  ? formatFilename(item.label)
                  : item.label}
                {item.caption && <Typography variant="caption">{item.caption}</Typography>}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </Menu>
      <Menu
        id="filters-menu"
        anchorEl={anchorFiltersEl}
        open={openFilters}
        onClose={handleFiltersClose}
        MenuListProps={{ "aria-labelledby": "open-ordering" }}
      >
        {!filterByMenu &&
          filtersMenuItems.map((item) => (
            <MenuItem
              key={`${item.value}`}
              value={item.value}
              onClick={() => setFilterByMenu(item.value)}
            >
              {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
              {filtersMenuItemsLookup[filterByMenu]?.valueUncapitalize !== false
                ? formatFilename(item.label)
                : item.label}
            </MenuItem>
          ))}
        {filterByMenu && filtersMenuItemsLookup[filterByMenu].customComponent}
        {filterByMenu && searchInput && (
          <div style={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 1 }}>
            <MenuItem onClick={(event) => event.stopPropagation()} onKeyDown={avoidBubblingUp}>
              <TextField
                placeholder="Search"
                fullWidth
                value={searchText}
                onChange={onSearch}
                InputProps={{
                  // ...InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <MagnifyingGlass weight="regular" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchText && (
                        <IconButton
                          onClick={() => {
                            setSearchText("");
                            setDataItems(filtersMenuItemsLookup[filterByMenu].countObject);
                          }}
                        >
                          <X />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </MenuItem>
          </div>
        )}
        {filterByMenu && (
          <div
            style={{
              maxHeight: 350, // Adjust height for scrollable items
              overflowY: "auto",
            }}
          >
            {dataItems?.map((el) => (
              <MenuItem
                key={el.id}
                value={el.id}
                onClick={() => {
                  filtersMenuItemsLookup[filterByMenu].setState((prev) =>
                    prev.indexOf(el.id) > -1
                      ? prev.filter((item) => item !== el.id)
                      : [...prev, el.id]
                  );
                }}
                onKeyDown={avoidBubblingUp}
              >
                <Checkbox
                  checked={filtersMenuItemsLookup[filterByMenu].state.indexOf(el.id) > -1}
                />
                {filtersMenuItemsLookup[filterByMenu]?.valueUncapitalize !== false
                  ? formatFilename(el.display_name)
                  : el.display_name}
                {filtersMenuItemsLookup[filterByMenu].countLabel && (
                  <Typography variant="caption">{`${el.count}  ${filtersMenuItemsLookup[filterByMenu].countLabel ?? "document"}${
                    el.count > 1 ? "s" : ""
                  }`}</Typography>
                )}
              </MenuItem>
            ))}
          </div>
        )}
      </Menu>
      <Stack direction="row" gap="8px" flexWrap="wrap" alignItems="center">
        <Button
          color="secondary"
          size="small"
          startIcon={<SlidersHorizontal />}
          endIcon={<CaretDown />}
          onClick={handleOrderingOpen}
        >
          Display
        </Button>
        <Button
          color="secondary"
          size="small"
          startIcon={<FunnelSimple />}
          endIcon={<CaretDown />}
          onClick={handleFiltersOpen}
          {...filtersButtonProps}
        >
          Filters
        </Button>
        {filtersMenuItems.map((item) => {
          const filter = filtersMenuItemsLookup[item.value];
          if (filter.customComponent) {
            const { id, icon, filterChipProps, showChipConditionCustomComponent } = filter;
            return showChipConditionCustomComponent ? (
              <Chip key={id} size="small" variant="outlined" icon={icon} {...filterChipProps} />
            ) : null;
          } else {
            return filter?.state?.map((id) => {
              const { countObject, label, icon, filterChipProps } = filter;
              const filterName = countObject.find((item) => item.id === id)?.display_name;
              return (
                <Chip
                  key={id}
                  label={`${label} is : ${filterName}`}
                  size="small"
                  variant="outlined"
                  icon={icon}
                  onDelete={() => filter.setState((prev) => prev.filter((item) => item !== id))}
                  {...filterChipProps}
                />
              );
            });
          }
        })}
      </Stack>
    </>
  );
}
