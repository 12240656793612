const ACTIONS = {
  GET_USER_LIST: "GET_USER_LIST",
  GET_USERS_FOR_CLINICIAN: "GET_USERS_FOR_CLINICIAN",
  LOAD_USER_LIST: "LOAD_USER_LIST",
  SET_USER_LIST: "SET_USER_LIST",
  GET_USER_DETAILS: "GET_USER_DETAILS",
  LOAD_USER_DETAILS: "LOAD_USER_DETAILS",
  SET_USER_DETAILS: "SET_USER_DETAILS",
  EDIT_USER_DETAILS: "EDIT_USER_DETAILS",

  SET_USERS_PAGE: "SET_USERS_PAGE",
  SET_USERS_PER_PAGE: "SET_USERS_PER_PAGE",
  GET_USERS_PAGE: "GET_USERS_PAGE",
  GET_USERS_PER_PAGE: "GET_USERS_PER_PAGE",
  GET_USERS_COUNT: "GET_USERS_COUNT",
  SET_USERS_SORT: "SET_USERS_SORT",

  CREATE_USER: "CREATE_USER",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAIL: "CREATE_USER_FAIL",
  RESET_CREATE_USER: "RESET_CREATE_USER",
  SET_USER_SAVE_ERROR: "SET_USER_SAVE_ERROR",
  CLEAR_USER_SAVE_ERROR: "CLEAR_USER_SAVE_ERROR",

  GET_USER_DEACTIVATION_REASONS: "GET_USER_DEACTIVATION_REASONS",
  GET_USER_DEACTIVATION_REASONS_SUCCESS: "GET_USER_DEACTIVATION_REASONS_SUCCESS",
};

const ACTION_CREATORS = {
  getUserList() {
    return {
      type: ACTIONS.GET_USER_LIST,
    };
  },
  getUsersForClinician() {
    return {
      type: ACTIONS.GET_USERS_FOR_CLINICIAN,
    };
  },
  loadUserList() {
    return {
      type: ACTIONS.LOAD_USER_LIST,
    };
  },
  setUserList(users, count) {
    return {
      type: ACTIONS.SET_USER_LIST,
      payload: {
        users,
        count,
      },
    };
  },
  getUserDetails() {
    return {
      type: ACTIONS.GET_USER_DETAILS,
    };
  },
  loadUserDetails(userId) {
    return {
      type: ACTIONS.LOAD_USER_DETAILS,
      payload: {
        userId,
      },
    };
  },
  setUserDetails(payload) {
    return {
      type: ACTIONS.SET_USER_DETAILS,
      payload,
    };
  },
  editUserDetails(payload) {
    return {
      type: ACTIONS.EDIT_USER_DETAILS,
      payload,
    };
  },
  setUsersPage(page) {
    return {
      type: ACTIONS.SET_USERS_PAGE,
      payload: {
        page,
      },
    };
  },
  setUsersPerPage(rowsPerPage) {
    return {
      type: ACTIONS.SET_USERS_PER_PAGE,
      payload: {
        rowsPerPage,
      },
    };
  },
  getUsersPage() {
    return {
      type: ACTIONS.GET_USERS_PAGE,
    };
  },
  getUsersPerPage() {
    return {
      type: ACTIONS.GET_USERS_PER_PAGE,
    };
  },
  getUsersCount() {
    return {
      type: ACTIONS.GET_USERS_COUNT,
    };
  },
  setUsersSort(payload) {
    return {
      type: ACTIONS.SET_USERS_SORT,
      payload,
    };
  },
  createUser(payload) {
    return {
      type: ACTIONS.CREATE_USER,
      payload,
    };
  },
  createUserSuccess() {
    return {
      type: ACTIONS.CREATE_USER_SUCCESS,
    };
  },
  createUserFail(e) {
    return {
      type: ACTIONS.CREATE_USER_FAIL,
      payload: e,
    };
  },
  resetCreateUser() {
    return {
      type: ACTIONS.RESET_CREATE_USER,
    };
  },
  setUserSaveError() {
    return { type: ACTIONS.SET_USER_SAVE_ERROR };
  },
  clearUserSaveError() {
    return { type: ACTIONS.CLEAR_USER_SAVE_ERROR };
  },
  getUserDeactivationReasons() {
    return {
      type: ACTIONS.GET_USER_DEACTIVATION_REASONS,
    };
  },
  getUserDeactivationReasonsSuccess(payload) {
    return {
      type: ACTIONS.GET_USER_DEACTIVATION_REASONS_SUCCESS,
      payload,
    };
  },
};

export default { ...ACTIONS, ...ACTION_CREATORS };
